define("avaliacao-talentrh/pods/manage-goals/goals/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Services
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    // Default values
    userNameFilter: null,
    filters: null,
    // Life cycles
    didUpdateAttrs() {
      this._super(...arguments);
    },
    // Tasks
    changeUserNameFilter: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(300);
      if (this.userNameFilter) {
        Ember.set(this, 'filters', {
          ...this.filters,
          name: this.userNameFilter
        });
      }
    }).restartable(),
    dataFields: Ember.A([{
      field: 'id',
      fieldName: 'ID'
    }, {
      field: 'cpf',
      fieldName: 'CPF'
    }, {
      field: 'email',
      fieldName: 'Email'
    }, {
      field: 'userName',
      fieldName: 'Colaborador'
    }, {
      field: 'title',
      fieldName: 'Meta'
    }, {
      field: 'unit',
      fieldName: 'Unidade de medida'
    }, {
      field: 'value',
      fieldName: 'Valor esperado'
    }, {
      field: 'valueAchieved',
      fieldName: 'Valor atingido'
    }, {
      field: 'jobRoleTitle',
      fieldName: 'Cargo'
    }, {
      field: 'jobFunctionTitle',
      fieldName: 'Função'
    }, {
      field: 'department',
      fieldName: 'Departamento'
    }, {
      field: 'period',
      fieldName: 'Período'
    }, {
      field: 'comment',
      fieldName: 'Comentário'
    }, {
      field: 'description',
      fieldName: 'Descrição da Meta'
    }])
  });
});