define("avaliacao-talentrh/models/user-goal", ["exports", "@ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    // Attributes
    active: _emberData.default.attr('boolean'),
    // Associations
    user: (0, _model.belongsTo)('user'),
    goal: (0, _model.belongsTo)('goal'),
    goalsAchieved: (0, _model.hasMany)('goal-achieved')
  });
});