define("avaliacao-talentrh/pods/feedback-request/ability", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCan.Ability.extend({
    userConfigs: Ember.inject.service(),
    canManage: Ember.computed('userConfigs.isLoaded', 'record.{status,createdBy.id}', function () {
      let record = this.record;
      let sessionUser = this.get('userConfigs.sessionUser') || {};
      if (!sessionUser || !record) {
        return;
      }
      return record.get('status') === 'pending' && sessionUser.get('id') === record.get('createdBy.id');
    }),
    canAccept: Ember.computed('userConfigs.isLoaded', 'record.{status,createdBy.id}', function () {
      let record = this.record;
      let sessionUser = this.get('userConfigs.sessionUser') || {};
      if (!sessionUser || !record) {
        return;
      }
      return record.get('status') === 'pending' && sessionUser.get('id') === record.get('user.id');
    })
  });
});