define("avaliacao-talentrh/pods/feedback/show/edit-followup/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    model(params) {
      return Ember.RSVP.hash({
        feedback: this.modelFor('feedback.show'),
        followup: this.store.findRecord('followup', params.id)
      });
    },
    actions: {
      onCloseModal() {
        let parentId = this.controller.get('model.feedback.id');
        this.transitionTo('feedback.show', parentId);
      }
    }
  });
});