define("avaliacao-talentrh/pods/reports/evaluation-results/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    init() {
      this._super(...arguments);
      this.filters = {
        jobFunctions: Ember.A(),
        departments: Ember.A(),
        evaluationType: 'all'
      };
    },
    actions: {
      buildFilters() {
        let filters = this.filters;
        let build = {};
        if (filters.jobFunctions.length) {
          build.jobFunctions = filters.jobFunctions.mapBy('id');
        }
        if (filters.departments.length) {
          build.departments = filters.departments.mapBy('id');
        }
        if (filters.evaluationType !== 'all') {
          build.evaluationType = filters.evaluationType;
        }
        return build;
      }
    }
  });
});