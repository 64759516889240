define("avaliacao-talentrh/pods/components/answer-evaluation/question/info/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['pl-3', 'w-50', 'white-space-wrap'],
    classNameBindings: ['getClassNames'],
    //Default values
    maxLengthText: 500,
    showAllText: false,
    //Multi line computed
    getClassNames: Ember.computed('appraised', function () {
      if (this.appraised) {
        return 'border-right text-right';
      }
      return 'border-left';
    }),
    isTextGreaterThenLimit: Ember.computed('text', function () {
      if (!this.text) return false;
      return this.text.length > this.maxLengthText;
    }),
    textWithLimit: Ember.computed('text', 'isTextGreaterThenLimit', 'showAllText', function () {
      if (!this.text || !this.isTextGreaterThenLimit || this.showAllText) {
        return this.text;
      }
      const textWithLimit = this.text.substring(0, this.maxLengthText);
      return `${textWithLimit}...`;
    }),
    //Actions
    actions: {
      toggleShowAllText() {
        this.toggleProperty('showAllText');
      }
    }
  });
});