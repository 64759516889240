define("avaliacao-talentrh/helpers/user-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.userAvatar = userAvatar;
  function userAvatar(params /*, hash*/) {
    let url = params[0];
    return url ? url : '/images/user-sem-imagem.png';
  }
  var _default = Ember.Helper.helper(userAvatar);
  _exports.default = _default;
});