define("avaliacao-talentrh/pods/components/answer-evaluation/section-control/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    isFirstSection: Ember.computed.equal('currentSection', 0),
    isLastSection: Ember.computed('currentSection', function () {
      return this.currentSection + 1 === this.sectionList.length;
    })
  });
});