define("avaliacao-talentrh/models/employee-evaluation", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    appraisedAnswer: (0, _model.attr)('raw'),
    appraiserAnswer: (0, _model.attr)('raw'),
    consensusAnswer: (0, _model.attr)('raw'),
    appraisedConcluded: (0, _model.attr)('boolean'),
    appraiserConcluded: (0, _model.attr)('boolean'),
    teamConcluded: (0, _model.attr)('boolean'),
    consensusConcluded: (0, _model.attr)('boolean'),
    concluded: (0, _model.attr)('boolean'),
    skill: (0, _model.attr)('boolean'),
    appraiserTotal: (0, _model.attr)('number'),
    appraisedTotal: (0, _model.attr)('number'),
    consensusTotal: (0, _model.attr)('number'),
    finalTotal: (0, _model.attr)('number'),
    finalFeedback: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    type: (0, _model.attr)('number'),
    originType: (0, _model.attr)('number'),
    suggestion: (0, _model.attr)('string'),
    approved: (0, _model.attr)('boolean'),
    dismiss: (0, _model.attr)('boolean'),
    dismissalEmployee: (0, _model.attr)('boolean'),
    dismissalCompany: (0, _model.attr)('boolean'),
    periodExpired: (0, _model.attr)('number'),
    // 1: Prazo para avaliar expirou, 2: O Responsável pela avaliação permitiu responder a avaliação mesmo tento expirado o Prazo.
    reopened: (0, _model.attr)('boolean'),
    improvePoints: (0, _model.attr)('string'),
    keepPoints: (0, _model.attr)('string'),
    canceled: (0, _model.attr)('boolean'),
    cancellationReason: (0, _model.attr)('string'),
    dueDate: (0, _model.attr)('date'),
    // Data do vencimento
    expirationDate: (0, _model.attr)('date'),
    // Data que ainda é possível responder depois do vencimento
    printed: (0, _model.attr)('boolean'),
    // Indica se a avaliação foi impressa ou não.

    appraisedDate: (0, _model.attr)('date'),
    //Data resposta avaliado
    appraiserDate: (0, _model.attr)('date'),
    //Data resposta avaliador
    feedbackDate: (0, _model.attr)('date'),
    appraisedUser: (0, _model.belongsTo)('user'),
    appraiserUser: (0, _model.belongsTo)('user'),
    questionnaire: (0, _model.belongsTo)('questionnaire'),
    feedback: (0, _model.belongsTo)('feedback'),
    evaluationTeams: (0, _model.hasMany)('employeeEvaluationTeam'),
    scores: Ember.computed.mapBy('evaluationTeams', 'appraiserTotal'),
    sumOfScores: Ember.computed.sum('scores'),
    appraisersConcluded: Ember.computed.mapBy('evaluationTeams', 'appraiserConcluded'),
    averageTeam: Ember.computed('scores', 'scores.length', 'sumOfScores', function () {
      return this.sumOfScores / this.get('scores.length');
    }),
    concludedTeam: Ember.computed('appraisersConcluded.length', function () {
      var concluded = true;
      for (var i = 0; i < this.appraisersConcluded.length; i++) {
        if (!this.appraisersConcluded[i]) {
          concluded = false;
        }
      }
      return concluded;
    }),
    avarageFinal90: Ember.computed('averageTeam', 'appraiserTotal', function () {
      return (this.averageTeam + this.appraiserTotal) / 2;
    }),
    avarageFinal: Ember.computed('averageTeam', 'appraiserTotal', 'appraisedTotal', function () {
      return (this.averageTeam + this.appraiserTotal + this.appraisedTotal) / 3;
    }),
    limitDate: Ember.computed('createdAt', function () {
      var endDate = moment(`${this.createdAt}`).endOf('month');
      return endDate;
    }),
    typeDescription: Ember.computed('type', 'originType', function () {
      let types = {
        1: 'Primeira Avaliação',
        2: 'Avaliação de Efetivação',
        3: 'Avaliação de Função',
        4: 'Avaliação de Skills'
      };
      if (this.type === 4) {
        return `${types[this.originType]} - Skills`;
      }
      return types[this.type];
    }),
    isConcluded: Ember.computed.reads('concluded'),
    isAvailable: Ember.computed('canceled', 'periodExpired', function () {
      return !this.canceled && this.periodExpired !== 1;
    })
  });
  _exports.default = _default;
});