define("avaliacao-talentrh/pods/feedback/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    statusToSelect: Ember.A([{
      title: 'Aberto',
      value: 0
    }, {
      title: 'Finalizado',
      value: 1
    }]),
    init() {
      this._super(...arguments);
      if (this.session.user) {
        this.filters = {
          user: this.session.user.id
        };
      }
    },
    // Methods
    getFilters() {
      let userFilter = this.userFilter;
      let statusFilter = this.statusFilter;
      let startDateFilter = this.startDateFilter;
      let endDateFilter = this.endDateFilter;
      let query = {
        user: this.session.user.id
      };
      if (this.tabFilter === 'createdByMe') {
        query = {
          leaderUser: this.session.user.id
        };
      } else if (this.tabFilter === 'ledByMe') {
        query.user = {
          'not': this.session.user.id
        };
      }
      if (userFilter && userFilter.get('id')) {
        if (!this.tabFilter) {
          query.leaderUser = userFilter.get('id');
        } else {
          query.user = userFilter.get('id');
        }
      }
      if (startDateFilter && endDateFilter) {
        query.date = {
          ">=": (0, _moment.default)(startDateFilter).format('YYYY/MM/DD'),
          "<=": (0, _moment.default)(endDateFilter).format('YYYY/MM/DD')
        };
      }
      if (statusFilter) {
        query.status = statusFilter.value;
      }
      return query;
    },
    // Actions
    actions: {
      filter() {
        Ember.set(this, 'filters', this.getFilters());
      },
      selectTabFilter(tabFilter) {
        Ember.set(this, 'tabFilter', tabFilter);
        Ember.set(this, 'filters', this.getFilters());
      },
      clearFilters() {
        this.setProperties({
          'userFilter': null,
          'statusFilter': null,
          'startDateFilter': null,
          'endDateFilter': null
        });
        this.send('filter');
      }
    }
  });
});