define("avaliacao-talentrh/helpers/moment-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.momentFormat = momentFormat;
  function momentFormat(params /*, hash*/) {
    if (params[0]) {
      return moment(params[0]).format(params[1]);
    }
    return;
  }
  var _default = _exports.default = Ember.Helper.helper(momentFormat);
});