define("avaliacao-talentrh/pods/components/answer-evaluation/question/alternative/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['answer-evaluation__question__alternative', 'm-2 p-2 py-2 border-left border-2 border-primary']
  });
});