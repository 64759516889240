define("avaliacao-talentrh/pods/application/notification/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // HTML Attributes
    tagName: 'li',
    // Services
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    notificationService: Ember.inject.service('notification'),
    // Default values
    unreadNotifications: 0,
    // Multiline computed properties
    lastNotifications: Ember.computed('notificationService.unreadNotifications', function () {
      let notifications = this.notificationService.unreadNotifications;
      if (!notifications) {
        return;
      }
      return notifications.slice(0, 5);
    }),
    // Lifecycle hooks
    init() {
      this._super(...arguments);
      this.loadNotificationsCount.perform();
    },
    // Tasks
    loadNotificationsCount: (0, _emberConcurrency.task)(function* () {
      let {
        unreadCount
      } = yield this.ajax.request('notifications/unreadCount');
      Ember.set(this, 'unreadNotifications', unreadCount);
    }),
    // Actions
    actions: {
      setAsRead(notification) {
        io.socket.post('/notifications/' + notification.id + '/read', {
          action: 'read'
        }, (/*response*/
        ) => {
          this.notificationService.loadList();
          this.loadNotificationsCount.perform();
        });
      },
      readAllNotifications() {
        io.socket.post('/notifications/read/all', () => {
          this.notificationService.loadList();
          this.loadNotificationsCount.perform();
        });
      }
    }
  });
});