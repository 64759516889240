define("avaliacao-talentrh/pods/reports/team-evaluation-with-results/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ReportsTeamEvaluationWithResultsController = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember.computed('filtersSelected.users.length'), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _class = class ReportsTeamEvaluationWithResultsController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "filters", _descriptor2, this);
      _initializerDefineProperty(this, "filtersSelected", _descriptor3, this);
      _initializerDefineProperty(this, "showTable", _descriptor4, this);
      _defineProperty(this, "dataFields", [{
        field: 'appraised',
        fieldName: 'Avaliado'
      }, {
        field: 'evalCreatedAt',
        fieldName: 'Data de Criação'
      }, {
        field: 'evalType',
        fieldName: 'Avaliação de:'
      }, {
        field: 'appraiser',
        fieldName: 'Usuário Avaliador'
      }, {
        field: 'answerFrom',
        fieldName: 'Nota como:'
      }, {
        field: 'score',
        fieldName: 'Nota'
      }]);
    }
    get isDisabled() {
      if (this.filtersSelected.users?.length) {
        return true;
      }
      return false;
    }
    buildFilters() {
      const filters = {
        isFromDatatable: true,
        evaluationType: "all",
        userActive: "all"
      };
      const validated = this.validations(this.filtersSelected);
      if (validated) {
        filters.evaluationType = this.filtersSelected.evaluationType ?? 'all';
        filters.userActive = this.filtersSelected.userActive ?? 'all';
        filters.start = (0, _moment.default)(this.filtersSelected.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        filters.end = (0, _moment.default)(this.filtersSelected.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        if (this.filtersSelected.users?.length) {
          filters.users = this.filtersSelected.users.mapBy('id');
          filters.userActive = "all";
        }
        if (this.filtersSelected.branches?.length) {
          filters.branches = this.filtersSelected.branches.mapBy('id');
        }
        if (this.filtersSelected.departments?.length) {
          filters.departments = this.filtersSelected.departments.mapBy('id');
        }
        if (this.filtersSelected.jobRoles?.length) {
          filters.jobRoles = this.filtersSelected.jobRoles.mapBy('id');
        }
        if (this.filtersSelected.jobFunctions?.length) {
          filters.jobFunctions = this.filtersSelected.jobFunctions.mapBy('id');
        }
        this.filters = filters;
        this.showTable = true;
        return filters;
      }
    }
    validations(data) {
      if (!data.startDate || !data.endDate) {
        this.swal.warning('A data inicial e a data final precisam ser informadas.');
        return false;
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        this.swal.warning('A data inicial precisa ser igual ou anterior a data final.');
        return false;
      }
      return true;
    }
    clearFilters() {
      this.filtersSelected = {
        isFromDatatable: true,
        evaluationType: "all",
        userActive: "all"
      };
      this.filters = {
        isFromDatatable: true,
        evaluationType: "all",
        userActive: "all"
      };
      this.showTable = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        isFromDatatable: true,
        evaluationType: "all",
        userActive: "all"
      };
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "filtersSelected", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        isFromDatatable: true,
        evaluationType: "all",
        userActive: "all"
      };
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showTable", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "isDisabled", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "isDisabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildFilters", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "buildFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validations", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "validations"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype), _class);
});