define("avaliacao-talentrh/pods/pdi/form-percentage-action/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wZIwGFJk",
    "block": "{\"symbols\":[\"@pdiAction\",\"@disableInputs\"],\"statements\":[[10,\"div\"],[14,0,\"list-group-item rounded mb-2 border align-items-center d-flex bg-light\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row w-100\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-10\"],[12],[2,\"\\n      \"],[10,\"p\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-2\"],[12],[2,\"\\n      \"],[8,\"input\",[[16,\"disabled\",[32,2]],[16,\"oninput\",[30,[36,0],[[32,0],\"maxLengthCheck\"],[[\"value\"],[\"target\"]]]],[24,\"autocomplete\",\"no\"],[24,0,\"form-control\"],[24,\"placeholder\",\"Porcentagem\"],[24,\"maxlength\",\"3\"],[24,\"min\",\"0\"],[24,\"max\",\"100\"],[24,4,\"number\"]],[[\"@value\"],[[32,1,[\"percentage\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/pdi/form-percentage-action/item/template.hbs"
    }
  });
});