define("avaliacao-talentrh/pods/application/notification/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['list-group-item'],
    classNameBindings: ['unreadStyle'],
    unreadStyle: Ember.computed('notification.read', function () {
      return this.notification.read ? 'bg-white' : 'bg-light';
    }),
    linkParams: Ember.computed('notification', function () {
      let type = this.notification.type;
      let modelId = this.notification.modelId;
      let linkParams = this.notification.linkParams || {};
      let employeeEvaluation = Ember.get(this, 'notification.employeeEvaluation');
      let employeeEvaluationTeam = Ember.get(this, 'notification.employeeEvaluationTeam');
      let params;
      if (['feedback', 'followup', 'feedback-approval'].includes(type)) {
        params = {
          route: 'feedback.show',
          model: linkParams.feedback || modelId
        };
      } else if (type === 'feedback-request') {
        params = {
          route: 'feedback-request.show',
          model: modelId
        };
      } else if (type === 'pdi') {
        params = {
          route: 'pdi.show',
          model: modelId
        };
      } else if (type === 'evidence') {
        params = {
          route: 'pdi.show.show-action.show-evidence',
          models: [linkParams.pdi, linkParams.pdiAction, modelId]
        };
      } else if (['pdi-action', 'pdi-action-expired', 'pdi-action-expire-in'].includes(type)) {
        params = {
          route: 'pdi.show.show-action',
          models: [linkParams.pdi, modelId]
        };
      } else if (employeeEvaluationTeam) {
        params = {
          route: 'evaluation.answer-team',
          model: employeeEvaluationTeam
        };
      } else if (employeeEvaluation) {
        params = {
          route: 'evaluation.answer',
          model: employeeEvaluation
        };
      } else if (type === 'pde') {
        params = {
          route: 'pde.show',
          model: modelId
        };
      } else if (type === 'pde-action') {
        params = {
          route: 'pde.show.show-action',
          models: [linkParams.pde, modelId]
        };
      } else if (type === 'pde-evidence') {
        params = {
          route: 'pde.show.show-action.show-evidence',
          models: [linkParams.pde, linkParams.pdeAction, modelId]
        };
      }
      return params;
    })
  });
  _exports.default = _default;
});