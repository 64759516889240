define("avaliacao-talentrh/components/ui-sidebar/menu-item/submenu/submenu-item", ["exports", "ui-nela/components/ui-sidebar/menu-item/submenu/submenu-item"], function (_exports, _submenuItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _submenuItem.default;
    }
  });
});