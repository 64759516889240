define("avaliacao-talentrh/pods/evaluations/answer-list/question/info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "E6XXBz4e",
    "block": "{\"symbols\":[\"@appraisedText\",\"@icon\",\"@appraiserText\"],\"statements\":[[10,\"span\"],[15,0,[31,[\"pr-3 border-right w-50 text-right white-space-wrap \",[30,[36,1],[[35,0]],null]]]],[12],[1,[30,[36,2],[[32,1],\"Não informado\"],null]],[13],[2,\"\\n\\n\"],[10,\"i\"],[15,0,[31,[\"fa \",[32,2],\" text-primary mt-1 \",[30,[36,3],[[35,0],\"px-3\",\"pr-3\"],null]]]],[12],[13],[2,\"\\n\\n\"],[10,\"span\"],[14,0,\"pl-3 border-left w-50 white-space-wrap\"],[12],[1,[30,[36,2],[[32,3],\"Não informado\"],null]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"appraised\",\"show-if\",\"coalesce\",\"if\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/evaluations/answer-list/question/info/template.hbs"
    }
  });
});