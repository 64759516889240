define("avaliacao-talentrh/pods/pde/form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Services
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    type: '',
    // Life Cycle
    didInsertElement() {
      this._super(...arguments);
      this.onLoadForm(this.save);
      Ember.set(this, 'type', this.pde.skill.get('type') || 'hard');
    },
    // Concurrency Tasks
    save: (0, _emberConcurrency.task)(function* () {
      if (this.pde.validations.isInvalid) {
        return this.swal.validation(this.pde.validations);
      }
      let message = this.pde.isNew ? 'PDE cadastrado' : 'PDE atualizado';
      try {
        let pde = yield this.pde.save();
        this.toast.success(message);
        if (this.afterSave) {
          this.afterSave(pde);
        }
        this.router.transitionTo('pde.show', pde.id);
      } catch (e) {
        this.swal.catch(e);
      }
    })
  });
});