define("avaliacao-talentrh/authenticators/super-user", ["exports", "ember-simple-auth-token/authenticators/jwt"], function (_exports, _jwt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _jwt.default.extend({
    session: Ember.inject.service(),
    init() {
      this._super(...arguments);
      const owner = Ember.getOwner(this);
      const environment = owner ? owner.resolveRegistration('config:environment') || {} : {};
      this.serverTokenEndpoint = environment.apiUrl.conta + '/auth/superUserLogin';
      this.headers = this.session.get('authorizationBearer');
    }
  });
});