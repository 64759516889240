define("avaliacao-talentrh/pods/external-evaluation/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    showButton: Ember.computed('userConfigs.accessProfile.{admin,generalManager}', 'userConfigs.sessionUser.isLeader', function () {
      const isShown = this.userConfigs.accessProfile.admin || this.userConfigs.accessProfile.generalManager || this.userConfigs.sessionUser.isLeader;
      return isShown;
    }),
    // Custom methods
    getFilters() {
      try {
        let userFilter = this.userFilter;
        let initialDate = this.initial;
        let finalDate = this.finalDate;
        let query = {};
        if (userFilter && userFilter.get('id')) {
          query.appraisedUser = userFilter.get('id');
        }
        if (initialDate && finalDate) {
          query.date = {
            '>=': moment(initialDate).format('YYYY-MM-DD 00:00:00'),
            '<=': moment(finalDate).format('YYYY-MM-DD 23:59:59')
          };
        }
        return query;
      } catch (e) {
        this.swal.catch(e);
      }
    },
    // Actions
    actions: {
      filter() {
        let filters = this.getFilters();
        Ember.set(this, 'filters', filters);
      },
      clearFilters() {
        Ember.setProperties(this, {
          userFilter: null,
          initialDate: null,
          finalDate: null
        });
        this.send('filter');
      }
    }
  });
});