define("avaliacao-talentrh/pods/components/answer-evaluation/question/comment/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['answer-evaluation__question__comment', 'form-group'],
    maxLengthComment: 1000
  });
  _exports.default = _default;
});