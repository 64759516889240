define("avaliacao-talentrh/pods/manage-goals/export-goals/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "EBrXEmA+",
    "block": "{\"symbols\":[\"Modal\",\"Footer\"],\"statements\":[[8,\"ui-modal\",[[24,\"data-backdrop\",\"static\"]],[[\"@open\",\"@modalSize\",\"@onCloseModal\"],[true,\"modal-lg\",[30,[36,0],[[32,0],\"onCloseModal\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      Exportar Metas\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"p\"],[12],[2,\"1) Serão exportadas as metas selecionadas, através do filtro realizado, para uma planilha.\"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"2) Na planilha serão exibidos todos os dados das metas exportadas.\"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"3) Informando na planilha os valores atingidos, é possível utilizar a opção “Importar Metas” para atualizar estes valores no sistema.\"],[13],[2,\"\\n\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"button\"],[14,0,\"btn btn-primary mr-2\"],[15,\"onclick\",[30,[36,1],[[32,0,[\"exportGoals\"]]],null]],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fas fa-inbox-in\"],[12],[13],[2,\" Exportar planilha\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"perform\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/manage-goals/export-goals/template.hbs"
    }
  });
  _exports.default = _default;
});