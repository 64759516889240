define("avaliacao-talentrh/pods/components/chart-performance-by-question/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "O1uqUdcy",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"card-header bg-white h5\"],[12],[1,[35,0,[\"question\"]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n  \"],[10,\"canvas\"],[14,\"width\",\"1200\"],[14,\"height\",\"450\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"data\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/components/chart-performance-by-question/template.hbs"
    }
  });
  _exports.default = _default;
});