define("avaliacao-talentrh/models/team", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    // Attributes
    description: (0, _attr.default)('string'),
    type: (0, _attr.default)('number'),
    // BelongsTo
    company: (0, _relationships.belongsTo)('company'),
    // HasMany
    users: (0, _relationships.hasMany)('user', {
      inverse: null
    }),
    leaders: (0, _relationships.hasMany)('user', {
      inverse: null
    })
  });
});