define("avaliacao-talentrh/pods/printed-evaluation/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    userConfigs: Ember.inject.service(),
    router: Ember.inject.service(),
    accessProfile: Ember.computed.alias('userConfigs.accessProfile'),
    deactivate() {
      this.controllerFor('printed-evaluation').set('filters', {
        printed: 1
      });
    },
    beforeModel() {
      const allowed = this.accessProfile.admin || this.accessProfile.generalManager;
      if (!allowed) {
        return this.router.transitionTo('errors.forbidden');
      }
    }
  });
  _exports.default = _default;
});