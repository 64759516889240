define("avaliacao-talentrh/pods/indicators/pdi-detail/route", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    router: Ember.inject.service(),
    usersICanManage: Ember.computed.alias('userConfigs.usersICanManage.users'),
    accessProfile: Ember.computed.alias('userConfigs.accessProfile'),
    isLeader: Ember.computed.alias('userConfigs.sessionUser.isLeader'),
    async setupController(controller, model) {
      this._super(controller, model);
      if (model.data) {
        controller.set('data', model.data);
      } else {
        const {
          data
        } = await this.modelTask.perform(model.user_id, model.pdi_id);
        controller.set('data', data);
      }
    },
    model(params) {
      const allowed = (this.accessProfile.admin || this.accessProfile.generalManager || this.isLeader) && this.usersICanManage.includes(+params.user_id);
      if (!allowed) {
        return this.router.transitionTo('errors.forbidden');
      }
      return this.modelTask.perform(params.user_id, params.pdi_id);
    },
    modelTask: (0, _emberConcurrency.task)(function* (userId, pdiId) {
      try {
        const data = yield this.ajax.request('/pdi/findPdiDetail', {
          data: {
            userId,
            pdiId
          }
        });
        return Ember.RSVP.hash({
          data
        });
      } catch (e) {
        Ember.debug(e);
      }
    })
  });
  _exports.default = _default;
});