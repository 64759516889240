define("avaliacao-talentrh/pods/pde/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    statusOptions: Ember.A([{
      title: 'Aberto',
      value: 0
    }, {
      title: 'Finalizado',
      value: 1
    }]),
    // Attributes
    getFilters() {
      let initialDate = this.initial;
      let finalDate = this.finalDate;
      let teamId = Ember.get(this, 'team.id');
      let query = {};
      if (this.tabFilter === 'received') {
        query.owner = {
          'not': this.userConfigs.sessionUser.id
        };
      } else if (this.tabFilter === 'ledByMe') {
        query.owner = this.userConfigs.sessionUser.id;
      }
      if (this.status) {
        query.status = this.status.value;
      }
      if (teamId) {
        query.team = teamId;
      }
      if (initialDate && finalDate) {
        initialDate = moment(initialDate).format('YYYY-MM-DD HH:mm');
        finalDate = moment(finalDate).add(23, 'hour').add(59, 'minute').format('YYYY-MM-DD HH:mm');
        query.createdAt = {
          '>=': initialDate,
          '<=': finalDate
        };
      }
      return query;
    },
    actions: {
      filter() {
        let filters = this.getFilters();
        Ember.set(this, 'filters', filters);
      },
      clearFilters() {
        Ember.setProperties(this, {
          team: null,
          status: 'all',
          initial: null,
          finalDate: null
        });
        this.send('filter');
      },
      selectTabFilter(tabFilter) {
        Ember.set(this, 'tabFilter', tabFilter);
        Ember.set(this, 'filters', this.getFilters());
      }
    }
  });
});