define("avaliacao-talentrh/pods/components/performance-indicator-viwer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    filteredData: Ember.computed('sectionFilter', 'data', function () {
      let data = this.data;
      if (!data) {
        return;
      }
      let sectionFilter = this.sectionFilter;
      data = Ember.Object.create(data);
      if (!data) {
        return;
      }
      if (!sectionFilter) {
        return data;
      }
      let filteredData = {};
      filteredData.header = data.header.filter(header => {
        return header.section === sectionFilter;
      });
      filteredData.body = data.body.map(group => {
        return group.map(row => {
          let cloneRow = Ember.Object.create(row);
          cloneRow.performanceData = row.performanceData.filter(pd => {
            if (!pd.appraiserAnswer) return false;
            return pd.appraiserAnswer.section === sectionFilter;
          });
          return cloneRow;
        });
      });
      return filteredData;
    })
  });
});