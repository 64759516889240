define("avaliacao-talentrh/helpers/app-url-projects", ["exports", "avaliacao-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.appUrl = appUrl;
  _exports.default = void 0;
  function appUrl(params /*, hash*/) {
    let projectUrl = _environment.default[params[0]];
    return projectUrl ? projectUrl + (params[1] || '') : '';
  }
  var _default = _exports.default = Ember.Helper.helper(appUrl);
});