define("avaliacao-talentrh/pods/evaluations/show/results/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    // Services
    userConfigs: Ember.inject.service(),
    router: Ember.inject.service(),
    // Default values
    evaluationType: null,
    // Alias
    evaluation: Ember.computed.alias('model'),
    allowConsensusInEmployee180Evaluation: Ember.computed.alias('userConfigs.companyConfiguration.allowConsensusInEmployee180Evaluation'),
    // One line Computed
    showTeam: Ember.computed.and('evaluation.averageTeam', 'evaluation.teamConcluded'),
    // Multi line computed
    showAppraised: Ember.computed('evaluation.{appraisedTotal,appraiserConcluded}', function () {
      return this.evaluation.appraiserConcluded && (this.evaluation.appraisedTotal || this.evaluation.appraisedTotal === 0);
    }),
    showEvaluationScore: Ember.computed('evaluation.appraisedUser.id', 'userConfigs.companyConfiguration.showNoteEmployee', function () {
      let appraisedId = Ember.get(this, 'evaluation.appraisedUser.id');
      return this.userConfigs.iCanManageUser(appraisedId) || Ember.get(this, 'userConfigs.companyConfiguration.showNoteEmployee');
    }),
    showEvaluationResult: Ember.computed('evaluation.{type,appraisedUser.id}', function () {
      let appraisedId = Ember.get(this, 'evaluation.appraisedUser.id');
      return this.evaluation.type !== 3 && this.userConfigs.iCanManageUser(appraisedId);
    }),
    showConsensus: Ember.computed('allowConsensusInEmployee180Evaluation', 'evaluation.{consensusAnswer,originType}', 'evaluationType', function () {
      return (this.evaluationType === 2 || [2, 3].includes(this.evaluation.originType)) && this.allowConsensusInEmployee180Evaluation && this.evaluation.consensusAnswer;
    }),
    actions: {
      closeModal() {
        this.router.transitionTo('evaluations.show');
      }
    }
  });
});