define("avaliacao-talentrh/pods/evaluations/show-team/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vmYsSd4A",
    "block": "{\"symbols\":[\"View\",\"Section\",\"Header\"],\"statements\":[[8,\"ui-crud/view\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"section\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,3,[\"title\"]],[[24,0,\"font-weight-bold\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Dados Gerais\"]],\"parameters\":[]}]]],[2,\"\\n\\n      \"],[8,[32,3,[\"btnGroup\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"link-to\",[[24,0,\"btn btn-outline-primary btn-sm mr-2\"]],[[\"@route\",\"@model\"],[\"evaluation.answer-team\",[34,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"i\"],[14,0,\"fa fa-file-signature\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\" Avaliar\\n          \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n\\n    \"],[8,\"evaluations/evaluation-view\",[],[[\"@evaluation\"],[[34,0,[\"employeeEvaluation\"]]]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"evaluationTeam\",\"canRate\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/evaluations/show-team/template.hbs"
    }
  });
  _exports.default = _default;
});