define("avaliacao-talentrh/pods/evaluation/answer-team/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model({
      id
    }) {
      return this.store.findRecord('employeeEvaluationTeam', id, {
        reload: true
      });
    },
    afterModel(model) {
      let sessionUserId = null;
      if (this.userConfigs && this.userConfigs.sessionUser && this.userConfigs.sessionUser.id) {
        sessionUserId = this.userConfigs.sessionUser.id;
      } else if (this.userConfigs && this.userConfigs.session && this.userConfigs.session.user && this.userConfigs.session.user.id) {
        sessionUserId = this.userConfigs.session.user.id;
      }
      const appraiserId = model.appraiserUser.get('id');
      const appraisedId = model.appraisedUser.get('id');
      const {
        appraisedConcluded,
        appraiserConcluded
      } = model;
      if (appraiserConcluded && appraiserId === sessionUserId || appraisedConcluded && appraisedId === sessionUserId) {
        this.replaceWith('/evaluation/answer-forbidden');
      }
    }
  });
});