define("avaliacao-talentrh/utils/formatDate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatDate;
  function formatDate(date) {
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    };
    return new Intl.DateTimeFormat('pt-BR', options).format(date);
  }
});