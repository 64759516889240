define("avaliacao-talentrh/pods/pde/show/show-action/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "alamhnMG",
    "block": "{\"symbols\":[\"Card\",\"Header\"],\"statements\":[[8,\"ui-card\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[[24,0,\"bg-white\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"title\"]],[[24,0,\"text-primary\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Evidências\"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\\n  \"],[8,\"pde/team-user-list\",[],[[\"@pdeAction\"],[[34,0]]],null],[2,\"\\n\\n  \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"pdeAction\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/pde/show/show-action/index/template.hbs"
    }
  });
  _exports.default = _default;
});