define("avaliacao-talentrh/services/user-configs", ["exports", "ember-concurrency", "avaliacao-talentrh/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    // Services
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    // Attributes
    isLoaded: false,
    sessionUser: null,
    accessProfile: null,
    userPreferences: null,
    usersICanManage: null,
    companyConfiguration: null,
    teamsICanManage: null,
    admin: Ember.computed.alias('accessProfile.admin'),
    // Life Cycle Methods
    init() {
      this._super(...arguments);
    },
    // Concurrency Tasks
    loadUserConfigs: (0, _emberConcurrency.task)(function* () {
      try {
        let store = this.store;
        let ajax = this.ajax;
        let isLoaded = this.isLoaded;
        if (!Ember.get(this, 'session.isAuthenticated') || isLoaded) {
          return;
        }
        if (!Ember.get(this, 'session.user')) {
          yield this.session.loadMe.perform();
        }
        let {
          id,
          company
        } = Ember.get(this, 'session.user') || {};
        let [sessionUser, companyConfiguration, usersICanManage, teamsICanManage] = yield Ember.RSVP.all([store.findRecord('user', id), store.findRecord('companyConfiguration', company), ajax.request('/users/usersICanManage'), ajax.request('teams/iCanSee')]);
        this.set('companyConfiguration', companyConfiguration);
        this.set('sessionUser', sessionUser);
        this.set('usersICanManage', usersICanManage);
        this.set('teamsICanManage', teamsICanManage);
        if (!this.companyConfiguration.hasAVDModule) {
          yield this.swal.error('Você não tem acesso a este módulo. Entre em contato com seu administrador do sistema para mais informações.');
          let href = '';
          if (this.companyConfiguration.hasPortalColabModule) {
            href = _environment.default.appUrl.social;
          } else if (this.companyConfiguration.hasPortalGestorModule) {
            href = _environment.default.appUrl.conta;
          } else if (this.companyConfiguration.hasProjectManagerModule) {
            href = _environment.default.appUrl.projetos;
          } else if (this.companyConfiguration.hasTEDModule) {
            href = _environment.default.appUrl.ted;
          } else if (this.companyConfiguration.hasPesquisaModule) {
            href = _environment.default.appUrl.pesquisa;
          } else {
            href = _environment.default.appUrl.agenda;
          }
          document.location.href = href;
        } else {
          console.log('service user-configs 1');
          if (sessionUser) {
            let [accessProfile, userPreference] = yield Ember.RSVP.all([sessionUser.get('accessProfile'), ajax.request('/userPreferences/me')]);
            console.log('service user-configs 2');
            if (accessProfile) {
              if (accessProfile.closedIfAway && this.session.user.away) {
                yield this.swal.error({
                  title: 'Ops!',
                  text: 'Seu perfil não tem acesso no momento. Entre em  contato com seu administrador do sistema para mais informações',
                  type: 'error',
                  showCancelButton: false,
                  showConfirmButton: true,
                  allowOutsideClick: false
                });
                return this.session.invalidate();
              } else {
                this.set('accessProfile', accessProfile);
              }
            }
            if (userPreference) {
              store.pushPayload(userPreference);
              let emberDataUserPreference = store.peekRecord('userPreference', userPreference.userPreferences.id);
              this.set('userPreferences', emberDataUserPreference);
            }
            this.set('isLoaded', true);
          }
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    }).drop(),
    // Methods
    /* Retorna se eu posso gerenciar o usuário informado no userId */
    iCanManageUser(userId) {
      let me = this.sessionUser;
      let usersICanManage = this.usersICanManage;
      if (!usersICanManage) {
        return;
      }

      // Mesmo podendo gerenciar todos os usuário não pode gerenciar a si mesmo.
      return usersICanManage.users.includes(Number(userId)) || usersICanManage.canManageAll && me.get('id') !== String(userId);
    }
  });
});