define("avaliacao-talentrh/pods/components/goal-achieved-chart/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    fetch: Ember.inject.service(),
    tagName: 'tr',
    value: Ember.computed.alias('goal.achieved.value'),
    goalAchieveDate: Ember.A([]),
    goalAchieveValue: Ember.A([]),
    goalValue: null,
    goalValueAux: null,
    goalUnit: "",
    charts: null,
    data: Ember.A([]),
    buildChart(goalUnit) {
      let chart = this.chart;
      let previousValue = this.goalValue;
      let firstTime = true;
      let data = {
        labels: this.goalAchieveDate,
        datasets: [{
          data: this.goalAchieveValue,
          backgroundColor: 'rgba(132, 88, 157, 0.5)',
          borderColor: 'rgb(108, 88, 157)',
          borderWidth: 1
        }]
      };
      if (this.chart) {
        chart = this.chart;
        chart.data = data;
        chart.update();
      } else {
        let ctx = document.getElementById('chart').getContext('2d');
        chart = new Chart(ctx, {
          type: 'bar',
          responsive: true,
          data: data,
          options: {
            scales: {
              yAxes: [{
                ticks: {
                  min: 0,
                  max: this.goalValue ? this.goalValue : 10,
                  callback: function (value) {
                    if (previousValue) {
                      if (value < previousValue && value <= previousValue - 10 || firstTime) {
                        if (goalUnit === 'hours') {
                          let str = '';
                          let duration = _moment.default.duration(Number(value), 'minutes');
                          let hours = parseInt(duration.as('hours'));
                          if (hours) {
                            str += `${hours}h `;
                          }
                          if (duration.get('minutes')) {
                            str += `${duration.get('minutes')}m`;
                          }
                          if (!str) {
                            return '0h 0m';
                          }
                          firstTime = false;
                          previousValue = value;
                          return str;
                        } else if (goalUnit === 'percentage') {
                          let str = '';
                          str = value + '%';
                          firstTime = false;
                          previousValue = value;
                          return str;
                        } else if (goalUnit === 'monetary') {
                          let str = Intl.NumberFormat('pt-br', {
                            currency: 'BRL',
                            style: 'currency',
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          }).format(value);
                          firstTime = false;
                          previousValue = value;
                          return str;
                        } else {
                          firstTime = false;
                          previousValue = value;
                          return value;
                        }
                      }
                    } else {
                      return value;
                    }
                  }
                }
              }]
            },
            legend: {
              display: false
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem) {
                  if (goalUnit === 'hours') {
                    let str = '';
                    let duration = _moment.default.duration(Number(tooltipItem.value), 'minutes');
                    let hours = parseInt(duration.as('hours'));
                    if (hours) {
                      str += `${hours}h `;
                    }
                    if (duration.get('minutes')) {
                      str += `${duration.get('minutes')}m`;
                    }
                    if (!str) {
                      tooltipItem.yLabel = 'Não informado';
                    }
                    tooltipItem.yLabel = str;
                    return tooltipItem.yLabel;
                  } else if (goalUnit === 'percentage') {
                    let str = '';
                    str = tooltipItem.yLabel + '%';
                    return str;
                  } else if (goalUnit === 'monetary') {
                    let str = Intl.NumberFormat('pt-br', {
                      currency: 'BRL',
                      style: 'currency',
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    }).format(tooltipItem.yLabel);
                    return str;
                  } else {
                    return tooltipItem.yLabel;
                  }
                }
              }
            }
          }
        });
      }
      this.charts = chart;
    },
    formatedValue(unit, value) {
      if (unit === 'percentage') {
        this.goalValue = parseFloat(this.goalValue) * 100;
        let valueReturn = parseFloat(value) * 100;
        return parseFloat(valueReturn.toFixed(2));
      }
      if (unit === 'monetary') {
        this.goalValue = parseFloat(this.goalValue);
        value = parseFloat(value);
        return value;
      }
      if (unit === 'numeric') {
        this.goalValue = parseFloat(this.goalValue);
        value = parseFloat(value);
        return value;
      }
      if (unit === 'hours') {
        this.goalValue = parseInt(this.goalValue);
        return parseInt(value);
      }
      return value;
    },
    buildData: (0, _emberConcurrency.task)(function* () {
      const goal = yield this.goal.userGoal.get('goal');
      let achievedGoals = this.goal.goalAchieves.toArray();
      achievedGoals.forEach(goal => {
        goal.achieveDate = (0, _moment.default)(goal.achieveDate, 'DD/MM/YYYY').toDate();
      });
      achievedGoals = achievedGoals.sort((a, b) => {
        return b.achieveDate - a.achieveDate;
      });
      if (achievedGoals && achievedGoals.length) {
        achievedGoals.forEach(achieved => {
          if (!this.goalValueAux) {
            if (goal.unitType === 'between') {
              this.goalValue = goal.maxValue;
            } else {
              this.goalValue = goal.value;
            }
            this.goalValueAux = this.formatedValue(goal.unit, this.goalValue);
          }
          this.goalUnit = achievedGoals[0].unit;
          const formatedValue = this.formatedValue(goal.unit, achieved.value);
          if (formatedValue > this.goalValueAux) {
            this.goalValueAux = formatedValue;
          }
          let month = (0, _moment.default)(achieved.achieveDate, 'DD/MM/YYYY').format('MMMM/YYYY');
          month = month.charAt(0).toUpperCase() + month.slice(1);
          this.data.push({
            month: month,
            value: formatedValue,
            updatedAt: achieved.updatedAt
          });
        });
        this.data.forEach(item => {
          if (!this.goalAchieveDate.includes(item.month)) {
            this.goalAchieveDate.push(item.month);
            this.goalAchieveValue.push(item.value);
          }
        });
        this.goalAchieveValue = this.goalAchieveValue.reverse();
        this.goalAchieveDate = this.goalAchieveDate.reverse();
        let auxValuePerMonth;
        const goalAchievedValuesperMonth = [];
        for (let i = 0; i < this.goalAchieveValue.length; i++) {
          i == 0 ? auxValuePerMonth = this.goalAchieveValue[i] : auxValuePerMonth = this.goalAchieveValue[i] - this.goalAchieveValue[i - 1];
          goalAchievedValuesperMonth.push(auxValuePerMonth.toFixed(2));
        }
        this.goalAchieveValue = goalAchievedValuesperMonth;
        this.goalValue = this.goalValueAux;
        yield this.buildChart(this.goalUnit);
      } else {
        yield this.buildChart(this.goalUnit);
      }
    }).drop(),
    didInsertElement() {
      this._super(...arguments);
      this.goalAchieveValue = [];
      this.goalAchieveDate = [];
      this.data = [];
      this.buildData.perform();
    }
  });
  _exports.default = _default;
});