define("avaliacao-talentrh/pods/application/navbar/component", ["exports", "ember-concurrency", "avaliacao-talentrh/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    session: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    openPersonalDataModal: false,
    // Computed
    supportLink: Ember.computed('userConfigs.companyConfiguration.subscription.sellType', function () {
      const companyConfig = this.userConfigs.companyConfiguration;
      if (!companyConfig) return "https://viasoft.movidesk.com/";
      if (companyConfig.subscription.sellType === 'convencional') {
        return "https://viasoft.movidesk.com/";
      } else if (companyConfig.subscription.sellType === 'saas' || companyConfig.subscription.sellType === 'embarcada') {
        return "https://atendimento.viasoft.com.br/form/3664/";
      }
      return "https://viasoft.movidesk.com/";
    }),
    // Life Cycle
    init() {
      this._super(...arguments);
      if (this.session.data.keybkp) {
        this.canReturnToAccount = true;
      }
    },
    // Tasks
    logout: (0, _emberConcurrency.task)(function* () {
      yield this.session.invalidate();
    }).drop(),
    superUserReturn: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          userAccountToken,
          user,
          company
        } = this.session.data.keybkp;
        yield this.session.authenticate('authenticator:jwt', {
          token: userAccountToken,
          user: user.id,
          company: company.id
        });
        this.session.set('data.keybkp', undefined);
        location.reload();
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    getPersonalDataReport: (0, _emberConcurrency.task)(function* () {
      let swalLoading = null;
      try {
        const headers = {};
        const authToken = this.session.token;
        if (authToken) {
          headers['Authorization'] = `Bearer ${authToken}`;
        }
        let url = `${_environment.default.apiUrl.social}/receipts/personalData`;
        swalLoading = this.swal.loading('Gerando o pdf...');
        const response = yield fetch(url, {
          method: 'GET',
          headers
        });
        const report = yield response.blob();
        const objectURL = URL.createObjectURL(report);
        const link = document.createElement('a');
        link.href = objectURL;
        link.download = 'Voors-Dados-Pessoais-LGPD.pdf';
        document.body.append(link);
        link.click();
        link.remove();
        swalLoading.close();
      } catch (e) {
        if (swalLoading) swalLoading.close();
        this.swal.catch(e);
      }
    }).drop(),
    actions: {
      requestPersonalData() {
        console.log("Executou");
        Ember.set(this, 'openPersonalDataModal', !this.openPersonalDataModal);
      }
    }
  });
  _exports.default = _default;
});