define("avaliacao-talentrh/pods/evaluations/evaluation-view/team-modal/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    leaders: Ember.A(),
    users: Ember.A(),
    userStatus: Ember.A(),
    loadUsers: (0, _emberConcurrency.task)(function* () {
      try {
        const appraisedUser = yield this.evaluation.get('appraisedUser');
        const team = yield this.ajax.request(`/teams/appraisedUser/${appraisedUser.id}`);
        let users = yield this.ajax.request(`/teams/${team.id}/users`);
        users = users.user;
        let evaluationTeams = yield this.store.query('employee-evaluation-team', {
          employeeEvaluation: this.evaluation.get('id')
        });
        evaluationTeams = evaluationTeams.toArray();
        for (const [i, user] of users.entries()) {
          if (user.jobFunction) {
            const jf = yield this.store.findRecord('job-function', user.jobFunction);
            user.jobFunction = jf.title;
          }
          if (user.id == appraisedUser.id) {
            user.concluded = this.evaluation.get('appraisedConcluded');
          } else {
            const teamEval = evaluationTeams.filter(e => e.appraiserUser.get('id') == user.id);
            if (teamEval.length) {
              user.concluded = teamEval[0].appraiserConcluded;
            }
            if (!user.active && !user.concluded) {
              users.splice(i, 1);
            }
          }
        }
        Ember.set(this, 'users', users);
      } catch (error) {
        console.log(error);
      }
    }).drop().on('didUpdateAttrs'),
    loadLeaders: (0, _emberConcurrency.task)(function* () {
      try {
        const appraisedUser = yield this.evaluation.get('appraisedUser');
        const team = yield this.ajax.request(`/teams/appraisedUser/${appraisedUser.id}`);
        let leaders = yield this.ajax.request(`/teams/${team.id}/leaders`);
        leaders = leaders.user;
        for (const user of leaders) {
          if (user.jobFunction) {
            const jf = yield this.store.findRecord('job-function', user.jobFunction);
            user.jobFunction = jf.title;
          }
        }
        Ember.set(this, 'leaders', leaders);
      } catch (error) {
        console.log(error);
      }
    }).drop().on('didUpdateAttrs'),
    // Actions
    actions: {
      onShow() {
        Ember.set(this, 'show', true);
      }
    }
  });
  _exports.default = _default;
});