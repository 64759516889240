define("avaliacao-talentrh/pods/application/notification/simple-notification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "riPvxZ+t",
    "block": "{\"symbols\":[\"@notification\"],\"statements\":[[6,[37,0],[[32,1,[\"read\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"i\"],[14,0,\"far fa-dot-circle small text-primary position-absolute r-2 t-2\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,1,[\"relatedUser\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ui-avatar\",[[24,0,\"avatar-4 flex-shrink-0\"],[16,\"alt\",[32,1,[\"relatedUser\",\"fullName\"]]]],[[\"@src\"],[[32,1,[\"relatedUser\",\"avatar\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"ml-2\"],[12],[2,\"\\n  \"],[10,\"h6\"],[14,0,\"mb-0\"],[12],[1,[32,1,[\"message\"]]],[13],[2,\"\\n  \"],[10,\"small\"],[12],[1,[30,[36,2],[[32,1,[\"createdAt\"]],\"DD/MM/YYYY HH:mm\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"unless\",\"if\",\"moment-format\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/application/notification/simple-notification/template.hbs"
    }
  });
});