define("avaliacao-talentrh/models/job-function", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    description: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean'),
    company: _emberData.default.belongsTo('company'),
    questionnaire: _emberData.default.belongsTo('questionnaire'),
    goals: _emberData.default.hasMany('goal', {
      inverse: 'jobFunctions'
    })
  });
  _exports.default = _default;
});