define("avaliacao-talentrh/pods/manage-goals/show-achieve/route", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    beforeModel(transition) {
      const filters = this.controllerFor('manage-goals').get('filters');
      if (!filters || !filters.startAt || !filters.endAt) {
        transition.abort();
        this.transitionTo('manage-goals');
      }
    },
    model(params = {}) {
      return this.modelTask.perform(params);
    },
    deactivate() {
      this.controller.get('model.goalAchieved').rollbackAttributes();
      this.controllerFor('manage-goals').getGoals.perform();
    },
    modelTask: (0, _emberConcurrency.task)(function* (params) {
      try {
        const userGoal = yield this.store.findRecord('user-goal', params['user_goal_id']);
        return Ember.RSVP.hash({
          goalAchieved: this.store.createRecord('goal-achieved'),
          goalAchieves: this.store.query('goal-achieved', {
            userGoal: Ember.get(userGoal, 'id')
          }),
          userGoal
        });
      } catch (e) {
        Ember.debug(e);
      }
    })
  });
});