define("avaliacao-talentrh/pods/components/evaluation-status/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    userConfigs: Ember.inject.service(),
    evaluationType: null,
    // Alias
    allowConsensusInEmployee180Evaluation: Ember.computed.alias('userConfigs.companyConfiguration.allowConsensusInEmployee180Evaluation'),
    companyConfig: Ember.computed.alias('userConfigs.companyConfiguration'),
    // Computed
    appraiser: Ember.computed('evaluation.appraiserConcluded', function () {
      if (Ember.get(this, 'evaluation.appraiserConcluded')) {
        return this.concludedStatus('Avaliador');
      } else {
        return this.pendingStatus('Avaliador');
      }
    }),
    appraised: Ember.computed('evaluation.{appraisedConcluded,typeEvaluation,appraisedUser.id,appraiserUser.id}', function () {
      if (Ember.get(this, 'evaluation.typeEvaluation') === 90 && appraiserAndAppraised) {
        return;
      } else if (Ember.get(this, 'evaluation.appraisedConcluded')) {
        return this.concludedStatus('Avaliado');
      } else {
        return this.pendingStatus('Avaliado');
      }
    }),
    team: Ember.computed('evaluation.{teamConcluded,evaluationTeams.length}', function () {
      if (!Ember.get(this, 'evaluation.evaluationTeams') || !Ember.get(this, 'evaluation.evaluationTeams.length')) {
        return;
      } else if (Ember.get(this, 'evaluation.teamConcluded')) {
        return this.concludedStatus('Equipe');
      } else {
        return this.pendingStatus('Equipe');
      }
    }),
    consensus: Ember.computed('evaluation.consensusConcluded', 'evaluationType', 'allowConsensusInEmployee180Evaluation', function () {
      if (this.evaluationType !== 2 || !this.evaluation.get('consensusAnswer')) {
        return;
      } else if (Ember.get(this, 'evaluation.consensusConcluded')) {
        return this.concludedStatus('Consenso');
      } else {
        return this.pendingStatus('Consenso');
      }
    }),
    // Tasks
    getEvaluationType: (0, _emberConcurrency.task)(function* () {
      const appraisedUser = yield this.evaluation.get('appraisedUser');
      yield appraisedUser.reload();
      const evaluationType = appraisedUser.get('typeEvaluation');
      if (evaluationType) {
        return Ember.set(this, 'evaluationType', evaluationType);
      }
      const type = this.evaluation.type;
      if (type === 1) {
        return Ember.set(this, 'evaluationType', this.companyConfig.typeFirstEvaluation);
      }
      if (type === 2) {
        return Ember.set(this, 'evaluationType', this.companyConfig.typeLastEvaluation);
      }
      if (type === 3) {
        return Ember.set(this, 'evaluationType', this.companyConfig.typeFunctionEvaluation);
      }
      if (type === 4) {
        return Ember.set(this, 'evaluationType', this.companyConfig.typeFunctionEvaluation);
      }
    }).drop().on('didUpdateAttrs').on('didInsertElement'),
    // Methods
    pendingStatus(role) {
      return {
        title: 'Pendente',
        icon: 'fa fa-clock text-warning',
        role
      };
    },
    concludedStatus(role) {
      return {
        title: 'Concluído',
        icon: 'fa fa-check-circle text-success',
        role
      };
    }
  });
  _exports.default = _default;
});