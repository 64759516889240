define("avaliacao-talentrh/pods/components/answer-evaluation/section-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['list-group', 'list-group-flush', 'max-h-vh-4', 'overflow-auto'],
    sections: Ember.computed('currentSection', 'finishError', 'sectionList', function () {
      let finishError = this.finishError || {};
      return (this.sectionList || []).map((section, index) => {
        let unjustifiedQuestions = this.haveErrors(finishError.unjustifiedQuestions, section);
        let unansweredQuestions = this.haveErrors(finishError.unansweredQuestions, section);
        return {
          description: section.description,
          error: unjustifiedQuestions || unansweredQuestions,
          selectedClass: index === this.currentSection ? 'text-primary' : 'text-body'
        };
      });
    }),
    haveErrors() {
      let questionErrors = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      let section = arguments.length > 1 ? arguments[1] : undefined;
      return !!questionErrors.findBy('section', section.id);
    }
  });
  _exports.default = _default;
});