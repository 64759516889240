define("avaliacao-talentrh/models/goal-achieved", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* globals moment */

  const Validations = (0, _emberCpValidations.buildValidations)({
    value: (0, _emberCpValidations.validator)('inline', {
      dependentKeys: ['unit', 'value'],
      validate(value, options, model) {
        const unit = model.unit;
        if (value) return true;
        if (!unit || unit === 'monetary') {
          return 'É necessário informar um valor';
        } else if (unit === 'percentage') {
          return 'É necessário informar um percentual';
        } else if (unit === 'hours') {
          return 'É necessário informar as horas';
        } else if (unit === 'numeric') {
          return 'É necessário informar uma quantidade';
        } else if (unit === 'qualitative') {
          return 'É necessário informar um resultado';
        }
      }
    }),
    achieveDate: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'É necessário informar uma data'
    }),
    userGoal: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'É necessário informar uma meta'
    })
  });
  var _default = _model.default.extend(Validations, {
    // Attributes
    value: (0, _model.attr)('string'),
    comment: (0, _model.attr)('string'),
    achieveDate: (0, _model.attr)('date'),
    taskDetails: (0, _model.attr)('string'),
    projectDetails: (0, _model.attr)('string'),
    // Associations
    userGoal: (0, _model.belongsTo)('user-goal'),
    // Computed
    unit: Ember.computed('userGoal', function () {
      return this.userGoal.get('goal').get('unit');
    }),
    valueFormatted: Ember.computed('value', 'unit', function () {
      if (this.unit === 'percentage') {
        return Intl.NumberFormat('pt-br', {
          style: 'percent',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }).format(this.value);
      }
      if (this.unit === 'monetary') {
        return Intl.NumberFormat('pt-br', {
          currency: 'BRL',
          style: 'currency',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }).format(this.value);
      }
      if (this.unit === 'hours') {
        let str = '';
        let duration = moment.duration(this.value, 'minutes');
        let hours = parseInt(duration.as('hours'));
        if (hours) {
          str += `${hours}h `;
        }
        if (duration.get('minutes')) {
          str += `${duration.get('minutes')}min`;
        }
        if (!str) {
          return 'Não informado';
        }
        return str;
      }
      return this.value;
    })
  });
  _exports.default = _default;
});