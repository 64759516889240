define("avaliacao-talentrh/pods/components/evaluation-finish-actions/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // HTML Attributes
    tagName: '',
    // Services
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    updateEvaluationAnswer() {
      let evaluation = this.evaluation;
      let appraisedUserId = evaluation.get('appraisedUser.id');
      let appraiserUserId = evaluation.get('appraiserUser.id');
      let userId = Ember.get(this, 'userConfigs.sessionUser.id');
      if (this.isConsensus) {
        evaluation.set('consensusAnswer', this.evaluationAnswer);
      } else if (this.evaluationTeam || appraiserUserId === userId || this.answerFor === 'appraiserUser') {
        evaluation.set('appraiserAnswer', this.evaluationAnswer);
      } else if (appraisedUserId === userId || this.answerFor === 'appraisedUser') {
        evaluation.set('appraisedAnswer', this.evaluationAnswer);
      }
      return evaluation;
    },
    // Custom methods
    checkUnansweredQuestion(err) {
      if (err) {
        let unansweredQuestions = err.unansweredQuestions;
        let unjustifiedQuestions = err.unjustifiedQuestions;
        if (unansweredQuestions && unansweredQuestions.length) {
          this.swal.warning('É necessário responder todas as questões antes de continuar.');
        }
        if (unjustifiedQuestions && unjustifiedQuestions.length) {
          this.swal.warning('Há respostas que precisam ser justificadas.');
        }
      } else {
        this.swal.catch(err);
      }
    },
    // Actions
    actions: {
      save() {
        let evaluation = this.updateEvaluationAnswer();
        if (!evaluation) {
          return;
        }
        evaluation.save().then(data => {
          if (this.sendToEvaluation) {
            this.sendToEvaluation(data);
          }
        }).catch(err => {
          this.swal.catch(err);
        });
      },
      async finish() {
        let evaluation = this.updateEvaluationAnswer();
        if (!evaluation) {
          return;
        }
        const configuration = await this.store.findRecord('companyConfiguration', this.userConfigs.accessProfile.company.get('id'));
        const idUser = this.userConfigs.sessionUser.id;
        let msg = 'Após a finalização da Avaliação, o Avaliado poderá visualizar as notas e/ou resultado.\n Finalizar Avaliação?';
        if (Ember.get(evaluation, 'appraisedUser.id') == idUser || !configuration.get('showEvaluationComparison') && !configuration.get('showNoteEmployee')) {
          msg = 'Finalizar Avaliação?';
        }
        this.swal.confirm(msg).then(result => {
          if (!result.value) {
            return;
          }
          let url = `/employeeEvaluations/${Ember.get(evaluation, 'id')}/finishAppraiserUser`;
          if (Ember.get(evaluation, 'appraisedUser.id') == idUser && Ember.get(evaluation, 'appraiserUser.id') !== idUser) {
            url = `/employeeEvaluations/${Ember.get(evaluation, 'id')}/finishAppraisedUser`;
          }
          if (this.answerFor && (this.generalManager || this.isAdmin)) {
            url = `/employeeEvaluations/${Ember.get(evaluation, 'id')}/finishProfileRh?answerFor=${this.answerFor}`;
          }
          if (this.evaluationTeam) {
            url = `/employeeEvaluationTeams/${Ember.get(evaluation, 'id')}/finishAppraiserTeam`;
          }
          if (this.isConsensus) {
            url = `/employeeEvaluations/${Ember.get(evaluation, 'id')}/finishConsensus`;
          }
          console.log({
            url
          });
          this.ajax.post(url, {
            data: {
              model: JSON.stringify(evaluation)
            }
          }).then(data => {
            this.store.pushPayload(data);
            if (this.sendToEvaluation) {
              this.sendToEvaluation(data);
            }
          }).catch(err => {
            this.checkUnansweredQuestion(err.payload);
            if (this.onFinishError) {
              this.onFinishError(err.payload);
            }
          });
        });
      }
    }
  });
});