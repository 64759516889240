define("avaliacao-talentrh/pods/evaluations/show/answers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kXleR6k3",
    "block": "{\"symbols\":[\"Modal\",\"Footer\"],\"statements\":[[8,\"ui-modal\",[],[[\"@open\",\"@modalSize\",\"@onCloseModal\",\"@data-backdrop\"],[true,\"modal-xl\",[30,[36,0],[[32,0],\"closeModal\"],null],\"static\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Respostas da Avaliação\"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"body\"]],[[24,0,\"p-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"ul\"],[14,0,\"list-group list-group-flush\"],[12],[2,\"\\n      \"],[10,\"li\"],[14,0,\"list-group-item py-4\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n          \"],[10,\"img\"],[14,0,\"avatar-5\"],[15,\"src\",[34,1,[\"appraisedUser\",\"avatar\"]]],[14,\"alt\",\"Author\"],[12],[13],[2,\"\\n\\n          \"],[10,\"div\"],[14,0,\"mt-2\"],[12],[2,\"\\n            \"],[10,\"h6\"],[14,0,\"font-weight-bold mb-1\"],[12],[1,[35,1,[\"appraisedUser\",\"fullName\"]]],[13],[2,\"\\n            \"],[10,\"h6\"],[14,0,\"mb-0\"],[12],[1,[35,1,[\"appraisedUser\",\"jobFunction\",\"title\"]]],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"evaluations/answer-list\",[],[[\"@evaluation\",\"@class\"],[[34,1],[34,2]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"cancel\"]],[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"evaluation\",\"appraiserListClass\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/evaluations/show/answers/template.hbs"
    }
  });
  _exports.default = _default;
});