define("avaliacao-talentrh/pods/analytics/nine-box/cycle/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AnalyticsNineBoxCycleNewRoute extends Ember.Route {
    model() {
      return this.store.createRecord('nine-box-cycle');
    }
  }
  _exports.default = AnalyticsNineBoxCycleNewRoute;
});