define("avaliacao-talentrh/pods/components/comments/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Services
    swal: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    store: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Default values
    createComment: false,
    text: '',
    // Tasks
    save: (0, _emberConcurrency.task)(function* () {
      try {
        const {
          company
        } = this.userConfigs.sessionUser;
        let prop = this.isPdi ? 'pdi' : 'pdiEvidence';
        let modeName = this.isPdi ? 'pdi-comment' : 'pdi-evidence-comment';
        let data = {
          createdBy: this.userConfigs.sessionUser,
          company: company,
          body: this.text
        };
        data[prop] = this.model;
        let comment = this.store.createRecord(modeName, data);
        comment = yield comment.save();
        this.comments.pushObject(comment);
        this.model.reload();
        this.toast.success('Comentário adicionado');
        this.send('clearComment');
      } catch (e) {
        return this.swal.catch(e);
      }
    }).drop(),
    // Actions
    actions: {
      autofocus(e) {
        if (!e) return;
        e.focus();
      },
      clearComment() {
        Ember.setProperties(this, {
          createComment: false,
          text: ''
        });
      }
    }
  });
});