define("avaliacao-talentrh/pods/evaluations/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    afterModel(model, transition) {
      if (this.controller && transition.to.localName === 'show-team') {
        Ember.set(this, 'controller.tabFilter', 'team');
      }
    }
  });
});