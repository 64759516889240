define("avaliacao-talentrh/pods/manage-goals/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    // Default values
    filters: null,
    userFilter: null,
    goalFilter: null,
    branchFilter: null,
    departmentFilter: null,
    jobFunctionFilter: null,
    jobRoleFilter: null,
    endDateFilter: null,
    startDateFilter: null,
    nameFilter: null,
    goals: Ember.A(),
    // Multiline computed
    canUseUserFilters: Ember.computed('userFilter.length', 'canUseFilters', function () {
      return (!this.userFilter || !this.userFilter.length) && this.canUseFilters;
    }),
    canUseBranchFilters: Ember.computed('goalFilter.length', 'canUseFilters', function () {
      return (!this.goalFilter || !this.goalFilter.length) && this.canUseFilters;
    }),
    canUseFilters: Ember.computed('startDateFilter', 'endDateFilter', function () {
      return this.startDateFilter && this.endDateFilter;
    }),
    formatedStartAt: Ember.computed('startDateFilter', function () {
      var vDate = moment(`${this.startDateFilter}`);
      if (vDate.isValid()) {
        return vDate.format('YYYY-MM-DD');
      } else {
        return null;
      }
    }),
    formatedEndAt: Ember.computed('endDateFilter', function () {
      var vDate = moment(`${this.endDateFilter}`);
      if (vDate.isValid()) {
        return vDate.format('YYYY-MM-DD');
      } else {
        return null;
      }
    }),
    // LifeCycles
    init() {
      this._super(...arguments);
    },
    // Methods
    getFilters() {
      let filters = {};
      if (this.departmentFilter) {
        filters.department = this.departmentFilter.id;
      }
      if (this.branchFilter) {
        filters.branch = this.branchFilter.id;
      }
      if (this.userFilter) {
        filters.users = this.userFilter.map(u => u.id);
      }
      if (this.goalFilter) {
        filters.goals = this.goalFilter.map(g => g.id);
      }
      if (this.jobFunctionFilter) {
        filters.jobFunction = this.jobFunctionFilter.id;
      }
      if (this.jobRoleFilter) {
        filters.jobRole = this.jobRoleFilter.id;
      }
      if (this.endDateFilter) {
        filters.endAt = moment(this.endDateFilter).format('YYYY-MM-DD');
      }
      if (this.startDateFilter) {
        filters.startAt = moment(this.startDateFilter).format('YYYY-MM-DD');
      }
      Ember.set(this, 'filters', filters);
    },
    // Tasks
    getGoals: (0, _emberConcurrency.task)(function* () {
      try {
        if (!this.endDateFilter || !this.startDateFilter) {
          return this.swal.warning('Período inicial e período fim são obrigatórios');
        }
        if (moment(this.endDateFilter).isBefore(this.startDateFilter)) {
          return this.swal.warning('O período inicial precisa ser igual ou anterior ao período fim.');
        }
        this.swal.loading("Buscando as metas...");
        yield this.getFilters();
        this.swal.close();
        Ember.set(this, 'openModalFilter', false);
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    // Actions
    actions: {
      clearFilters() {
        Ember.setProperties(this, {
          userFilter: null,
          goalFilter: null,
          branchFilter: null,
          departmentFilter: null,
          jobFunctionFilter: null,
          jobRoleFilter: null,
          endDateFilter: null,
          startDateFilter: null,
          filters: null,
          goals: []
        });
      },
      clearBranchFilter() {
        Ember.set(this, 'branchFilter', null);
      },
      setNameFilter(name) {
        Ember.set(this, 'nameFilter', name);
      }
    }
  });
  _exports.default = _default;
});