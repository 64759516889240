define("avaliacao-talentrh/pods/reports/print-evaluation-employee/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    swal: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.filters = {
        showFeedback: false,
        showResponse: false,
        employeeEvaluation: null
      };
    },
    actions: {
      buildFilters() {
        let filters = this.filters;
        let build = {};
        if (filters.employeeEvaluation) {
          build.employeeEvaluation = filters.employeeEvaluation.id;
        }
        build.showFeedback = filters.showFeedback;
        build.showResponse = filters.showResponse;
        return build;
      },
      validateFilters() {
        if (this.filters.employeeEvaluation) {
          return true;
        } else {
          this.swal.warning('É necessário informar uma avaliação');
          return;
        }
      },
      cleanEmployeeEvaluation() {
        if (this.filters.employeeEvaluation) {
          Ember.set(this, 'filters.employeeEvaluation', '');
        }
      }
    }
  });
});