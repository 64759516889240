define("avaliacao-talentrh/pods/reports/performance-indicators/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    // Defulat values
    typeFilter: 'question',
    evaluationType: 'all',
    visualization: 'appraiser',
    sectionFilter: '',
    showSubtitle: false,
    jobFunctions: Ember.A(),
    users: Ember.A(),
    departments: Ember.A(),
    // Alias
    allowConsensusInEmployee180Evaluation: Ember.computed.alias('userConfigs.companyConfiguration.allowConsensusInEmployee180Evaluation'),
    buildQueryFilter() {
      let objFilter = {};
      if (this.typeFilter) {
        objFilter.typeFilter = this.typeFilter;
      }
      if (this.startDate && this.endDate) {
        objFilter.startDate = moment(this.startDate).format('DD/MM/YYYY');
        objFilter.endDate = moment(this.endDate).format('DD/MM/YYYY');
      }
      if (this.visualization) {
        objFilter.visualization = this.visualization;
      }
      if (this.evaluationType !== 'all') {
        objFilter.evaluationType = this.evaluationType;
      }
      if (this.users.get('length')) {
        objFilter.users = this.users.mapBy('id');
      }
      if (this.departments.get('length')) {
        objFilter.departments = this.departments.mapBy('id');
      }
      if (this.jobFunctions.get('length')) {
        objFilter.jobFunctions = this.jobFunctions.mapBy('id');
      }
      if (this.showConsensus) {
        objFilter.showConsensus = 1;
      }
      return jQuery.param(objFilter);
    },
    actions: {
      printTeste() {
        window.print();
      },
      generate(model) {
        if (this.startDate && this.endDate) {
          let startDateM = moment(this.startDate);
          let endDateM = moment(this.endDate);
          if (endDateM.isBefore(startDateM)) {
            this.swal.warning('A data Final deve ser posterior a data Inicial.');
            return;
          }
        }
        this.swal.loading('Gerando Relatório...');
        this.ajax.request('/reports/generate/' + 'performanceIndicators/?' + this.buildQueryFilter(model)).then(reportData => {
          this.swal.close();
          Ember.set(this, 'reportData', reportData);
          if (!reportData || !reportData.header.length) {
            this.swal.info('Não existem indicadores para os filtros informados.');
          }
        }).catch(err => {
          Ember.set(this, 'reportData', null);
          this.swal.catch(err);
        });
      }
    }
  });
  _exports.default = _default;
});