define("avaliacao-talentrh/pods/manage-goals/goals/data-grid-goals/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'tr',
    swal: Ember.inject.service(),
    store: Ember.inject.service(),
    valueFormatted: Ember.computed('goal.{unit,value,unitType}', 'minValueFormatted', 'maxValueFormatted', function () {
      if (this.goal.unit === 'Qualitativa') {
        return "Qualitativa";
      }
      if (this.goal.unitType === 'between') {
        return `${this.minValueFormatted} e ${this.maxValueFormatted}`;
      } else {
        return this.getValueFormatted(this.goal.value, this.goal.unit);
      }
    }),
    valueAchievedFormatted: Ember.computed('goal.{unit,valueAchieved}', function () {
      return this.getValueFormatted(this.goal.valueAchieved, this.goal.unit);
    }),
    minValueFormatted: Ember.computed('goal.{unit,minValue}', function () {
      return this.getValueFormatted(this.goal.minValue, this.goal.unit);
    }),
    maxValueFormatted: Ember.computed('goal.{unit,maxValue}', function () {
      return this.getValueFormatted(this.goal.maxValue, this.goal.unit);
    }),
    updateUserGoal: (0, _emberConcurrency.task)(function* (goal) {
      if (goal) {
        try {
          let userGoal = yield this.store.findRecord('userGoal', goal.id);
          Ember.set(this, 'goal.active', goal.active === 1 ? 0 : 1);
          userGoal.active = !userGoal.active;
          yield userGoal.save();
          this.swal.success('Registro salvo com sucesso!');
        } catch (e) {
          this.swal.catch(e);
        }
      }
    }).drop(),
    didRender() {
      this._super(...arguments);
    },
    // Methods
    getValueFormatted(value, unit) {
      if (unit === 'Porcentagem') {
        value = Number(value.replace(',', '.'));
        return Intl.NumberFormat('pt-br', {
          style: 'percent',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }).format(value);
      }
      if (unit === 'Monetária') {
        value = Number(value.replace(',', '.'));
        return Intl.NumberFormat('pt-br', {
          currency: 'BRL',
          style: 'currency',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }).format(value);
      }
      if (unit === 'Qualitativa') {
        return value === 'S' ? 'Atingiu' : 'Não atingiu';
      }
      if (unit === 'Horas') {
        let str = '';
        let duration = moment.duration(+value, 'minutes');
        let hours = parseInt(duration.as('hours'));
        if (hours) {
          str += `${hours}h `;
        }
        if (duration.get('minutes')) {
          str += `${duration.get('minutes')}min`;
        }
        if (!str) {
          return 'Não informado';
        }
        return str;
      }
      return value;
    },
    actions: {
      openCloseUserGoal(goal) {
        if (goal.active) {
          this.swal.confirm('Deseja fechar a meta?', {
            text: 'A meta fechada não poderá receber atualizações.'
          }).then(result => {
            if (result.value) {
              this.updateUserGoal.perform(goal);
            }
          });
        } else {
          this.swal.confirm('Deseja reabrir a meta?', {
            text: 'A meta reaberta passará receber atualizações.'
          }).then(result => {
            if (result.value) {
              this.updateUserGoal.perform(goal);
            }
          });
        }
      }
    }
  });
  _exports.default = _default;
});