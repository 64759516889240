define("avaliacao-talentrh/pods/evaluations/show/add-feedback/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    swal: Ember.inject.service(),
    // Computed Properties
    evaluation: Ember.computed.alias('model'),
    // Tasks
    save: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.model.save();
        this.swal.success('Feedback salvo com sucesso.');
        this.send('closeModal');
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    actions: {
      closeModal() {
        this.transitionToRoute('evaluations.show');
      }
    }
  });
  _exports.default = _default;
});