define("avaliacao-talentrh/pods/pde/show/show-action/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model() {
      return this.modelFor('pde.show.show-action');
    }
  });
  _exports.default = _default;
});