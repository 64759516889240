define("avaliacao-talentrh/pods/pde/team-user-list/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // HTML Attributes
    classNames: ['card', 'bg-white', 'cursor-pointer', 'mb-1'],
    // Services
    session: Ember.inject.service(),
    // Multiline comptued properties
    userEvidences: Ember.computed('pdeAction.evidences.@each.createdBy', function () {
      let evidences = Ember.get(this, 'pdeAction.evidences');
      if (!evidences) {
        return;
      }
      return evidences.filterBy('createdBy.id', Ember.get(this, 'user.id'));
    }),
    canManageEvidence: Ember.computed('pdeAction.{status,evidences.@each.createdBy}', function () {
      let pde = Ember.get(this, 'pdeAction.pde');
      let pdeAction = this.pdeAction;
      if (!pde || !pdeAction) {
        return;
      }
      return !Ember.get(pdeAction, 'isExpired') && Ember.get(pde, 'status') !== 1 && Ember.get(pdeAction, 'status') !== 1 && parseInt(Ember.get(this, 'user.id')) === this.session.user.id;
    }),
    // Multi line computed properties
    noCanManageMessage: Ember.computed('pdeAction.{pde,status}', function () {
      let pde = Ember.get(this, 'pdeAction.pde');
      let pdeAction = this.pdeAction;
      let message;
      if (!pde || !pdeAction) {
        return;
      }
      if (Ember.get(pde, 'isExpired')) {
        message = 'Este pde expirou';
      } else if (Ember.get(pdeAction, 'isExpired')) {
        message = 'Esta Ação de PDE expirou';
      } else if (Ember.get(pde, 'status') === 1) {
        message = 'Este pde foi finalizado';
      } else if (Ember.get(pdeAction, 'status') === 1) {
        message = 'Esta Ação de PDE foi finalizada';
      } else {
        message = 'Somente Leitura';
      }
      return message;
    })
  });
});