define("avaliacao-talentrh/pods/evaluation/answer-consensus/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "PVeh4qSL",
    "block": "{\"symbols\":[\"Page\",\"Header\",\"Crumb\"],\"statements\":[[8,\"ui-page\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Responder Avaliação\"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,2,[\"breadcrumbs\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,3,[\"link\"]],[],[[\"@text\",\"@link\"],[\"Início\",\"home\"]],null],[2,\"\\n      \"],[8,[32,3,[\"link\"]],[],[[\"@text\"],[\"Responder Avaliação\"]],null],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"section\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"answer-evaluation\",[],[[\"@evaluation\",\"@answerFor\"],[[34,0,[\"employeeEvaluation\"]],[34,0,[\"answerFor\"]]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/evaluation/answer-consensus/template.hbs"
    }
  });
  _exports.default = _default;
});