define("avaliacao-talentrh/pods/evaluations/show/answers/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    evaluation: Ember.computed.alias('model'),
    appraiserGridClass: Ember.computed('evaluation.id', function () {
      return this.evaluation.appraisedAnswer ? 'col-md-6' : 'col-12';
    }),
    appraiserListClass: Ember.computed('evaluation.id', function () {
      return this.evaluation.appraisedAnswer ? 'border-right' : '';
    }),
    actions: {
      closeModal() {
        this.transitionToRoute('evaluations.show');
      }
    }
  });
  _exports.default = _default;
});