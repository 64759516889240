define("avaliacao-talentrh/pods/evaluations/answer-list/team-answers/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    collapsedIcon: false,
    didRender() {
      this._super(...arguments);
    },
    actions: {
      toggleCollapsedIcon() {
        Ember.set(this, 'collapsedIcon', !this.collapsedIcon);
      }
    }
  });
  _exports.default = _default;
});