define("avaliacao-talentrh/models/goal", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* globals moment */

  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'É necessário informar um título'
    }),
    description: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'É necessário informar uma descrição'
    }),
    unit: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'É necessário informar uma unidade'
    }),
    value: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'É necessário informar um valor'
    }),
    startAt: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'É necessário informar uma data inicial'
    }),
    endAt: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'É necessário informar uma data de encerramento'
    })
  });
  var _default = _exports.default = _model.default.extend(Validations, {
    // Attributes
    title: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    informationSource: (0, _model.attr)('string'),
    unit: (0, _model.attr)('string'),
    unitType: (0, _model.attr)('string'),
    value: (0, _model.attr)('string'),
    minValue: (0, _model.attr)('string'),
    maxValue: (0, _model.attr)('string'),
    startAt: (0, _model.attr)('date'),
    endAt: (0, _model.attr)('date'),
    // Associations
    company: (0, _model.belongsTo)('company'),
    branches: (0, _model.hasMany)('branch', {
      inverse: 'goals'
    }),
    departments: (0, _model.hasMany)('department', {
      inverse: 'goals'
    }),
    jobRoles: (0, _model.hasMany)('job-role', {
      inverse: 'goals'
    }),
    jobFunctions: (0, _model.hasMany)('jobFunctions', {
      inverse: 'goals'
    }),
    valueLabel: Ember.computed('unit', function () {
      if (this.unit === 'numeric') return 'Quantidade';
      if (this.unit === 'percentage') return 'Percentual';
      if (this.unit === 'monetary') return 'Valor';
      if (this.unit === 'hours') return 'Horas';
      if (this.unit === 'qualitative') return 'Resultado';
      return 'Valor';
    }),
    valueFormatted: Ember.computed('maxValueFormatted', 'minValueFormatted', 'unit', 'unitType', 'value', function () {
      if (this.unit === 'qualitative') {
        return "Qualitativa";
      }
      if (this.unitType === 'between') {
        return `${this.minValueFormatted} e ${this.maxValueFormatted}`;
      } else {
        return this._getValueFormatted(this.value, this.unit);
      }
    }),
    minValueFormatted: Ember.computed('unit', 'minValue', function () {
      return this._getValueFormatted(this.minValue, this.unit);
    }),
    maxValueFormatted: Ember.computed('unit', 'maxValue', function () {
      return this._getValueFormatted(this.maxValue, this.unit);
    }),
    _getValueFormatted(value, unit) {
      if (unit === 'percentage') {
        return Intl.NumberFormat('pt-br', {
          style: 'percent',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }).format(value);
      }
      if (unit === 'monetary') {
        return Intl.NumberFormat('pt-br', {
          currency: 'BRL',
          style: 'currency',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }).format(value);
      }
      if (unit === 'qualitative') {
        return value === 'S' ? 'Atingiu' : 'Não atingiu';
      }
      if (unit === 'hours') {
        let str = '';
        let duration = moment.duration(Number(value), 'minutes');
        let hours = parseInt(duration.as('hours'));
        if (hours) {
          str += `${hours}h `;
        }
        if (duration.get('minutes')) {
          str += `${duration.get('minutes')}min`;
        }
        if (!str) {
          return 'Não informado';
        }
        return str;
      }
      return value;
    }
  });
});