define("avaliacao-talentrh/pods/pdi/form-action/component", ["exports", "ember-concurrency", "avaliacao-talentrh/config/environment", "moment"], function (_exports, _emberConcurrency, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Services
    swal: Ember.inject.service(),
    router: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    type: '',
    skillUrl: `${_environment.default.appUrl.conta}registry/avd/skill/create?to=toCreateSkill`,
    descriptionPlaceholder: 'Descreva como essa ação levará ao alcance do objetivo desejado.',
    canCreateSkills: Ember.computed.or('userConfigs.admin', 'userConfigs.accessProfile.generalManager'),
    suggestions: Ember.computed(function () {
      return [{
        title: 'Assumir novos desafios',
        description: 'Condução de projetos, padronização de processos, maior exposição a situações as quais o colaborador ainda não tem o desempenho desejado (condução de reuniões e falar em público, por exemplo), são exemplos de atividades que impactam fortemente no desenvolvimento de competências.'
      }, {
        title: 'Coaching e Mentoring',
        description: 'Muito usados quando se deseja mudar comportamentos. Pode ser realizado por profissionais habilitados e/ou profissionais que sejam referência na competência e estejam dispostos a atuar como orientadores internos.'
      }, {
        title: 'Coaching interno',
        description: 'O coach é o próprio líder, que deve atuar como um treinador que ensina “jogadas”, dá diretrizes e estimula o desenvolvimento do liderado.'
      }, {
        title: 'Congressos, Palestras, Seminários',
        description: 'Eventos normalmente de curta duração com objetivo de atualização em determinada área de especialidade. Possibilitam também o estreitamento de relações entre profissionais (networking). Podem ser realizados interna ou externamente.'
      }, {
        title: 'Cursos/Programas de treinamento',
        description: 'Necessário nos casos em que falta habilidade para executar alguma tarefa específica. Programas realizados interna ou externamente, que visam à capacitação em conhecimentos, habilidades, atitudes (treinamentos comportamentais) ou de formação profissional para determinada atividade da empresa. Podem ser oferecidos nos dois formatos.'
      }, {
        title: 'Multiplicação de conhecimento',
        description: 'Necessário quando a equipe precisa adquirir um novo conhecimento que já é dominado por um colaborador (muito útil na preparação para o job rotation).'
      }, {
        title: 'Intercâmbio em outras áreas',
        description: 'Refere-se à alocação temporária do colaborador em outra área, visando à disseminação de determinado projeto, iniciativa ou know-how.'
      }, {
        title: 'Facilitador de treinamento',
        description: 'Envolve a preparação e aplicação de treinamentos como forma de reciclar seus conceitos e difundi-los.'
      }, {
        title: 'Fóruns/Comunidades externas',
        description: 'A participação em grupos e entidades externas normalmente relaciona-se à atualização profissional, possibilita a troca de experiências, ampliação do relacionamento com profissionais da mesma área ou setor de atuação, gerando até a possibilidade de desenvolver ações conjuntas com recursos compartilhados.'
      }, {
        title: 'Grupos de trabalho',
        description: 'Refere-se à participação ou coordenação de grupos internos, criando situações em que seja possível aprimorar relacionamentos e compartilhar conhecimentos e informações.'
      }, {
        title: 'Leitura de Livros',
        description: 'Necessário nos casos em que se deseja ampliar o conhecimento do colaborador sobre algum assunto. Indicações de leitura que contenham os principais conceitos sobre as competências e/ou expliquem como elas se materializam nas ações.'
      }, {
        title: 'Benchmarking',
        description: 'Benchmarking é um processo de estudo da concorrência, para analisar as melhores práticas usadas por uma empresa de um mesmo setor, averiguando se elas podem ser replicadas em sua empresa.'
      }, {
        title: 'Filmes',
        description: 'Indicações que elucidam a expressão da competência na ação.'
      }, {
        title: 'Melhores práticas',
        description: 'Metodologia utilizada por organizações para aperfeiçoar sua gestão, através da realização sistemática de levantamentos e análises de práticas, processos, produtos e serviços prestados por outras. Sua aplicação gera informações importantes sobre diferentes formas de lidar com situações e problemas semelhantes, contribuindo para que possam aperfeiçoar seus próprios processos de trabalho.'
      }];
    }),
    // Life Cycle
    didInsertElement() {
      this._super(...arguments);
      this.onLoadForm(this.save);
      Ember.set(this, 'type', this.model.skill.get('type') || 'hard');
    },
    // Concurrency Tasks
    save: (0, _emberConcurrency.task)(function* () {
      let model = this.model;
      let firstAction = false;
      if (!model.validations.isValid) {
        return this.swal.validation(model.validations);
      }
      if ((0, _moment.default)(model.expirationDate).isAfter(this.pdi.expirationDate)) {
        return this.swal.warning('A data de expiração da ação não pode ser posterior a data de expiração do PDI.');
      }
      try {
        model.set('pdi', this.pdi);
        model.set('user', this.pdi.user);
        const pdeActions = Ember.get(this, 'pdi.pdiActions');
        if (pdeActions.length <= 1) {
          model.set('percentage', 100);
          firstAction = true;
        }
        let created = yield model.save();
        this.swal.success('A ação de PDI foi salva com sucesso.');
        if (this.afterSave) {
          this.afterSave(created);
        }
        if (firstAction) {
          this.router.transitionTo('pdi.show.show-action', created.id);
        } else {
          this.router.transitionTo('pdi.show.percentage-action', {
            queryParams: {
              pdiActionCreatedId: created.id
            }
          });
        }
      } catch (e) {
        return this.swal.catch(e);
      }
    }),
    actions: {
      useSuggestion(suggestion) {
        Ember.set(this, 'model.title', suggestion.title);
        Ember.set(this, 'descriptionPlaceholder', `Dica: ${suggestion.description}`);
      }
    }
  });
});