define("avaliacao-talentrh/helpers/api-url", ["exports", "avaliacao-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.apiUrl = apiUrl;
  _exports.default = void 0;
  function apiUrl(params /*, hash*/) {
    if (params[0] && params[0].includes('gravatar')) {
      return params[0];
    }
    return _environment.default.apiBaseUrl + params[0];
  }
  var _default = Ember.Helper.helper(apiUrl);
  _exports.default = _default;
});