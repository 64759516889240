define("avaliacao-talentrh/pods/reports/print-evaluation/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    swal: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.filters = {
        questionnaire: null,
        showFeedback: null
      };
    },
    actions: {
      validateFilters() {
        if (!this.filters.questionnaire) {
          this.swal.warning('Informe um questionário para continuar.');
          return;
        }
        return true;
      },
      buildFilters() {
        let filters = this.filters;
        let build = {};
        if (filters.questionnaire) {
          build.questionary = filters.questionnaire.id;
        }
        build.showFeedback = filters.showFeedback;
        return build;
      }
    }
  });
  _exports.default = _default;
});