define("avaliacao-talentrh/pods/pdi/show/edit-action/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model(params) {
      return this.store.findRecord('pdi-action', params.id).then(pdiAction => {
        return Ember.RSVP.hash({
          pdi: this.modelFor('pdi.show'),
          pdiAction
        });
      }).catch(() => {
        this.router.transitionTo('pdi.new');
      });
    },
    deactivate() {
      this.controller.get('model.pdiAction').rollbackAttributes();
    },
    actions: {
      onCloseModal() {
        let parentId = this.controller.get('model.pdi.id');
        this.transitionTo('pdi.show', parentId);
      }
    }
  });
  _exports.default = _default;
});