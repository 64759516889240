define("avaliacao-talentrh/pods/evaluation/answer-forbidden/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    model(params, transition) {
      return Ember.RSVP.hash({
        title: 'Ops!',
        subtitle: 'Você já respondeu esta avaliação!'
      });
    }
  });
});