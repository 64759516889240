define("avaliacao-talentrh/pods/feedback-request/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kKP3PUWg",
    "block": "{\"symbols\":[\"pdi\",\"record\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-6\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"Solicitar Para\"],[13],[2,\"\\n\\n      \"],[8,\"nela-select\",[],[[\"@modelName\",\"@endPoint\",\"@searchEnabled\",\"@selected\",\"@placeholder\",\"@disabled\"],[\"user\",\"/users/usersCanManageMeAjax\",true,[34,0,[\"user\"]],\"Selecione um colaborador\",[30,[36,1],[[35,0,[\"isNew\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,2,[\"firstName\"]]],[2,\" \"],[1,[32,2,[\"lastName\"]]],[2,\"\\n      \"]],\"parameters\":[2]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-md-6\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"PDI (opcional)\"],[13],[2,\"\\n\\n      \"],[8,\"nela-select\",[],[[\"@modelName\",\"@searchEnabled\",\"@selected\",\"@query\",\"@placeholder\",\"@disabled\"],[\"pdi\",true,[34,0,[\"pdi\"]],[30,[36,2],null,[[\"user\"],[[35,0,[\"user\",\"id\"]]]]],\"Selecione um PDI\",[30,[36,1],[[35,0,[\"isNew\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,1,[\"objective\"]]],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"Descrição\"],[13],[2,\"\\n\\n      \"],[1,[30,[36,3],null,[[\"class\",\"placeholder\",\"value\"],[\"form-control\",\"Informe uma Descrição\",[35,0,[\"description\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"not\",\"hash\",\"textarea\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/feedback-request/form/template.hbs"
    }
  });
  _exports.default = _default;
});