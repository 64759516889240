define("avaliacao-talentrh/pods/components/answer-evaluation/section-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TMkkWjKG",
    "block": "{\"symbols\":[\"section\",\"sectionIndex\",\"@currentSection\"],\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"li\"],[14,0,\"list-group-item\"],[12],[2,\"\\n    \"],[10,\"button\"],[15,0,[31,[\"btn btn-link w-100 py-0 text-left \",[32,1,[\"selectedClass\"]]]]],[15,\"onclick\",[30,[36,1],[[32,0],[30,[36,0],[[32,3]],null],[32,2]],null]],[12],[2,\"\\n      \"],[1,[30,[36,2],[[32,2],1],null]],[2,\" - \"],[1,[32,1,[\"description\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,3],[[32,1,[\"error\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[14,0,\"fas fa-exclamation-circle fa-sm text-danger position-absolute r-4 centralize-y\"],[14,\"title\",\"Há pendências nesta seção\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"action\",\"sum\",\"if\",\"sections\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/components/answer-evaluation/section-list/template.hbs"
    }
  });
  _exports.default = _default;
});