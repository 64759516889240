define("avaliacao-talentrh/pods/pde/form-pde-evidence/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Services
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    // Default values
    files: Ember.A([]),
    // Multine compted properties
    fileSelected: Ember.computed('files.length', function () {
      return this.files[0];
    }),
    // Lifecycle hooks
    didInsertElement() {
      this._super(...arguments);
      this.onLoadForm(this.save);
    },
    willDestroyElement() {
      this.files.clear();
    },
    // Tasks
    save: (0, _emberConcurrency.task)(function* () {
      let pde = this.pdeAction.pde;
      if (!this.description) {
        return this.swal.warning('É necessário informar a descrição.');
      }
      try {
        // Evidência com anexo
        if (this.fileSelected) {
          let file = this.fileSelected;
          let data = {
            type: file.blob.type,
            filename: file.blob.name,
            model: 'pdeEvidence',
            parentModel: 'pdeAction',
            parentModelId: this.get('pdeAction.id')
          };
          this.swal.loading('Estamos subindo seu anexo para o servidor, isso pode levar alguns segundos...');
          let response = yield this.ajax.request('s3/signedUploadUrl', {
            data: data
          });
          yield file.uploadBinary(response.url, {
            method: 'PUT'
          });
          response.pdeEvidences.description = this.description;
          response.pdeEvidences.team = pde.get('team.id');
          response.pdeEvidences.pde = pde.get('id');
          this.store.pushPayload({
            pdeEvidences: response.pdeEvidences
          });
          yield this.store.peekRecord('pde-evidence', response.pdeEvidences.id).save();
        } else {
          let evidence = this.pdeEvidence;
          evidence.set('description', this.description);
          evidence.set('pde', pde);
          evidence.set('pdeAction', this.pdeAction);
          evidence.set('team', pde.get('team'));
          yield evidence.save();
        }
        this.swal.success('Salvo com sucesso!');
        this.router.transitionTo('pde.show.show-action');
      } catch (e) {
        return this.swal.catch(e);
      }
    }).drop(),
    // Actions
    actions: {
      selectedImage(file) {
        this.files.clear();
        this.files.pushObject(file);
      }
    }
  });
});