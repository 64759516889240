define("avaliacao-talentrh/pods/feedback/new/route", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    model(params, transiction) {
      let {
        feedbackRequestId
      } = transiction.to.queryParams;
      if (feedbackRequestId) {
        return Ember.RSVP.hash({
          feedback: this.store.createRecord('feedback'),
          feedbackRequest: this.store.findRecord('feedback-request', feedbackRequestId)
        });
      }
      const {
        user: userId,
        insightLed: insightLedId
      } = params;
      if (userId) {
        return this.modelTask.perform(userId, insightLedId);
      }
      return Ember.RSVP.hash({
        feedback: this.store.createRecord('feedback')
      });
    },
    modelTask: (0, _emberConcurrency.task)(function* (userId, insightLedId) {
      try {
        const user = yield this.store.findRecord('user', userId);
        const feedback = yield this.store.createRecord('feedback', {
          user
        });
        let insightLed;
        if (insightLedId) {
          insightLed = yield this.store.findRecord('insight-led', insightLedId);
        }
        return Ember.RSVP.hash({
          feedback,
          insightLed
        });
      } catch (e) {
        Ember.debug(e);
      }
    }),
    actions: {
      afterSave() {
        this.send('reload');
      }
    }
  });
});