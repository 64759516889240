define("avaliacao-talentrh/pods/components/answer-evaluation/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // HTML Attributes
    classNames: ['answer-evaluation'],
    // Services
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    // Default values
    currentSection: 0,
    // Single line computed properties
    userId: Ember.computed.alias('evaluation.appraisedUser.id'),
    generalManager: Ember.computed.alias('userConfigs.accessProfile.generalManager'),
    notes: Ember.computed.alias('evaluation.appraisedUser.notes'),
    members: Ember.computed.alias('userConfigs.usersICanManage.users'),
    // Computed methods
    userIsMember: Ember.computed('evaluation.appraisedUser.id', 'members', 'userId', function () {
      if (!this.members) return false;
      let member = this.members.findIndex(u => Number(u) === Number(this.userId));
      return member >= 0;
    }),
    accessDeniedToAnswer: Ember.computed('answerFor', 'evaluation.appraisedUser.id', 'evaluation.appraiserUser.id', 'evaluation.periodExpired', 'evaluationTeam.appraiserUser.id', 'generalManager', 'userConfigs.sessionUser.id', function () {
      let periodExpired = Ember.get(this, 'evaluation.periodExpired');
      let sessionUserId = Ember.get(this, 'userConfigs.sessionUser.id');
      let appraisedUserId = Ember.get(this, 'evaluation.appraisedUser.id');
      let appraiserUserId = Ember.get(this, 'evaluation.appraiserUser.id');
      let appraiserTeamUserId = Ember.get(this, 'evaluationTeam.appraiserUser.id');
      if (sessionUserId !== appraisedUserId && sessionUserId !== appraiserUserId && sessionUserId !== appraiserTeamUserId && !this.answerFor && !this.generalManager) {
        return 'Seu usuário não tem as permissões necessárias para responder essa avaliação.';
      } else if (periodExpired === 1) {
        return 'O prazo para responder essa avaliação expirou, em caso de dúvidas entre em contato com o administrador do Gestão de Desempenho.';
      } else {
        return;
      }
    }),
    sectionList: Ember.computed('evaluationAnswer.length', 'currentSection', function () {
      return (this.evaluationAnswer || []).map((obj, index) => {
        return {
          id: index,
          description: obj.description
        };
      });
    }),
    evaluationAnswer: Ember.computed('answerFor', 'evaluation.appraisedUser.id', 'evaluation.appraiserUser.id', 'evaluation.{appraisedAnswer,appraisedConcluded,appraiserAnswer,appraiserConcluded,consensusAnswer,id}', 'evaluationTeam.appraiserAnswer', 'userConfigs.sessionUser.id', function () {
      let appraisedUserId = Ember.get(this, 'evaluation.appraisedUser.id');
      let appraiserUserId = Ember.get(this, 'evaluation.appraiserUser.id');
      let sessionUserId = Ember.get(this, 'userConfigs.sessionUser.id');
      const appraiserConcluded = Ember.get(this, 'evaluation.appraiserConcluded');
      const appraisedConcluded = Ember.get(this, 'evaluation.appraisedConcluded');
      const consensusAnswer = Ember.get(this, 'evaluation.consensusAnswer');
      const isTeam = this.evaluationTeam;
      const isAppraiser = appraiserUserId === sessionUserId || this.answerFor === 'appraiserUser';
      const isAppraised = appraisedUserId === sessionUserId || this.answerFor === 'appraisedUser';
      const isAppraiserAndAppraised = appraisedUserId === sessionUserId && appraiserUserId === sessionUserId || this.answerFor === 'appraiserUser';
      const isConsensus = appraiserUserId === sessionUserId && appraiserConcluded && appraisedConcluded && this.answerFor === 'consensus';
      if (isTeam) {
        return this.evaluationTeam.appraiserAnswer;
      }
      if (isAppraiserAndAppraised) {
        return this.evaluation.appraiserAnswer;
      }
      if (isAppraised) {
        return this.evaluation.appraisedAnswer;
      }
      if (isConsensus) {
        return consensusAnswer;
      }
      if (isAppraiser) {
        return this.evaluation.appraiserAnswer;
      }
      return null;
    }),
    sectionSelected: Ember.computed('evaluationAnswer.length', 'currentSection', function () {
      if (!this.evaluationAnswer) {
        return;
      }
      (0, _jquery.default)('html, body').animate({
        scrollTop: 0
      }, 'slow');
      return this.evaluationAnswer.objectAt(this.currentSection || 0);
    }),
    routeNotes: Ember.computed('answerFor', function () {
      return this.answerFor === 'consensus' ? 'evaluation.answer-consensus.notes' : 'evaluation.answer.notes';
    }),
    evaluationRole: Ember.computed('answerFor', 'evaluation.appraisedUser.id', 'evaluation.appraiserUser.id', 'evaluation.{appraisedConcluded,appraiserConcluded,id}', 'evaluationTeam', 'userConfigs.sessionUser.id', function () {
      let appraisedUserId = Ember.get(this, 'evaluation.appraisedUser.id');
      let appraiserUserId = Ember.get(this, 'evaluation.appraiserUser.id');
      let sessionUserId = Ember.get(this, 'userConfigs.sessionUser.id');
      const appraiserConcluded = Ember.get(this, 'evaluation.appraiserConcluded');
      const appraisedConcluded = Ember.get(this, 'evaluation.appraisedConcluded');
      if (this.evaluationTeam) {
        return 'Avaliado pela Equipe';
      } else if (this.answerFor === 'appraisedUser') {
        return 'RH - Inclusão de respostas pelo avaliado';
      } else if (this.answerFor === 'appraiserUser') {
        return 'RH - Inclusão de respostas pelo avaliador';
      } else if (appraisedUserId === sessionUserId) {
        return 'Autoavaliação';
      } else if (appraiserUserId === sessionUserId && appraiserConcluded && appraisedConcluded && this.answerFor === 'consensus') {
        return 'Avaliação de Consenso';
      } else if (appraiserUserId === sessionUserId) {
        return 'Meu Avaliado';
      } else {
        return;
      }
    }),
    // Actions
    actions: {
      sendToEvaluation() {
        this.swal.success('Avaliação salva com sucesso.');
        if (this.evaluationTeam) {
          this.router.transitionTo('evaluations.show-team', this.evaluationTeam.id);
        } else {
          if (!this.answerFor || this.answerFor === 'consensus') {
            this.router.transitionTo('evaluations.show', this.evaluation.id);
          } else {
            this.router.transitionTo('printed-evaluation.show', this.evaluation.id);
          }
        }
      },
      prevSection() {
        let prevSection = this.currentSection - 1;
        if (prevSection >= 0) {
          Ember.set(this, 'currentSection', prevSection);
        }
      },
      nextSection() {
        let nextSection = this.currentSection + 1;
        if (this.evaluationAnswer && nextSection < this.evaluationAnswer.length) {
          Ember.set(this, 'currentSection', nextSection);
        }
      }
    }
  });
});