define("avaliacao-talentrh/pods/home/info-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "KwGFrgoW",
    "block": "{\"symbols\":[\"@data\",\"@icon\",\"@title\"],\"statements\":[[8,\"ui-card/header\",[[24,0,\"py-3 d-flex border-primary text-right\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"d-flex ml-auto\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"pending\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"pr-3 mb-0 h5 text-center border-right\"],[14,\"title\",\"Pendente\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-clock text-warning mr-2\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"mb-0 text-warning\"],[12],[1,[32,1,[\"pending\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"pl-3 mb-0 h5 text-center\"],[14,\"title\",\"Concluído\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-check-circle text-success mr-2\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"mb-0 text-success\"],[12],[1,[32,1,[\"completed\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"ui-card/body\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"avatar-7 position-absolute t-4 border border-3 border-white bg-primary\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"position-absolute text-white font-weight-bold centralize h4\"],[12],[2,\"\\n      \"],[10,\"i\"],[15,0,[31,[\"fa \",[32,2]]]],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"h6\"],[14,0,\"mt-1 mb-0 pl-5 ml-3 text-truncate text-right\"],[12],[1,[32,3]],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"display-2\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,\"aria-hidden\",\"\"],[15,0,[31,[\"position-absolute centralize opacity-1 fa \",[32,2],\" text-primary\"]]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/home/info-card/template.hbs"
    }
  });
});