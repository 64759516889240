define("avaliacao-talentrh/pods/home/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    userConfigs: Ember.inject.service(),
    showActionPanel: Ember.computed('userConfigs.usersICanManage', function () {
      let usersICanManage = this.userConfigs.usersICanManage;
      if (!usersICanManage) {
        return;
      }
      return usersICanManage.users.length || usersICanManage.canManageAll;
    })
  });
});