define("avaliacao-talentrh/pods/components/socket-handler/component", ["exports", "avaliacao-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    APP: {
      version
    }
  } = _environment.default;
  var _default = _exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    notificationService: Ember.inject.service('notification'),
    init() {
      this._super(...arguments);
      if (this.get('session.isAuthenticated')) {
        Ember.debug('Ember autenticado, deve autenticar o socket');
        this.authenticateSocket();
        this.initSocketListeners();
      }
    },
    authenticateSocket() {
      var token = this.get('session.session.content.authenticated.token');
      var headers = {
        authorization: 'Bearer ' + token
      };
      io.socket.request({
        method: 'post',
        url: '/socketlogin',
        data: {
          origin: 'avd-web',
          version: version.split('+')[0]
        },
        headers: headers
      }, function (response) {
        Ember.debug(`Socket login: ${response}`);
      });
    },
    initSocketListeners() {
      io.socket.on('needsAuthentication', data => {
        Ember.debug(`*** Pediu autenticação *** : ${data.body}`);
        this.authenticateSocket();
      });
      io.socket.on('notification', () => {
        this.notificationService.loadList();
      });
    }
  });
});