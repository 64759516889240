define("avaliacao-talentrh/pods/home/evaluation-pending/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Services
    userConfigs: Ember.inject.service(),
    // Alias
    allowConsensusInEmployee180Evaluation: Ember.computed.alias('userConfigs.companyConfiguration.allowConsensusInEmployee180Evaluation'),
    // Computed Properties
    evaluationType: Ember.computed('evaluation.{type,originType}', function () {
      let types = {
        1: 'Primeira Avaliação',
        2: 'Avaliação de Efetivação',
        3: 'Avaliação de Função',
        4: 'Avaliação de Skills'
      };
      if (this.evaluation.type === 4) {
        return `${types[this.evaluation.originType]} - Skills`;
      }
      return types[this.evaluation.type];
    }),
    appraiserStatus: Ember.computed('concludedStatus', 'evaluation.appraiserConcluded', 'pendingStatus', function () {
      if (this.evaluation.appraiserConcluded) {
        return this.concludedStatus;
      } else {
        return this.pendingStatus;
      }
    }),
    appraisedStatus: Ember.computed('concludedStatus', 'evaluation.{appraisedConcluded,typeEvaluation}', 'pendingStatus', function () {
      if (this.evaluation.typeEvaluation === 1) {
        return;
      } else if (this.evaluation.appraisedConcluded) {
        return this.concludedStatus;
      } else {
        return this.pendingStatus;
      }
    }),
    consensusStatus: Ember.computed('allowConsensusInEmployee180Evaluation', 'concludedStatus', 'evaluation.{consensusAnswer,consensusConcluded,typeEvaluation}', 'pendingStatus', function () {
      if (this.evaluation.typeEvaluation !== 2 || !this.evaluation.consensusAnswer) {
        return;
      } else if (this.evaluation.consensusConcluded) {
        return this.concludedStatus;
      } else {
        return this.pendingStatus;
      }
    }),
    teamStatus: Ember.computed('concludedStatus', 'evaluation.{evaluationTeam,teamConcluded}', 'pendingStatus', function () {
      if (!this.evaluation.evaluationTeam) {
        return;
      } else if (this.evaluation.teamConcluded) {
        return this.concludedStatus;
      } else {
        return this.pendingStatus;
      }
    }),
    routeToAnswer: Ember.computed('allowConsensusInEmployee180Evaluation', 'evaluation.{appraisedConcluded,appraiserConcluded,consensusConcluded,employeeEvaluation,typeEvaluation}', function () {
      if (this.evaluation.employeeEvaluation) {
        return 'evaluation.answer-team';
      } else if (this.evaluation.typeEvaluation === 2 && this.allowConsensusInEmployee180Evaluation && !this.evaluation.consensusConcluded && this.evaluation.appraisedConcluded && this.evaluation.appraiserConcluded) {
        return 'evaluation.answer-consensus';
      } else {
        return 'evaluation.answer';
      }
    }),
    // Life Cycle
    init() {
      this._super(...arguments);
      Ember.set(this, 'pendingStatus', {
        title: 'Pendente',
        icon: 'fa fa-clock text-warning'
      });
      Ember.set(this, 'concludedStatus', {
        title: 'Concluído',
        icon: 'fa fa-check-circle text-success'
      });
    }
  });
});