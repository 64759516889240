define("avaliacao-talentrh/pods/external-evaluation/show/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    swal: Ember.inject.service(),
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    externalEvaluation: Ember.computed.alias('model'),
    canManageEvaluation: Ember.computed('externalEvaluation.appraiserUser', 'session.user.id', function () {
      return this.externalEvaluation.appraiserUser.get('id') == this.session.user.id;
    }),
    delete: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar exclusão desta avaliação externa?');
        if (!value) {
          return;
        }
        yield this.externalEvaluation.destroyRecord();
        this.toast.success('Avaliação externa removida');
        this.transitionToRoute('external-evaluation.index');
        this.send('removeExternalEvaluationFromList', this.externalEvaluation);
      } catch (e) {
        this.swal.catch(e);
      }
    })
  });
});