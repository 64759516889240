define("avaliacao-talentrh/pods/new-evaluation/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    router: Ember.inject.service(),
    accessProfile: Ember.computed.alias('userConfigs.accessProfile'),
    leds: Ember.computed.alias('userConfigs.usersICanManage.users'),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
      const allowed = this.accessProfile.admin || this.leds.length || this.accessProfile.generalManager;
      if (!allowed) {
        return this.router.transitionTo('errors.forbidden');
      }
    },
    model() {
      return Ember.RSVP.hash({
        questionnaire: this.store.query('questionnaire', {
          sort: 'id'
        }),
        modelQuestionnaire: this.store.query('modelQuestionnaire', {
          sort: 'id ASC'
        })
      });
    }
  });
});