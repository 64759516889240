define("avaliacao-talentrh/pods/feedback/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "uAIUCaer",
    "block": "{\"symbols\":[],\"statements\":[[8,\"link-to\",[[24,0,\"list-group-item\"]],[[\"@route\",\"@model\",\"@tagName\"],[\"feedback.show\",[34,0,[\"id\"]],\"li\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"small\"],[14,0,\"float-right\"],[12],[1,[30,[36,1],[[35,0,[\"date\"]],\"DD/MM/YYYY\"],null]],[13],[2,\"\\n  \"],[10,\"h6\"],[14,0,\"text-truncate font-weight-bold\"],[12],[1,[35,0,[\"objective\"]]],[13],[2,\"\\n\\n\"],[6,[37,2],[[35,0,[\"draft\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"rounded small px-1 border float-right\"],[14,\"title\",\"Rascunhos não são exibidos aos colaboradores.\"],[12],[2,\"\\n      Rascunho\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-truncate\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"font-weight-bold\"],[12],[2,\"De:\"],[13],[2,\"\\n    \"],[1,[35,0,[\"leaderUser\",\"fullName\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text-truncate\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"font-weight-bold\"],[12],[2,\"Para:\"],[13],[2,\"\\n    \"],[1,[35,0,[\"user\",\"fullName\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"feedback\",\"moment-format\",\"if\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/feedback/item/template.hbs"
    }
  });
});