define("avaliacao-talentrh/models/feedback-historic", ["exports", "@ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    approvalStatus: (0, _attr.default)('string'),
    reason: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date'),
    user: (0, _relationships.belongsTo)('user'),
    approvalStatusBadge: Ember.computed('approvalStatus', function () {
      let type = this.approvalStatus;
      let badges = {
        'pending': {
          text: 'Pendente',
          color: 'warning'
        },
        'approved': {
          text: 'Aprovado',
          color: 'success'
        },
        'disapproved': {
          text: 'Reprovado',
          color: 'danger'
        }
      };
      return badges[type] || {};
    })
  });
});