define("avaliacao-talentrh/pods/pde/show/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    swal: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    toast: Ember.inject.service(),
    pde: Ember.computed.alias('model'),
    showAnnotations: Ember.computed('pde.owner.id', 'userConfigs.sessionUser.id', function () {
      return Ember.get(this, 'pde.owner.id') === Ember.get(this, 'userConfigs.sessionUser.id');
    }),
    toggleAction: (0, _emberConcurrency.task)(function* () {
      try {
        let status = this.pde.status === 0 ? 1 : 0;
        Ember.set(this, 'pde.status', status);
        yield this.pde.save();
        this.swal.success('Salvo com sucesso');
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    deletePde: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar exclusão deste PDE?');
        if (!value) {
          return;
        }
        yield this.pde.destroyRecord();
        this.toast.success('PDE removido');
        this.transitionToRoute('pde.index');
        this.send('removePdeFromList', this.pde);
      } catch (e) {
        this.swal.catch(e);
      }
    })
  });
  _exports.default = _default;
});