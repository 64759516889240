define("avaliacao-talentrh/services/report", ["exports", "nela-report/services/report"], function (_exports, _report) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _report.default;
    }
  });
});