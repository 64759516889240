define("avaliacao-talentrh/services/user-cookie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let UserCookieService = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _class = class UserCookieService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _defineProperty(this, "handled", false);
    }
    setInitialSessionCount() {
      this.session.set('data.logged', 1);
      this.session.set('data.sessionCount', 0);
    }
    invalidateAuthCookie() {
      this.session.invalidate();
    }
    getAuthTokenFromCookie() {
      const emberSimpleAuthRegex = /ember_simple_auth-session=([^;]+)/;
      const cookie = document.cookie;
      const matches = cookie.match(emberSimpleAuthRegex);
      if (!matches) {
        return null;
      }
      const decoded = decodeURIComponent(matches[1]);
      const parsed = JSON.parse(decoded);
      return parsed.authenticated.token;
    }
    async deleteUserDbToken() {
      try {
        await this.store.query('user-token', {
          user: this.session.user.id
        }).then(records => {
          records.forEach(record => {
            record.destroyRecord();
          });
        }).catch(e => {
          throw new Error(e);
        });
      } catch (e) {
        throw 'Error on delete user db token', e;
      } finally {
        this.session.set('data.logged', false);
        this.handled = true;
      }
    }
    async handleDeleteSession() {
      await this.deleteUserDbToken();
      await this.swal.fire({
        title: 'Sua sessão anterior foi encerrada!',
        text: 'Informe suas credenciais de acesso novamente.'
      }).then(() => {
        this.invalidateAuthCookie();
      });
    }
    async handleSessionCount() {
      const sessionCount = this.session.data.sessionCount;
      const logged = this.session.data.logged;
      const isReload = window.performance.navigation.type === 1;
      if (!sessionCount && !logged && !isReload) {
        return this.handleDeleteSession();
      }
      this.session.set('data.sessionCount', sessionCount + 1);
    }
    handleBeforeUnload() {
      const that = this;
      window.addEventListener('beforeunload', function () {
        const sessionCount = that.session.data.sessionCount;
        if (sessionCount > 0) {
          that.session.set('data.sessionCount', sessionCount - 1);
          that.session.set('data.logged', false);
        }
      });
    }
    async handle() {
      if (this.handled) {
        return;
      }
      this.handleBeforeUnload();
      const isLogged = this.session.data.sessionCount || this.session.data.logged;
      const isReload = window.performance.navigation.type === 1;
      if (!isLogged && !isReload) {
        return this.handleDeleteSession();
      }
      try {
        const token = this.getAuthTokenFromCookie();
        const userToken = await this.store.queryRecord('user-token', {
          user: this.session.user.id,
          token
        });
        if (!userToken) {
          return this.handleDeleteSession();
        }
        this.handled = true;
        return this.handleSessionCount();
      } catch (e) {
        console.error(e);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});