define("avaliacao-talentrh/pods/feedback-request/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    actions: {
      reload() {
        this.controller.set('filters', {});
      },
      error(error) {
        this.swal.catch(error);
        this.transitionTo('feedback-request');
      }
    }
  });
  _exports.default = _default;
});