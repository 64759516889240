define("avaliacao-talentrh/pods/evaluations/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    userConfigs: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    usersICanManage: Ember.computed.alias('userConfigs.usersICanManage'),
    accessProfile: Ember.computed.alias('userConfigs.accessProfile'),
    admin: Ember.computed.alias('userConfigs.accessProfile.admin'),
    generalManager: Ember.computed.alias('userConfigs.accessProfile.generalManager'),
    sessionUser: Ember.computed.alias('session.user.id'),
    setupController(controller, model) {
      this._super(controller, model);
      controller.set('model', model);
      return model.get('appraisedUser').then(appraisedUser => {
        if (appraisedUser.typeEvaluation) {
          controller.set('evaluationType', appraisedUser.typeEvaluation);
        }
        const companyConfig = this.userConfigs.companyConfiguration;
        const type = model.type;
        if (type === 1) {
          return controller.set('evaluationType', companyConfig.typeFirstEvaluation);
        }
        if (type === 2) {
          return controller.set('evaluationType', companyConfig.typeLastEvaluation);
        }
        if (type === 3) {
          return controller.set('evaluationType', companyConfig.typeFunctionEvaluation);
        }
      });
    },
    async afterModel(model) {
      const appraisedId = Number(model.appraisedUser.get('id'));
      const appraiserId = Number(model.appraiserUser.get('id'));
      const allowed = this.sessionUser === appraisedId || this.sessionUser === appraiserId || this.admin || this.generalManager || this.usersICanManage.users.includes(appraisedId);
      if (!allowed) {
        return this.router.transitionTo('errors.forbidden');
      }
    }
  });
});