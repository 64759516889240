define("avaliacao-talentrh/pods/pde/item-pde-action/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML Attributes
    classNames: ['list-group-item']
  });
  _exports.default = _default;
});