define("avaliacao-talentrh/pods/pdi/show/percentage-action/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model(params, transition) {
      let {
        pdi_id
      } = transition.to.parent.params;
      let pdiActionId = transition.to.queryParams ? transition.to.queryParams.pdiActionCreatedId : null;
      let actionDelete = transition.to.queryParams.pdiActionDelete;
      if (actionDelete) {
        return Ember.RSVP.hash({
          pdi: this.modelFor('pdi.show')
        });
      }
      if (!pdiActionId) {
        this.transitionTo('pdi.show', pdi_id);
      }
      return Ember.RSVP.hash({
        pdi: this.modelFor('pdi.show'),
        pdiActionId
      });
    },
    deactivate() {
      this.controller.get('model.pdi').rollbackAttributes();
      const pdiActions = this.controller.get('model.pdi.pdiActions');
      Ember.RSVP.map(pdiActions.toArray(), pdiAction => {
        return pdiAction.rollbackAttributes();
      });
    },
    actions: {
      onCloseModal() {
        let parentId = this.controller.get('model.pdi.id');
        this.transitionTo('pdi.show', parentId);
      }
    }
  });
  _exports.default = _default;
});