define("avaliacao-talentrh/pods/manage-goals/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    userConfigs: Ember.inject.service(),
    router: Ember.inject.service(),
    beforeModel() {
      let userConfigs = this.userConfigs;
      if (!userConfigs) {
        return;
      }
      let {
        accessProfile,
        sessionUser,
        companyConfiguration
      } = userConfigs;
      if (!accessProfile || !sessionUser || !companyConfiguration) {
        return;
      }
      const manageGoals = companyConfiguration.hasAVDModule && (accessProfile.allowManageGoals || accessProfile.admin || sessionUser.userAppraisers.length);
      if (!manageGoals) {
        this.router.transitionTo('errors.forbidden');
      }
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.set('openModalFilter', true);
    }
  });
  _exports.default = _default;
});