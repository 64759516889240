define("avaliacao-talentrh/pods/login/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    userConfigs: Ember.inject.service(),
    // Tasks
    authenticate: (0, _emberConcurrency.task)(function* () {
      try {
        console.log('aqui authenticate');
        yield this.userConfigs.loadUserConfigs.perform();
      } catch (e) {
        console.log(e);
        this.set('error', e.text || e);
      }
    })
  });
  _exports.default = _default;
});