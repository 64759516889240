define("avaliacao-talentrh/pods/reports/report-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "sl0S1L3N",
    "block": "{\"symbols\":[\"@title\"],\"statements\":[[8,\"ui-card/header\",[[24,0,\"pt-3 pb-5 border-primary text-right\"]],[[],[]],null],[2,\"\\n\\n\"],[8,\"ui-card/body\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"avatar-7 position-absolute t-4 mt-1 border border-3 border-white bg-primary\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"position-absolute text-white font-weight-bold centralize h4\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-file-chart-line\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"h6\"],[14,0,\"mt-1 mb-0 pl-5 ml-3 text-truncate text-right\"],[12],[2,\"\\n    \"],[1,[32,1]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"display-2\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,\"aria-hidden\",\"\"],[14,0,\"position-absolute centralize opacity-1 fa fa-file-chart-line text-primary\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/reports/report-card/template.hbs"
    }
  });
});