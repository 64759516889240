define("avaliacao-talentrh/pods/feedback-request/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model() {
      return this.store.createRecord('feedback-request');
    },
    actions: {
      afterSave() {
        this.send('reload');
      }
    }
  });
  _exports.default = _default;
});