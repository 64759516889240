define("avaliacao-talentrh/components/ui-button", ["exports", "ui-nela/components/ui-button"], function (_exports, _uiButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _uiButton.default;
    }
  });
});