define("avaliacao-talentrh/pods/analytics/nine-box/filter-modal/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AnalyticsNineBoxFilterModalComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.alias('userConfigs.companyConfiguration.showJobFunction'), _dec4 = Ember.computed.alias('userConfigs.accessProfile'), _dec5 = Ember.computed.or('accessProfile.admin', 'accessProfile.generalManager'), _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _class = class AnalyticsNineBoxFilterModalComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "userConfigs", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      // Single line computed
      _initializerDefineProperty(this, "showJobFunction", _descriptor3, this);
      _initializerDefineProperty(this, "accessProfile", _descriptor4, this);
      _initializerDefineProperty(this, "notShowLedFilter", _descriptor5, this);
      // Default values
      _initializerDefineProperty(this, "filters", _descriptor6, this);
    }
    usersSelected(users, select) {
      select.actions.search('');
      this.filters = {
        ...this.filters,
        users,
        branch: null,
        department: null,
        jobFunction: null,
        jobRole: null
      };
    }
    onClose() {
      this.filters = {
        ledType: 'a'
      };
      if (this.args.onClose) {
        this.args.onClose();
      }
    }
    updateLedTypeFilter() {
      this.filters = {
        ...this.filters,
        users: []
      };
    }
    clearFilter() {
      this.filters = {
        ledType: 'a'
      };
    }
    onFilter() {
      if (!this.filters.cycle) {
        return this.swal.warning('Ciclo é um campo obrigatório.');
      }
      const filters = {};
      if (this.filters.users && this.filters.users.length) {
        filters.usersIds = this.filters.users.map(user => user.id);
      }
      if (this.filters.cycle) {
        filters.id = this.filters.cycle.id;
      }
      if (this.filters.branch) {
        filters.branch = this.filters.branch.id;
      }
      if (this.filters.department) {
        filters.department = this.filters.department.id;
      }
      if (this.filters.jobFunction) {
        filters.jobFunction = this.filters.jobFunction.id;
      }
      if (this.filters.jobRole) {
        filters.jobRole = this.filters.jobRole.id;
      }
      if (this.filters.ledType) {
        filters.ledType = this.filters.ledType;
      }
      if (this.args.onFilter) {
        this.args.onFilter(filters);
        this.filters = {
          ledType: 'a'
        };
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "userConfigs", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showJobFunction", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "accessProfile", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "notShowLedFilter", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        ledType: 'a'
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "usersSelected", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "usersSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateLedTypeFilter", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "updateLedTypeFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilter", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFilter", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onFilter"), _class.prototype), _class);
});