define("avaliacao-talentrh/models/company-configuration", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    periodicityAvd: _emberData.default.attr('number'),
    daysFirstEvaluation: _emberData.default.attr('number'),
    daysLastEvaluation: _emberData.default.attr('number'),
    showNoteEmployee: _emberData.default.attr('boolean'),
    questionary45DaysFunction: _emberData.default.attr('boolean'),
    questionary90DaysFunction: _emberData.default.attr('boolean'),
    singleExperienceEvaluation: _emberData.default.attr('boolean'),
    useNineBox: _emberData.default.attr('boolean'),
    subscription: _emberData.default.attr('raw'),
    daysToGenerateExperienceEvaluation: _emberData.default.attr('number'),
    daysToExpirationExperienceEvaluation: _emberData.default.attr('number'),
    daysToGeneratePerformanceEvaluation: _emberData.default.attr('number'),
    company: _emberData.default.belongsTo('company'),
    // useFeed45Days: DS.attr('boolean'),
    // useFeed90Days: DS.attr('boolean'),
    questionary45Days: _emberData.default.belongsTo('questionnaire'),
    questionary90Days: _emberData.default.belongsTo('questionnaire'),
    questionarySingleEvaluation: _emberData.default.belongsTo('questionnaire'),
    daysToExpirationPerformanceEvaluation: _emberData.default.attr('number'),
    createEvaluation: _emberData.default.attr('boolean'),
    showEvaluationComparison: _emberData.default.attr('boolean'),
    customDateFunctionEvaluation: _emberData.default.attr('string'),
    enableCustomDateFunctionEvaluation: _emberData.default.attr('boolean'),
    typeFirstEvaluation: _emberData.default.attr('number'),
    thumbLogo: _emberData.default.attr('string'),
    typeLastEvaluation: _emberData.default.attr('number'),
    typeFunctionEvaluation: _emberData.default.attr('number'),
    featureFlags: _emberData.default.attr('raw'),
    allowConsensusInEmployee180Evaluation: _emberData.default.attr('boolean'),
    showJobFunction: _emberData.default.attr('boolean'),
    onlyAppraiserAnswers: _emberData.default.attr('boolean'),
    showPortalColab: Ember.computed('hasPortalColabModule', 'jobContract', 'permitMessageRh', 'permitReceipt', function () {
      return this.permitReceipt | this.permitMessageRh && this.hasPortalColabModule;
    }),
    hasProjectManagerModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('PROJECT-MANAGER');
    }),
    hasTrainingModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('TRAINING');
    }),
    hasTEDModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('TED');
    }),
    hasPortalColabModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('PORTAL_COLAB');
    }),
    hasPortalGestorModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('PORTAL_GESTOR');
    }),
    hasAVDModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('AVD');
    }),
    hasPesquisaModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('PESQUISA');
    }),
    hasAgendaModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('AGENDA');
    }),
    accountIsActive: Ember.computed('subscription.active', function () {
      return this.get('subscription.active') || false;
    })
  });
});