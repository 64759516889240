define("avaliacao-talentrh/pods/pdi/new/notes/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PdiNewNotesRoute extends Ember.Route {
    model() {
      return this.modelFor('pdi.new');
    }
  }
  _exports.default = PdiNewNotesRoute;
});