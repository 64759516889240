define("avaliacao-talentrh/pods/application/notification/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aj1gWPBN",
    "block": "{\"symbols\":[\"@notification\"],\"statements\":[[6,[37,1],[[35,0,[\"models\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"link-to\",[],[[\"@route\",\"@models\"],[[34,0,[\"route\"]],[34,0,[\"models\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"application/notification/simple-notification\",[],[[\"@notification\"],[[32,1]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[[34,0,[\"route\"]],[34,0,[\"model\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"application/notification/simple-notification\",[],[[\"@notification\"],[[32,1]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"application/notification/simple-notification\",[],[[\"@notification\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"linkParams\",\"if\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/application/notification/item/template.hbs"
    }
  });
  _exports.default = _default;
});