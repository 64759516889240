define("avaliacao-talentrh/models/notification", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attrs
    type: (0, _attr.default)('string'),
    modelId: (0, _attr.default)('string'),
    read: (0, _attr.default)('boolean'),
    message: (0, _attr.default)('string'),
    linkParams: (0, _attr.default)('raw'),
    createdAt: (0, _attr.default)('date'),
    // BelongsTo
    user: (0, _relationships.belongsTo)('user'),
    relatedUser: (0, _relationships.belongsTo)('user'),
    employeeEvaluation: (0, _attr.default)('string'),
    employeeEvaluationTeam: (0, _attr.default)('string')
  });
  _exports.default = _default;
});