define("avaliacao-talentrh/models/city", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    state: _emberData.default.belongsTo('state'),
    ddd: _emberData.default.attr('string'),
    official: _emberData.default.attr('string'),
    zipcode: _emberData.default.attr('string'),
    ibge: _emberData.default.attr('string'),
    // users: DS.hasMany('user'),
    companies: _emberData.default.hasMany('company')
  });
  _exports.default = _default;
});