define("avaliacao-talentrh/pods/printed-evaluation/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Single line computed properties
    evaluation: Ember.computed.alias('model'),
    // Multi line computed properties
    canAnswerAppraiser: Ember.computed('evaluation.{id,appraiserConcluded}', 'session.user', function () {
      let appraiserConcluded = Ember.get(this, 'evaluation.appraiserConcluded');
      return this.evaluation.isAvailable && !appraiserConcluded;
    }),
    canAnswerAppraised: Ember.computed('evaluation.{id,appraisedConcluded}', 'session.user', function () {
      let appraisedConcluded = Ember.get(this, 'evaluation.appraisedConcluded');
      return this.evaluation.isAvailable && !appraisedConcluded;
    }),
    management: Ember.computed('canAnswerAppraiser', 'canAnswerAppraised', function () {
      return !this.canAnswerAppraiser && !this.canAnswerAppraised;
    })
  });
  _exports.default = _default;
});