define("avaliacao-talentrh/pods/pdi/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    // Life Cycle
    init() {
      this._super(...arguments);
      Ember.set(this, 'statusToSelect', [{
        title: 'Aberto',
        value: 0
      }, {
        title: 'Finalizado',
        value: 1
      }]);
    },
    // Services
    userConfigs: Ember.inject.service(),
    // Methods
    getFilters() {
      let userFilter = this.userFilter;
      let statusFilter = this.statusFilter;
      let startDateFilter = this.startDateFilter;
      let endDateFilter = this.endDateFilter;
      let query = {};
      if (this.tabFilter === 'me') {
        query.user = this.userConfigs.sessionUser.id;
      } else if (this.tabFilter === 'ledByMe') {
        query.user = {
          'not': this.userConfigs.sessionUser.id
        };
      }
      if (userFilter && userFilter.get('id')) {
        query.user = userFilter.get('id');
      }
      if (startDateFilter && endDateFilter) {
        query.createdAt = {
          ">=": moment(startDateFilter).format('YYYY/MM/DD 00:00'),
          "<=": moment(endDateFilter).format('YYYY/MM/DD 23:59')
        };
      }
      if (statusFilter) {
        query.status = statusFilter.value;
      }
      return query;
    },
    // Actions
    actions: {
      filter() {
        Ember.set(this, 'filters', this.getFilters());
      },
      clearFilters() {
        this.setProperties({
          'userFilter': null,
          'statusFilter': null,
          'startDateFilter': null,
          'endDateFilter': null
        });
        this.send('filter');
      },
      selectTabFilter(tabFilter) {
        Ember.set(this, 'tabFilter', tabFilter);
        Ember.set(this, 'filters', this.getFilters());
      }
    }
  });
});