define("avaliacao-talentrh/models/user", ["exports", "ember-data", "avaliacao-talentrh/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    username: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    avatarUrl: _emberData.default.attr('string'),
    cpf: _emberData.default.attr('string'),
    admin: _emberData.default.attr('boolean'),
    superUser: _emberData.default.attr('boolean'),
    isLeader: _emberData.default.attr('boolean'),
    loginSuperUser: _emberData.default.attr('boolean'),
    company: _emberData.default.belongsTo('company'),
    establishment: _emberData.default.attr('string'),
    employeeId: _emberData.default.attr('string'),
    newPassword: _emberData.default.attr('string'),
    confirmPassword: _emberData.default.attr('string'),
    birthCity: _emberData.default.belongsTo('city'),
    birthDate: _emberData.default.attr('date'),
    address: _emberData.default.attr('string'),
    complement: _emberData.default.attr('string'),
    zipcode: _emberData.default.attr('string'),
    neighborhood: _emberData.default.attr('string'),
    number: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    city: _emberData.default.belongsTo('city'),
    cellphone: _emberData.default.attr('string'),
    jobFunction: _emberData.default.belongsTo('jobFunction'),
    jobRole: _emberData.default.belongsTo('jobRole'),
    professionalEmail: _emberData.default.attr('string'),
    admissionDate: _emberData.default.attr('date'),
    appraisedBy: _emberData.default.belongsTo('user', {
      inverse: null
    }),
    userAppraisers: _emberData.default.hasMany('user', {
      inverse: null
    }),
    appointments: _emberData.default.hasMany('appointment'),
    notes: _emberData.default.hasMany('note'),
    typeEvaluation: _emberData.default.attr('number'),
    team: _emberData.default.belongsTo('team'),
    accessProfile: _emberData.default.belongsTo('access-profile'),
    department: _emberData.default.belongsTo('department'),
    fullName: Ember.computed('firstName', 'lastName', function () {
      return `${this.firstName} ${this.lastName}`;
    }),
    avatar: Ember.computed('avatarUrl', function () {
      if (this.avatarUrl) {
        return this.avatarUrl;
      } else {
        return '/images/user-sem-imagem.png';
      }
    })
  });
});