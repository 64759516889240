define("avaliacao-talentrh/pods/pdi/new/notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "q8D+Z1Ji",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-notes\",[],[[\"@user\",\"@route\"],[[32,0,[\"modelUser\"]],\"pdi.new\"]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/pdi/new/notes/template.hbs"
    }
  });
});