define("avaliacao-talentrh/pods/manage-goals/update-achieve/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "aayLZZn+",
    "block": "{\"symbols\":[\"Modal\",\"Form\",\"Footer\"],\"statements\":[[8,\"ui-modal\",[[24,\"data-backdrop\",\"static\"]],[[\"@open\",\"@modalSize\",\"@onCloseModal\"],[true,\"modal-lg\",[30,[36,0],[[32,0],\"onCloseModal\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Atualizar Meta\"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"ui-form-renderer\",[],[[\"@formName\"],[\"manage-goals/update-achieve/form\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"content\"]],[],[[\"@goalAchieved\",\"@userGoal\"],[[34,1,[\"goalAchieved\"]],[34,1,[\"userGoal\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,3,[\"cancel\"]],[],[[],[]],null],[2,\"\\n      \"],[8,[32,2,[\"submit\"]],[],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"model\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/manage-goals/update-achieve/template.hbs"
    }
  });
});