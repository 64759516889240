define("avaliacao-talentrh/pods/external-evaluation/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    userConfigs: Ember.inject.service(),
    router: Ember.inject.service(),
    model() {
      const allowed = this.userConfigs.accessProfile.admin || this.userConfigs.accessProfile.generalManager || this.userConfigs.sessionUser.isLeader;
      if (!allowed) {
        return this.router.transitionTo('errors.forbidden');
      }
      return this.store.createRecord('employee-external-evaluation');
    },
    deactivate() {
      this.controller.model.rollbackAttributes();
      Ember.set(this.controllerFor('external-evaluation'), 'filters', {});
    }
  });
  _exports.default = _default;
});