define("avaliacao-talentrh/pods/pdi/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    userConfigs: Ember.inject.service(),
    router: Ember.inject.service(),
    accessProfile: Ember.computed.alias('userConfigs.accessProfile'),
    sessionUser: Ember.computed.alias('userConfigs.sessionUser.id'),
    usersICanManage: Ember.computed.alias('userConfigs.usersICanManage.users'),
    afterModel(model) {
      const appraised = +model.user.get('id');
      const appraiser = +model.createdBy.get('id');
      const sessionUser = +this.sessionUser;
      const allowed = this.accessProfile.admin || this.usersICanManage.includes(appraised) || sessionUser === appraised || sessionUser === appraiser;
      if (!allowed) {
        return this.router.transitionTo('errors.forbidden');
      }
    },
    actions: {
      willTransition() {
        this.controller.set('reloadProgressBar', new Date());
      }
    }
  });
  _exports.default = _default;
});