define("avaliacao-talentrh/services/store", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global jQuery */
  var _default = _emberData.default.Store.extend({
    ajax: Ember.inject.service(),
    findEndpoint(modelName, endPoint, params) {
      return this.ajax.request(endPoint + '/?' + jQuery.param(params)).then(response => {
        let normalizedResponse = Ember.A(),
          camelizeKey = Ember.String.camelize((0, _emberInflector.pluralize)(modelName)),
          dasherizeKey = Ember.String.dasherize((0, _emberInflector.pluralize)(modelName));
        this.pushPayload(response);
        let responseArray = response[camelizeKey] || response[dasherizeKey];
        if (!responseArray) {
          throw 'Fomato dos dados de retorno é inválido, root do JSON não foi encontrado.';
        }
        responseArray.forEach(obj => {
          let normalized = this.peekRecord(modelName, obj.id);
          normalizedResponse.pushObject(normalized);
        });
        return {
          content: normalizedResponse
        };
      });
    }
  });
  _exports.default = _default;
});