define("avaliacao-talentrh/pods/pde/form-pde-evidence/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "NN48Tjab",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"Descrição*\"],[13],[2,\"\\n\\n      \"],[8,\"textarea\",[[24,0,\"form-control\"],[24,\"placeholder\",\"Informe uma descrição...\"],[24,\"maxlength\",\"65500\"]],[[\"@value\"],[[34,0]]],null],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"files\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"col-12 text-center mt-1\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-file-archive fa-3x\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n      \"],[10,\"h6\"],[14,0,\"mt-3 mb-0\"],[12],[1,[32,0,[\"fileSelected\",\"name\"]]],[13],[2,\"\\n      \"],[10,\"small\"],[12],[1,[32,0,[\"fileSelected\",\"type\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-12 text-center mt-3\"],[12],[2,\"\\n    \"],[8,\"file-upload\",[[24,0,\"btn btn-outline-primary\"]],[[\"@name\",\"@accept\",\"@onfileadd\"],[\"photos\",\"application/*,image/*,text/*,.doc,.docx\",[30,[36,2],[[32,0],\"selectedImage\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-cloud-upload text-primary\"],[12],[13],[2,\"\\n      \"],[1,[30,[36,1],[[32,0,[\"files\"]],\"Alterar Anexo\",\"Adicionar Anexo (opcional)\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"description\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/pde/form-pde-evidence/template.hbs"
    }
  });
});