define("avaliacao-talentrh/pods/pdi/form-percentage-action/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    sumPercentages: Ember.computed('pdiActions.@each.percentage', function () {
      let percentages = this.pdiActions.mapBy('percentage');
      return percentages.reduce((sum, current) => sum + current);
    }),
    didInsertElement() {
      this._super(...arguments);
      this.percentageDefault();
    },
    didUpdateAttrs() {
      this._super(...arguments);
      this.percentageDefault();
    },
    percentageDefault() {
      const pdiAction = this.pdiAction;
      if (this.defaultPercentage) {
        let percentage = Math.round(100 / this.countActions);
        Ember.set(pdiAction, 'percentage', percentage);
        if (this.sumPercentages !== 100) {
          const lastObject = this.pdiActions.lastObject;
          Ember.set(lastObject, 'percentage', percentage + 100 - this.sumPercentages);
        }
      } else {
        if (!pdiAction.percentage) {
          Ember.set(pdiAction, 'percentage', Math.round(100 - this.sumPercentages));
        }
      }
    },
    actions: {
      maxLengthCheck(el) {
        if (el.value.length > el.maxLength) {
          el.value = el.value.slice(0, el.maxLength);
        }
      }
    }
  });
  _exports.default = _default;
});