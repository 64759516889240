define("avaliacao-talentrh/pods/pdi/show/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    swal: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    ajax: Ember.inject.service(),
    reloadProgressBar: null,
    pdi: Ember.computed.alias('model'),
    // Computed Properties
    isConcluded: Ember.computed.equal('pdi.status', 1),
    // Computed Properties
    showAnnotations: Ember.computed('pdi.createdBy.id', 'userConfigs.sessionUser.id', function () {
      return Ember.get(this, 'pdi.createdBy.id') === Ember.get(this, 'userConfigs.sessionUser.id');
    }),
    resultPdi: Ember.computed('pdi', 'pdi.pdiActions.isFulfilled', 'reloadProgressBar', function () {
      let result = 0;
      let pdiActions = this.pdi.pdiActions;
      const progressBar = document.getElementById('progress-bar-rating');
      const partialScores = pdiActions.mapBy('partialScore').filter(p => p);
      if (partialScores.length) {
        result = partialScores.reduce((sum, current) => {
          return sum + current;
        });
      }
      if (progressBar) {
        progressBar.setAttribute('style', `width: ${result}%`);
      }
      return String(result);
    }),
    canManagePdi: Ember.computed('pdi.user.id', function () {
      let pdi = this.pdi;
      let userConfigs = this.userConfigs;
      if (!userConfigs || !pdi) {
        return;
      }
      return userConfigs.iCanManageUser(pdi.get('user.id'));
    }),
    canCreatePdiAction: Ember.computed('pdi.user.id', function () {
      let pdi = this.pdi;
      let userConfigs = this.userConfigs;
      if (!userConfigs || !pdi) {
        return;
      }
      return userConfigs.iCanManageUser(pdi.get('user.id')) || pdi.get('createdBy.id') === userConfigs.sessionUser.id && pdi.get('user.id') === userConfigs.sessionUser.id;
    }),
    leaderCanView: Ember.computed('pdi.user.id', 'pdi.read', function () {
      let pdi = this.pdi;
      let {
        sessionUser
      } = this.userConfigs;
      if (!sessionUser) {
        return;
      }
      return pdi.get('user.id') !== sessionUser.id && pdi.get('createdBy.id') !== sessionUser.id && !pdi.read;
    }),
    employeeCanView: Ember.computed('pdi.user.id', 'pdi.read', function () {
      let pdi = this.pdi;
      let {
        sessionUser
      } = this.userConfigs;
      if (!sessionUser || !pdi) {
        return;
      }
      return pdi.get('user.id') === sessionUser.id && pdi.get('createdBy.id') !== sessionUser.id && !pdi.read;
    }),
    // Concurrecny Taks
    toggleStatus: (0, _emberConcurrency.task)(function* (record) {
      let status = record.status || 0;
      try {
        Ember.set(record, 'status', Number(status === 0));
        yield record.save();
        this.swal.success('Salvo com sucesso');
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    toggleRead: (0, _emberConcurrency.task)(function* (record) {
      let id = record.id;
      try {
        const pdi = yield this.ajax.post(`pdi/${id}/read`);
        this.store.pushPayload(pdi);
        this.swal.success('Salvo com sucesso');
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    deletePdi: (0, _emberConcurrency.task)(function* (record) {
      try {
        let result = yield this.swal.confirm('Tem certeza que deseja remover este PDI?');
        if (!result.value) {
          return;
        }
        yield record.destroyRecord();
        this.swal.success('Removido com sucesso.');
        this.transitionToRoute('pdi');
        this.send('reload');
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    actions: {
      updateProgressBar(element) {
        if (element) {
          element.setAttribute('style', `width: ${this.resultPdi}%`);
        }
      }
    }
  });
});