define("avaliacao-talentrh/pods/feedback/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    userConfigs: Ember.inject.service(),
    router: Ember.inject.service(),
    accessProfile: Ember.computed.alias('userConfigs.accessProfile'),
    sessionUserId: Ember.computed.alias('userConfigs.sessionUser.id'),
    usersICanManage: Ember.computed.alias('userConfigs.usersICanManage.users'),
    afterModel(model) {
      const user = model.user.get('id');
      const leaderUser = model.leaderUser.get('id');
      const allowed = this.accessProfile.generalManager || this.accessProfile.admin || user === this.sessionUserId || leaderUser === this.sessionUserId || this.usersICanManage.includes(user);
      if (!allowed) {
        return this.router.transitionTo('errors.forbidden');
      }
    },
    actions: {
      refreshRoute() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});