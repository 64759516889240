define("avaliacao-talentrh/helpers/coalesce", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.coalesce = coalesce;
  _exports.default = void 0;
  function coalesce(params) {
    return params[0] || Ember.String.htmlSafe(`<i>${params[1]}</i>`);
  }
  var _default = Ember.Helper.helper(coalesce);
  _exports.default = _default;
});