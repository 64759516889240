define("avaliacao-talentrh/pods/components/answer-evaluation/question/info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hZpbmLzJ",
    "block": "{\"symbols\":[\"@appraisedText\",\"@appraised\",\"@icon\",\"@appraiserText\"],\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"answer-evaluation/question/info/item\",[],[[\"@appraised\",\"@text\"],[true,[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"i\"],[15,0,[31,[\"fa \",[32,3],\" text-primary mt-1 \",[30,[36,0],[[35,1],\"px-3\",\"pr-3\"],null]]]],[12],[13],[2,\"\\n\\n\"],[8,\"answer-evaluation/question/info/item\",[],[[\"@appraised\",\"@text\"],[false,[32,4]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"appraised\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/components/answer-evaluation/question/info/template.hbs"
    }
  });
  _exports.default = _default;
});