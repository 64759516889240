define("avaliacao-talentrh/pods/feedback/form-followup/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Services
    swal: Ember.inject.service(),
    router: Ember.inject.service(),
    // Life Cycle
    didInsertElement() {
      this._super(...arguments);
      this.onLoadForm(this.save);
    },
    // Concurrency Tasks
    save: (0, _emberConcurrency.task)(function* () {
      let model = this.model;
      if (!model.validations.isValid) {
        return this.swal.validation(model.validations);
      }
      try {
        model.set('user', this.feedback.user);
        model.set('feedback', this.feedback);
        let created = yield model.save();
        this.swal.success('O Acompanhamento foi salvo com sucesso.');
        if (this.afterSave) {
          this.afterSave(created);
        }
        this.router.transitionTo('feedback.show', this.feedback.id);
      } catch (e) {
        return this.swal.catch(e);
      }
    })
  });
});