define("avaliacao-talentrh/pods/components/page-access/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "uIsjQdhG",
    "block": "{\"symbols\":[\"@allowed\"],\"statements\":[[6,[37,2],[[32,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"mt-5\"],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"text-center\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-lock display-3\"],[12],[13],[2,\"\\n      \"],[10,\"h3\"],[14,0,\"mt-3\"],[12],[2,\"Ops!\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"h5\"],[14,0,\"text-center mt-3\"],[12],[2,\"Talvez tenha ocorrido algum engano, sua conta não\"],[13],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"text-center\"],[12],[2,\"tem as permissões necessárias para acessar essa página.\"],[13],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"text-center mt-3\"],[12],[2,\"Para receber as permissões, entre em contato\"],[13],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"text-center\"],[12],[2,\"com o administrador da rede ou com seu supervisor.\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"if\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/components/page-access/template.hbs"
    }
  });
});