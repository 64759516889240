define("avaliacao-talentrh/pods/external-evaluation/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    actions: {
      removeExternalEvaluationFromList() {
        let filters = this.controller.getFilters();
        Ember.set(this.controller, 'filters', filters);
      }
    }
  });
});