define("avaliacao-talentrh/pods/components/input-select-each/component", ["exports", "avaliacao-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    urlConta: _environment.default.appUrl.conta,
    data: Ember.A(),
    didInsertElement() {
      this.store.findAll(this.endpoint).then(itens => {
        this.set('data', itens);
      });
    },
    actions: {
      change() {
        if (this.endpoint === 'skill') {
          const value = this.$('select').val();
          this.store.findRecord(this.endpoint, value).then(data => {
            const skill = {
              id: data.get('id'),
              name: data.get('name')
            };
            this.set('model.skill', skill);
            this.set('model.title', skill.name);
          });
          this.set('model.question', null);
          this.set('model.questionSkill', null);
        } else if (this.endpoint === 'question') {
          const value = this.$('select').val();
          this.store.findRecord(this.endpoint, value).then(data => {
            const question = {
              id: data.get('id'),
              description: data.get('description')
            };
            this.set('model.question', question);
            this.set('model.title', question.description);
            if (data.get('skill.id')) {
              this.set('model.questionSkill', {
                id: data.get('skill.id'),
                name: data.get('skill.name')
              });
            } else {
              this.set('model.questionSkill', null);
            }
            this.set('model.skill', null);
          });
        } else {
          const value = this.$('select').val();
          this.store.findRecord(this.endpoint, value).then(data => {
            this.set('model.alternatives', []);
            const answersPercent = data.get('answers').filter(answer => answer.get('percentage'));
            const answersNoPercent = data.get('answers').filter(answer => !answer.get('percentage'));
            let answerPercent = 0;
            answersPercent.forEach(answer => {
              answerPercent += answer.get('percentage');
            });
            let percentage = (100 - answerPercent) / answersNoPercent.length;
            data.get('answers').forEach(answer => {
              this.get('model.alternatives').pushObject({
                id: answer.get('id'),
                description: answer.get('description'),
                definition: answer.get('definition'),
                percentage: answer.get('percentage') == null ? percentage : answer.get('percentage'),
                needJustification: answer.get('needJustification'),
                ignoreToCalculate: answer.get('ignoreToCalculate')
              });
            });
          });
        }
      },
      update() {
        this.store.findAll(this.endpoint).then(itens => {
          this.set('data', itens);
          this.$('select').val(this.selected != null ? this.selected : '');
          this.set('selected', this.selected != null ? this.selected : '');
          this.$('select').on('select', evt => {
            const value = evt.target.value;
            this.set('selected', value);
          });
        });
      }
    }
  });
  _exports.default = _default;
});