define("avaliacao-talentrh/pods/feedback-request/form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Services
    swal: Ember.inject.service(),
    router: Ember.inject.service(),
    // Life Cycle
    didInsertElement() {
      this._super(...arguments);
      this.onLoadForm(this.save);
    },
    // Concurrency Tasks
    save: (0, _emberConcurrency.task)(function* () {
      let swal = this.swal;
      let model = this.model;
      if (model.get('validations') && !model.get('validations.isValid')) {
        return swal.validation(model.get('validations'));
      }
      try {
        let created = yield model.save();
        swal.success('A Solicitação de Feedback foi salva com sucesso.');
        if (this.afterSave) {
          this.afterSave(created);
        }

        // this.router.transitionTo('model.show', created.get('id'));
        this.router.transitionTo('feedback-request');
      } catch (e) {
        return swal.catch(e);
      }
    })
  });
});