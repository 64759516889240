define("avaliacao-talentrh/pods/pde/team-user-list/item/action-item/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Html Attributes
    tagName: 'tr',
    // Services
    swal: Ember.inject.service(),
    // Tasks
    deleteEvidence: (0, _emberConcurrency.task)(function* (evidence) {
      let swal = this.swal;
      try {
        let result = yield swal.confirm('Tem certeza que deseja remover este registro?');
        if (!result.value) {
          return;
        }
        yield evidence.destroyRecord();
        swal.success('Removido com sucesso.');
      } catch (e) {
        swal.catch(e);
      }
    })
  });
});