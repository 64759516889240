define("avaliacao-talentrh/pods/manage-goals/update-achieve/form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Services
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    // LifeCycles

    didInsertElement() {
      this._super(...arguments);
      this.onLoadForm(this.save);
    },
    // Concurrency Tasks
    save: (0, _emberConcurrency.task)(function* () {
      const model = this.goalAchieved;
      model.set('userGoal', this.userGoal);
      if (!model.validations.isValid) {
        return this.swal.validation(model.validations);
      }
      try {
        const created = yield model.save();
        this.swal.success('Meta foi atualizada com sucesso!');
        if (this.afterSave) {
          this.afterSave(created);
        }
        this.router.transitionTo('manage-goals');
      } catch (e) {
        return this.swal.catch(e);
      }
    }),
    dateSelected: (0, _emberConcurrency.task)(function* ({
      date
    }) {
      try {
        const goal = this.userGoal.goal;
        yield goal;
        if (moment(date).isAfter(this.userGoal.goal.get('endAt'))) {
          return this.swal.warning('Data da atualização tem que ser anterior a data de encerramento da meta!');
        }
        Ember.set(this, 'goalAchieved.achieveDate', date);
      } catch (e) {
        return this.swal.catch(e);
      }
    })
  });
});