define("avaliacao-talentrh/pods/new-evaluation/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "my8JQLnW",
    "block": "{\"symbols\":[\"Page\",\"Header\",\"Crumb\"],\"statements\":[[8,\"ui-page\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Editar Questionário\"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,2,[\"breadcrumbs\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,3,[\"link\"]],[],[[\"@text\",\"@link\"],[\"Início\",\"home\"]],null],[2,\"\\n      \"],[8,[32,3,[\"link\"]],[],[[\"@text\",\"@link\"],[\"Questionários\",\"new-evaluation\"]],null],[2,\"\\n      \"],[8,[32,3,[\"link\"]],[],[[\"@text\"],[\"Editar\"]],null],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"section\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"form-new-evaluation\",[],[[\"@model\"],[[32,0,[\"model\"]]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/new-evaluation/edit/template.hbs"
    }
  });
  _exports.default = _default;
});