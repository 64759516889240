define("avaliacao-talentrh/pods/evaluations/new/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Properties
    createFor: 'employees',
    branch: null,
    appraiser: null,
    // Computed Porperties
    createForEmployees: Ember.computed.equal('createFor', 'employees'),
    createForDepartment: Ember.computed.equal('createFor', 'department'),
    createForJobFunction: Ember.computed.equal('createFor', 'jobFunction'),
    createForBranch: Ember.computed.equal('createFor', 'branch'),
    evaluationTypes: Ember.A([{
      name: 'Primeira Avaliação',
      value: 1
    }, {
      name: 'Avaliação de Efetivação',
      value: 2
    }, {
      name: 'Avaliação de Função',
      value: 3
    }]),
    userListMessage: Ember.computed('createFor', 'userList.length', function () {
      if (this.userList && this.userList.length) {
        return;
      }
      let messages = {
        employees: 'Nenhum empregado foi selecionado',
        department: 'Seu usuário não pode gerenciar os colaboradores deste departamento',
        jobFunction: 'Seu usuário não pode gerenciar os colaboradores desta função'
      };
      if (this[this.createFor]) {
        return messages[this.createFor];
      } else {
        return 'Nenhum registro selecionado';
      }
    }),
    // Concurrency Tasks
    createEvaluations: (0, _emberConcurrency.task)(function* (users) {
      let userIds = (users || []).mapBy('id');
      let type = Ember.get(this, 'evaluationType.value');
      let questionnaireId = this.questionnaire ? Number(this.questionnaire.id) : null;
      if (!type) {
        return this.swal.warning('É necessário informar o Tipo de Avaliação.');
      }
      if (!userIds.length) {
        return this.swal.warning('É necessário ter pelo menos um colaborador selecionado para continuar.');
      }
      try {
        const {
          errors,
          success
        } = yield this.ajax.post('/employeeEvaluations/createEvaluations', {
          data: {
            userIds,
            type,
            questionnaireId,
            appraiser: this.appraiser?.id
          }
        });
        this.userList.forEach(user => {
          let foundErr = errors.findBy('userId', Number(user.id));
          let foundSuccess = success.findBy('userId', Number(user.id));
          if (foundErr) {
            Ember.set(user, 'success', false);
            Ember.set(user, 'err', foundErr.err);
          } else if (foundSuccess) {
            Ember.set(user, 'success', true);
            Ember.set(user, 'err', null);
          }
        });
        if (errors.length) {
          this.swal.warning('Não foi possível criar avaliações para alguns colaboradores da lista, após resolver as pendências você pode tentar novamente.');
        } else {
          this.toast.success('Avaliação cadastrada');
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    afterSelect: (0, _emberConcurrency.task)(function* () {
      let userList = this.employees;
      if (this.createFor === 'department') {
        userList = yield this.ajax.request('/users/usersICanManageAjax', {
          data: {
            department: Ember.get(this, 'department.id')
          }
        });
      } else if (this.createFor === 'jobFunction') {
        userList = yield this.ajax.request('/users/usersICanManageAjax', {
          data: {
            jobFunction: Ember.get(this, 'jobFunction.id')
          }
        });
      } else if (this.createFor === 'branch') {
        userList = yield this.ajax.request('/users/usersICanManageAjax', {
          data: {
            branch: Ember.get(this, 'branch.id')
          }
        });
      }
      Ember.set(this, 'userList', userList);
    }).restartable(),
    actions: {
      tryAgain(user) {
        this.createEvaluations.perform([user]);
      },
      ignoreUser(user) {
        this.userList.removeObject(user);
      },
      onChangeRadio() {
        Ember.set(this, 'userList', Ember.A());
        Ember.set(this, 'employees', Ember.A());
      }
    }
  });
});