define("avaliacao-talentrh/pods/application/notification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xx8nc9jp",
    "block": "{\"symbols\":[\"notification\"],\"statements\":[[10,\"div\"],[14,0,\"border-bottom notification-header pb-1\"],[12],[2,\"\\n  \"],[10,\"h6\"],[14,0,\"d-inline-block mb-0 ml-3\"],[12],[2,\"Notificações\"],[13],[2,\"\\n\\n  \"],[10,\"button\"],[14,0,\"btn btn-link text-dark float-right p-0 mr-3\"],[15,\"onclick\",[30,[36,0],[[32,0],\"readAllNotifications\"],null]],[12],[2,\"\\n    Marcar todas como lidas\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"ul\"],[14,0,\"ui-navbar__nav__dropdown dropdown-notification list-group list-group-flush\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"application/notification/item\",[],[[\"@notification\",\"@mouseLeave\"],[[32,1],[30,[36,0],[[32,0],\"setAsRead\",[32,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"text-center py-3\"],[12],[2,\"Não há novas notificações.\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"lastNotifications\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/application/notification/template.hbs"
    }
  });
  _exports.default = _default;
});