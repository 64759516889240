define("avaliacao-talentrh/pods/evaluations/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    userConfigs: Ember.inject.service(),
    evaluationId: Ember.computed('evaluation', 'evaluation.id', 'evaluationTeam.id', function () {
      const evaluationTeamId = Ember.get(this, 'evaluationTeam.id');
      const evaluationId = Ember.get(this, 'evaluation.id');
      if (!evaluationTeamId && !evaluationId) return this.evaluation;
      return evaluationTeamId || evaluationId;
    }),
    evaluationRoute: Ember.computed('evaluation.id', 'evaluationTeam.id', function () {
      return this.evaluationTeam ? 'evaluations.show-team' : 'evaluations.show';
    }),
    evaluationRole: Ember.computed('evaluation.appraisedUser.id', 'evaluation.appraiserUser.id', 'evaluationTeam', 'isEvaluationTeam', 'userConfigs.sessionUser.id', function () {
      let evaluationTeam = this.evaluationTeam;
      let sessionUserId = Ember.get(this, 'userConfigs.sessionUser.id');
      let appraisedId = Ember.get(this, 'evaluation.appraisedUser.id');
      let appraiserId = Ember.get(this, 'evaluation.appraiserUser.id');
      if (evaluationTeam) {
        return 'Você é o avaliador de equipe';
      } else if (sessionUserId === appraisedId) {
        return 'Você é o avaliado';
      } else if (sessionUserId === appraiserId) {
        return 'Você é o avaliador';
      } else {
        return;
      }
    }),
    statusBadge: Ember.computed('evaluation.{canceled,isConcluded}', function () {
      let canceled = Ember.get(this, 'evaluation.canceled');
      let isConcluded = Ember.get(this, 'evaluation.isConcluded');
      if (canceled) {
        return {
          color: 'danger',
          text: 'Cancelada'
        };
      } else if (isConcluded) {
        return {
          color: 'success',
          text: 'Concluída'
        };
      } else {
        return {
          color: 'warning',
          text: 'Pendente'
        };
      }
    })
  });
  _exports.default = _default;
});