define("avaliacao-talentrh/models/followup", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    description: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Informe a descrição.'
    }),
    date: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Informe uma data.'
    })
  });
  var _default = _emberData.default.Model.extend(Validations, {
    company: _emberData.default.belongsTo('company'),
    user: _emberData.default.belongsTo('user'),
    createdBy: _emberData.default.belongsTo('user'),
    feedback: _emberData.default.belongsTo('feedback'),
    objective: _emberData.default.attr('string'),
    date: _emberData.default.attr('date'),
    description: _emberData.default.attr('string'),
    status: _emberData.default.attr('number')
  });
  _exports.default = _default;
});