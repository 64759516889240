define("avaliacao-talentrh/pods/evaluations/show/results/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    userConfigs: Ember.inject.service(),
    setupController(controller, model) {
      this._super(controller, model);
      controller.set('model', model);
      return model.get('appraisedUser').then(appraisedUser => {
        if (appraisedUser.typeEvaluation) {
          controller.set('evaluationType', appraisedUser.typeEvaluation);
        }
        const companyConfig = this.userConfigs.companyConfiguration;
        const type = model.type;
        if (type === 1) {
          return controller.set('evaluationType', companyConfig.typeFirstEvaluation);
        }
        if (type === 2) {
          return controller.set('evaluationType', companyConfig.typeLastEvaluation);
        }
        if (type === 3) {
          return controller.set('evaluationType', companyConfig.typeFunctionEvaluation);
        }
      });
    }
  });
});