define("avaliacao-talentrh/pods/pdi/new/route", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model(params) {
      const {
        user: userId,
        insightLed: insightLedId
      } = params;
      if (userId) {
        return this.modelTask.perform(userId, insightLedId);
      }
      return Ember.RSVP.hash({
        pdi: this.store.createRecord('pdi')
      });
    },
    modelTask: (0, _emberConcurrency.task)(function* (userId, insightLedId) {
      try {
        const user = yield this.store.findRecord('user', userId);
        const pdi = yield this.store.createRecord('pdi', {
          user
        });
        let insightLed;
        if (insightLedId) {
          insightLed = yield this.store.findRecord('insight-led', insightLedId);
        }
        return Ember.RSVP.hash({
          pdi,
          insightLed
        });
      } catch (e) {
        Ember.debug(e);
      }
    }),
    actions: {
      afterSave() {
        this.send('reload');
      }
    }
  });
  _exports.default = _default;
});