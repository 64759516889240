define("avaliacao-talentrh/pods/feedback/new/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    queryParams: ['user', 'insightLed'],
    // Services
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    userId: Ember.computed.alias('model.feedback.user.id'),
    feedbackRequest: Ember.computed.alias('model.feedbackRequest'),
    notes: Ember.computed.alias('model.feedback.user.notes'),
    members: Ember.computed.alias('userConfigs.usersICanManage.users'),
    userIsMember: Ember.computed('model.feedback.user.id', function () {
      if (!this.members) return false;
      let member = this.members.findIndex(u => Number(u) === Number(this.userId));
      return member >= 0;
    }),
    // Tasks
    refuseRequest: (0, _emberConcurrency.task)(function* () {
      if (!this.feedbackRequest) {
        return this.transitionToRoute('feedback');
      }
      try {
        let id = Ember.get(this, 'feedbackRequest.id');
        let result = yield this.swal.fire({
          input: 'textarea',
          title: 'Deseja dispensar essa solicitação?',
          text: 'Após esta operação a solicitação não poderá ser editada.',
          inputPlaceholder: 'Informe o motivo.',
          inputValidator: value => {
            return !value && 'Você precisa informar o motivo.';
          }
        });
        if (result.value === undefined) {
          return;
        }
        let response = yield this.ajax.put(`/feedbackRequests/${id}/refuse`, {
          data: {
            refusalReason: result.value
          }
        });
        this.store.pushPayload(response);
        this.transitionToRoute('feedback');
        this.swal.success('A solicitação foi dispensada.');
      } catch (e) {
        this.swal.catch(e);
      }
    })
  });
});