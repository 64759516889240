define("avaliacao-talentrh/pods/feedback/show/historic/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "upHwCpW2",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,1],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"dropdown\"],[14,\"title\",\"Ver histórico\"],[12],[2,\"\\n    \"],[10,\"button\"],[14,0,\"btn btn-link p-0\"],[14,\"data-toggle\",\"dropdown\"],[14,\"aria-haspopup\",\"true\"],[14,\"aria-expanded\",\"false\"],[14,4,\"button\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"far fa-history fa-xs\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"dropdown-menu max-h-vh-3 overflow-auto ui-scrollbar p-0 transform-none\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"p-2 text-nowrap text-truncate border-bottom\"],[14,5,\"width: 19rem;\"],[12],[2,\"\\n          \"],[10,\"h6\"],[14,0,\"mb-0\"],[12],[2,\"\\n            Feedback foi\\n            \"],[10,\"strong\"],[15,0,[31,[\"text-\",[32,1,[\"approvalStatusBadge\",\"color\"]]]]],[12],[2,\"\\n              \"],[1,[32,1,[\"approvalStatusBadge\",\"text\"]]],[2,\"\\n            \"],[13],[2,\"\\n            em\\n            \"],[10,\"strong\"],[12],[2,\"\\n              \"],[1,[30,[36,0],[[32,1,[\"createdAt\"]],\"DD/MM/YY HH:mm\"],null]],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n\"],[6,[37,1],[[32,1,[\"reason\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"h6\"],[14,0,\"mt-1 mb-0 text-truncate\"],[15,\"title\",[32,1,[\"reason\"]]],[12],[2,\"\\n              \"],[10,\"strong\"],[12],[2,\"Motivo:\"],[13],[2,\" \"],[1,[32,1,[\"reason\"]]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"if\",\"sorted\",\"-track-array\",\"each\",\"historic\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/feedback/show/historic/template.hbs"
    }
  });
});