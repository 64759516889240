define("avaliacao-talentrh/pods/evaluations/show-team/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    evaluationTeam: Ember.computed.alias('model'),
    canRate: Ember.computed('evaluationTeam.{appraiserConcluded,employeeEvaluation.isAvailable}', function () {
      return !Ember.get(this, 'evaluationTeam.appraiserConcluded') && Ember.get(this, 'evaluationTeam.employeeEvaluation.isAvailable');
    })
  });
});