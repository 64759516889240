define("avaliacao-talentrh/models/pdi", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* globals moment */

  const Validations = (0, _emberCpValidations.buildValidations)({
    user: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione um colaborador.'
    }),
    objective: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Informe qual é o objetivo.'
    }),
    expirationDate: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Informe a data de expiração.'
    })
  });
  var _default = _exports.default = _model.default.extend(Validations, {
    // Attr
    annotationsPdi: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    description: (0, _model.attr)('string'),
    expirationDate: (0, _model.attr)('date'),
    objective: (0, _model.attr)('string'),
    read: (0, _model.attr)('boolean', {
      defaultValue: 0
    }),
    readDate: (0, _model.attr)('date'),
    status: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // BelongsTo
    company: (0, _model.belongsTo)('company'),
    createdBy: (0, _model.belongsTo)('user'),
    readBy: (0, _model.belongsTo)('user'),
    skill: (0, _model.belongsTo)('skill'),
    user: (0, _model.belongsTo)('user'),
    // HasMany
    pdiActions: (0, _model.hasMany)('pdiAction'),
    // hasMany
    pdiComments: (0, _model.hasMany)('pdi-comment', {
      inverse: null
    }),
    // Computed
    isExpired: Ember.computed('expirationDate', function () {
      return moment().startOf('day').isAfter(this.expirationDate);
    }),
    readBadge: Ember.computed('read', function () {
      let status = this.read;
      let badges = {
        'false': {
          text: 'Não visualizado',
          color: 'info'
        },
        'true': {
          text: 'Visualizado',
          color: 'success'
        }
      };
      if (this.read) {
        status = 'true';
      }
      return badges[status] || {};
    }),
    statusBadge: Ember.computed('status', 'isExpired', function () {
      let status = this.status;
      let badges = {
        0: {
          text: 'Aberto',
          color: 'info'
        },
        1: {
          text: 'Finalizado',
          color: 'success'
        },
        2: {
          text: 'Expirado',
          color: 'warning'
        }
      };
      if (this.isExpired && this.status !== 1) {
        status = 2;
      }
      return badges[status] || {};
    })
  });
});