define("avaliacao-talentrh/pods/analytics/nine-box/cycle/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AnalyticsNineBoxCycleEditRoute extends Ember.Route {
    model() {
      let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return this.store.findRecord('nine-box-cycle', params.nine_box_cycle_id);
    }
  }
  _exports.default = AnalyticsNineBoxCycleEditRoute;
});