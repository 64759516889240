define("avaliacao-talentrh/pods/components/modal-notes/component", ["exports", "@glimmer/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ModalNotes = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.alias('args.route'), _dec5 = Ember.computed.alias('args.user'), _dec6 = Ember.computed.alias('userConfigs.companyConfiguration.showJobFunction'), _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember.computed('showJobFunction', 'user.jobFunction.{isFulfilled,title}', 'user.jobRole.{isFulfilled,title}'), _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _class = class ModalNotes extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "userConfigs", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "route", _descriptor4, this);
      _initializerDefineProperty(this, "user", _descriptor5, this);
      _initializerDefineProperty(this, "showJobFunction", _descriptor6, this);
      _initializerDefineProperty(this, "filters", _descriptor7, this);
      _initializerDefineProperty(this, "initialDate", _descriptor8, this);
      _initializerDefineProperty(this, "endDate", _descriptor9, this);
    }
    get jobTitle() {
      if (this.showJobFunction) {
        return this.user.get('jobFunction.title') ? this.user.get('jobFunction.title') : 'Não informado';
      } else {
        return this.user.get('jobRole.title') ? this.user.get('jobRole.title') : 'Não informado';
      }
    }
    setInitialDate({
      date
    }) {
      this.initialDate = date;
      if (this.initialDate && this.endDate && this.initialDate > this.endDate) {
        this.swal.warning('A data final não pode ser inferior a inicial.');
        this.initialDate = null;
        return;
      }
      this.buildFilters();
    }
    setEndDate({
      date
    }) {
      this.endDate = date;
      if (this.initialDate && this.endDate && this.initialDate > this.endDate) {
        this.swal.warning('A data final não pode ser inferior a inicial.');
        this.endDate = null;
        return;
      }
      this.buildFilters();
    }
    buildFilters() {
      const filters = {
        user: this.user.get('id')
      };
      if (this.initialDate) {
        filters.initialDate = (0, _moment.default)(this.initialDate).format('YYYY-MM-DD 00:00:00');
      }
      if (this.endDate) {
        filters.endDate = (0, _moment.default)(this.endDate).format('YYYY-MM-DD 23:59:59');
      }
      this.filters = filters;
    }
    onCloseModal() {
      this.initialDate = null;
      this.endDate = null;
      this.router.transitionTo(this.route);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "userConfigs", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "route", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showJobFunction", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        user: this.user.get('id')
      };
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "initialDate", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "endDate", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "jobTitle", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "jobTitle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setInitialDate", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setInitialDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setEndDate", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "setEndDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildFilters", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "buildFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCloseModal", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseModal"), _class.prototype), _class);
});