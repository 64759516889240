define("avaliacao-talentrh/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jpdhp/eJ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[34,3]],[2,\"\\n\\n  \"],[8,\"application/navbar\",[],[[\"@user\"],[[34,4,[\"sessionUser\"]]]],null],[2,\"\\n  \"],[8,\"application/sidebar\",[],[[],[]],null],[2,\"\\n\\n  \"],[8,\"ui-application\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[32,0,[\"notAllowed\"]]],null,[[\"default\",\"else\"],[{\"statements\":[],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"if\",\"socket-handler\",\"userConfigs\",\"isReady\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/application/template.hbs"
    }
  });
});