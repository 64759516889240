define("avaliacao-talentrh/helpers/breaklines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.breaklines = breaklines;
  _exports.default = void 0;
  function breaklines(params /*, hash*/) {
    let [text] = params;
    if (!text) return;
    text = text.replace(/(\r\n|\n|\r)/gm, '<br>');
    return new Ember.String.htmlSafe(text);
  }
  var _default = Ember.Helper.helper(breaklines);
  _exports.default = _default;
});