define("avaliacao-talentrh/pods/components/nine-box/quadrant/user-avatar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vL5WDzlP",
    "block": "{\"symbols\":[],\"statements\":[[10,\"img\"],[14,0,\"avatar-4\"],[15,\"src\",[32,0,[\"user\",\"avatar\"]]],[14,\"onerror\",\"if (this.src != 'error.jpg') this.src = '/images/user-sem-imagem.png';\"],[15,\"alt\",[32,0,[\"user\",\"fullName\"]]],[15,\"title\",[32,0,[\"popoverContent\"]]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/components/nine-box/quadrant/user-avatar/template.hbs"
    }
  });
  _exports.default = _default;
});