define("avaliacao-talentrh/models/access-profile", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    allowAvdRegistration: (0, _model.attr)('boolean'),
    defaultProfile: (0, _model.attr)('boolean'),
    description: (0, _model.attr)('string'),
    generalManager: (0, _model.attr)('boolean'),
    allowManageGoals: (0, _model.attr)('boolean'),
    allowCreateGoals: (0, _model.attr)('boolean'),
    allowAccessSchedule: (0, _model.attr)('boolean'),
    profileRh: (0, _model.attr)('boolean'),
    admin: (0, _model.attr)('boolean'),
    title: (0, _model.attr)('string'),
    // Associations
    company: (0, _model.belongsTo)('company'),
    users: (0, _model.hasMany)('user')
  });
  _exports.default = _default;
});