define("avaliacao-talentrh/components/nela-report/card-filter", ["exports", "nela-report/components/nela-report/card-filter"], function (_exports, _cardFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _cardFilter.default;
    }
  });
});