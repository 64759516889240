define("avaliacao-talentrh/pods/printed-evaluation/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    // Multi line computed properties
    filterStatusOptions: Ember.A([{
      label: 'Pendente',
      value: 0
    }, {
      label: 'Concluído',
      value: 1
    }]),
    // Lifecycle hooks
    init() {
      this._super(...arguments);
      Ember.set(this, 'filters', {
        printed: 1
      });
    },
    // Custom methods
    getFilters() {
      try {
        let userFilter = this.userFilter;
        let initialDate = this.initialDate;
        let finalDate = this.finalDate;
        let status = this.filterStatus;
        let query = {
          printed: 1
        };
        if (status) {
          query.status = status.value;
        }
        if (userFilter && userFilter.get('id')) {
          query.appraisedUser = userFilter.get('id');
        }
        if (initialDate && finalDate) {
          query.createdAt = {
            '>=': (0, _moment.default)(initialDate).format('YYYY-MM-DD 00:00:00'),
            '<=': (0, _moment.default)(finalDate).format('YYYY-MM-DD 23:59:59')
          };
        }
        return query;
      } catch (e) {
        this.swal.catch(e);
      }
    },
    // Actions
    actions: {
      filter() {
        let filters = this.getFilters();
        Ember.set(this, 'filters', filters);
      },
      clearFilters() {
        Ember.setProperties(this, {
          userFilter: null,
          initialDate: null,
          finalDate: null,
          filterStatus: null
        });
        this.send('filter');
      }
    }
  });
});