define("avaliacao-talentrh/models/branch", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    address: (0, _attr.default)('string'),
    cnpj: (0, _attr.default)('string'),
    complement: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    neighborhood: (0, _attr.default)('string'),
    number: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    phoneDDD: (0, _attr.default)('string'),
    tradingName: (0, _attr.default)('string'),
    zipcode: (0, _attr.default)('string'),
    goals: (0, _relationships.hasMany)('goal', {
      inverse: 'branches'
    })
  });
  _exports.default = _default;
});