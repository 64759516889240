define("avaliacao-talentrh/helpers/previous-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.previousNote = previousNote;
  function previousNote(params, hash) {
    let pageId = parseInt(hash.pageId);
    let questionId = parseInt(hash.questionId);
    let previousAnswer = hash.previousAnswer;
    if (!previousAnswer) {
      return;
    }
    let answerFound = previousAnswer.find(obj => {
      return obj.pageId === pageId && obj.questionId === questionId;
    });
    return answerFound ? answerFound.percentage : '';
  }
  var _default = _exports.default = Ember.Helper.helper(previousNote);
});