define("avaliacao-talentrh/pods/indicators/pdi-detail/chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7j1hhm6h",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"card-body bg-white py-2\"],[12],[2,\"\\n  \"],[10,\"h6\"],[14,0,\"font-weight-bold border-bottom pb-3 pt-2\"],[12],[2,\"Cadastro de evidências\"],[13],[2,\"\\n  \"],[10,\"canvas\"],[14,1,\"pdiEvidenceDetails\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/indicators/pdi-detail/chart/template.hbs"
    }
  });
  _exports.default = _default;
});