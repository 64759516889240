define("avaliacao-talentrh/pods/pde/show/show-action/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    // Single line computed properties
    pdeAction: Ember.computed.alias('model')
  });
});