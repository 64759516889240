define("avaliacao-talentrh/pods/components/comments/item/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Services
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Default values
    editComment: false,
    allowEdit: Ember.computed('comment', function () {
      return Number(Ember.get(this.comment, 'createdBy.id')) === this.session.user.id;
    }),
    // Tasks
    removeComment: (0, _emberConcurrency.task)(function* () {
      let comment = this.comment;
      try {
        let {
          value
        } = yield this.swal.confirm('Remover comentário?');
        if (!value) {
          return;
        }
        yield comment.destroyRecord();
        this.toast.success('Comentário removido');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    save: (0, _emberConcurrency.task)(function* () {
      let comment = this.comment;
      try {
        yield comment.save();
        Ember.set(this, 'editComment', false);
        this.toast.success('Comentário atualizado');
      } catch (e) {
        comment.rollbackAttributes();
        this.swal.catch(e);
      }
    }).drop(),
    actions: {
      cancelCommentEdit() {
        Ember.set(this, 'editComment', false);
        this.comment.rollbackAttributes();
      }
    }
  });
});