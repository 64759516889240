define("avaliacao-talentrh/pods/components/nine-box/quadrant/user-avatar/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let NineBoxQuadrantUserAvatarComponent = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('args.quadrantUser.user'), _dec3 = Ember.computed.alias('args.nineBoxCycle.xAxisDescription'), _dec4 = Ember.computed.alias('args.nineBoxCycle.yAxisDescription'), _dec5 = Ember.computed.alias('user.jobFunction'), _dec6 = Ember.computed.alias('user.jobRole'), _dec7 = Ember.computed.alias('args.quadrantUser.user'), _dec8 = Ember.computed.alias('userConfigs.companyConfiguration.showJobFunction'), _dec9 = Ember.computed('jobFunction.title'), _dec10 = Ember.computed('jobRole.title'), _dec11 = Ember.computed('showJobFunction', 'jobFunctionDescription', 'jobRoleDescription'), _dec12 = Ember.computed('user.{isLoaded,fullName}', 'args.quadrantUser.{xAxisPercentage,yAxisPercentage}', 'jobFunctionOrRoleDescription', 'xAxisDescription', 'yAxisDescription'), (_class = class NineBoxQuadrantUserAvatarComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "userConfigs", _descriptor, this);
      _initializerDefineProperty(this, "user", _descriptor2, this);
      _initializerDefineProperty(this, "xAxisDescription", _descriptor3, this);
      _initializerDefineProperty(this, "yAxisDescription", _descriptor4, this);
      _initializerDefineProperty(this, "jobFunction", _descriptor5, this);
      _initializerDefineProperty(this, "jobRole", _descriptor6, this);
      _initializerDefineProperty(this, "user", _descriptor7, this);
      _initializerDefineProperty(this, "showJobFunction", _descriptor8, this);
    }
    // Methods
    get jobFunctionDescription() {
      const jobFunctionDescription = this.jobFunction.get('title') ? this.jobFunction.get('title') : 'Não informado';
      return `Função: ${jobFunctionDescription}`;
    }
    get jobRoleDescription() {
      const jobRoleDescription = this.jobRole.get('title') ? this.jobRole.get('title') : 'Não informado';
      return `Cargo: ${jobRoleDescription}`;
    }
    get jobFunctionOrRoleDescription() {
      if (this.showJobFunction) {
        return this.jobFunctionDescription;
      } else {
        return this.jobRoleDescription;
      }
    }

    // Multi line computed
    get popoverContent() {
      let content = `${this.user.get('fullName')}\n`;
      content += `${this.jobFunctionOrRoleDescription}\n`;
      content += `${this.yAxisDescription}: ${this.args.quadrantUser.yAxisPercentageFormatted}%\n`;
      content += `${this.xAxisDescription}: ${this.args.quadrantUser.xAxisPercentageFormatted}%`;
      return content;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "userConfigs", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "xAxisDescription", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "yAxisDescription", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "jobFunction", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "jobRole", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showJobFunction", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "jobFunctionDescription", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "jobFunctionDescription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "jobRoleDescription", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "jobRoleDescription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "jobFunctionOrRoleDescription", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "jobFunctionOrRoleDescription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "popoverContent", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "popoverContent"), _class.prototype)), _class));
  _exports.default = NineBoxQuadrantUserAvatarComponent;
});