define("avaliacao-talentrh/pods/pdi/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    router: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    actions: {
      reload() {
        let filters = this.controller.getFilters();
        this.controller.set('filters', filters);
      }
    }
  });
});