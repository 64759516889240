define("avaliacao-talentrh/pods/manage-goals/import-goals/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ccD4dd07",
    "block": "{\"symbols\":[\"Modal\",\"Footer\"],\"statements\":[[8,\"ui-modal\",[[24,\"data-backdrop\",\"static\"]],[[\"@open\",\"@modalSize\",\"@onCloseModal\"],[true,\"modal-lg\",[30,[36,0],[[32,0],\"onCloseModal\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      Importar Metas\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"p\"],[12],[2,\"1) Deve ser preenchida a planilha, gerada pela opção \\\"Exportar CSV\\\";\"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[10,\"strong\"],[12],[2,\"IMPORTANTE:\"],[13],[2,\" Não apagar nem adicionar colunas, pode gerar erro na importação;\"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"Quando estiver com a planilha preenchida, clique em  \\\"Importar planilha\\\" para prosseguir com a importação.\"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"button\"],[14,0,\"btn btn-primary mr-2\"],[15,\"onclick\",[30,[36,0],[[32,0],\"getFile\"],null]],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fas fa-inbox-out\"],[12],[13],[2,\" Importar planilha\\n    \"],[13],[2,\"\\n    \"],[10,\"input\"],[14,0,\"d-none\"],[14,1,\"import-goal-file\"],[15,\"onchange\",[30,[36,0],[[32,0],\"loadXlsx\"],null]],[14,4,\"file\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/manage-goals/import-goals/template.hbs"
    }
  });
  _exports.default = _default;
});