define("avaliacao-talentrh/router", ["exports", "avaliacao-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('home', {
      path: '/'
    }, function () {});
    this.route('login');
    this.route('register', function () {});
    this.route('evaluation', function () {
      this.route('answer', {
        path: 'answer/:id'
      }, function () {
        this.route('notes');
      });
      this.route('answer-team', {
        path: 'answer-team/:id'
      });
      this.route('answer-forbidden');
      this.route('answer-consensus', {
        path: 'answer-consensus/:id'
      }, function () {
        this.route('notes');
      });
    });
    this.route('feedback', function () {
      this.route('new', function () {
        this.route('notes');
      });
      this.route('edit', {
        path: ':feedback_id/editar'
      });
      this.route('show', {
        path: ':feedback_id'
      }, function () {
        this.route('new-followup');
        this.route('edit-followup', {
          path: 'followup/:id/editar'
        });
      });
      this.route('error');
    });
    this.route('pdi', function () {
      this.route('new', function () {
        this.route('notes');
      });
      this.route('show', {
        path: ':pdi_id'
      }, function () {
        this.route('edit-action', {
          path: 'pdiAction/:id/editar'
        });
        this.route('new-action');
        this.route('show-action', {
          path: 'pdiAction/:pdi_action_id'
        }, function () {
          this.route('new-evidence');
          this.route('show-evidence', {
            path: 'evidence/:pdi_evidence_id'
          });
        });
        this.route('percentage-action');
      });
      this.route('edit', {
        path: ':pdi_id/editar'
      });
    });
    this.route('reports', function () {
      this.route('performance-indicators');
      this.route('performance-by-question');
      this.route('evaluation-results');
      this.route('evaluation-feedback');
      this.route('performance-evaluation');
      this.route('experience-ratings');
      this.route('print-evaluation');
      this.route('print-evaluation-employee');
      this.route('feedback');
      this.route('consolidated-ratings');
      this.route('evaluation-team');
      this.route('print-evaluation-by-employee');
      this.route('evaluation-with-results');
      this.route('team-evaluation-with-results');
    });
    this.route('report', function () {});
    this.route('notification');
    this.route('new-evaluation', function () {
      this.route('new');
      this.route('edit', {
        path: 'edit/:id'
      });
    });
    this.route('pde', function () {
      this.route('create');
      this.route('edit', {
        path: ':pde_id/edit'
      });
      this.route('show', {
        path: ':pde_id'
      }, function () {
        this.route('edit-action', {
          path: 'pdeAction/:id/editar'
        });
        this.route('new-action');
        this.route('show-action', {
          path: 'pdeAction/:pde_action_id'
        }, function () {
          this.route('show-evidence', {
            path: 'evidence/:pde_evidence_id'
          });
          this.route('new-evidence');
        });
      });
    });
    this.route('feedback-request', function () {
      this.route('new');
      this.route('edit', {
        path: ':feedback_request_id/editar'
      });
      this.route('show', {
        path: ':feedback_request_id'
      });
    });
    this.route('show', function () {});
    this.route('evaluations', function () {
      this.route('show', {
        path: ':employee_evaluation_id'
      }, function () {
        this.route('results');
        this.route('answers');
        this.route('add-feedback');
        this.route('feedback', function () {
          this.route('new');
          this.route('edit');
        });
      });
      this.route('show-team', {
        path: ':employee_evaluation_team_id/team'
      });
      this.route('new');
    });
    this.route('external-evaluation', function () {
      this.route('create');
      this.route('edit', {
        path: ':employee_external_evaluation_id/edit'
      });
      this.route('show', {
        path: ':employee_external_evaluation_id'
      });
    });
    this.route('printed-evaluation', function () {
      this.route('show', {
        path: ':employee_evaluation_id'
      });
    });
    this.route('manage-goals', function () {
      this.route('update-achieve', {
        path: ':user_goal_id'
      });
      this.route('show-achieve', {
        path: ':user_goal_id/show'
      });
      this.route('goal-evolution', {
        path: ':user_goal_id/showEvolution'
      });
      this.route('import-goals');
      this.route('export-goals');
    });
    this.route('indicators');
    this.route('indicators', function () {
      this.route('pdi');
    });
    this.route('indicators', function () {
      this.route('pdi');
      this.route('pdi-detail', {
        path: '/:user_id/:pdi_id'
      });
      this.route('collaborator-evolution');
    });
    this.route('analytics', function () {
      this.route('nine-box', function () {
        this.route('cycle', function () {
          this.route('new');
          this.route('edit', {
            path: ':nine_box_cycle_id/edit'
          });
        });
      });
    });
    this.route('privacy-policy');
    this.route('errors', function () {
      this.route('forbidden');
    });
  });
  var _default = Router;
  _exports.default = _default;
});