define("avaliacao-talentrh/pods/evaluations/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    userConfigs: Ember.inject.service(),
    router: Ember.inject.service(),
    accessProfile: Ember.computed.alias('userConfigs.accessProfile'),
    leds: Ember.computed.alias('userConfigs.usersICanManage.users'),
    deactivate() {
      this._super(...arguments);
      Ember.setProperties(this.controller, {
        createFor: 'employees',
        evaluationType: null,
        questionnaire: null,
        department: null,
        jobFunction: null,
        employees: Ember.A(),
        userList: Ember.A()
      });
    },
    beforeModel() {
      const allowed = this.accessProfile.admin || this.leds.length || this.accessProfile.generalManager;
      if (!allowed) {
        return this.router.transitionTo('errors.forbidden');
      }
    }
  });
});