define("avaliacao-talentrh/pods/evaluation/answer/notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "w3fzkl3L",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-notes\",[],[[\"@user\",\"@route\"],[[32,0,[\"appraisedUser\"]],\"evaluation.answer\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/evaluation/answer/notes/template.hbs"
    }
  });
  _exports.default = _default;
});