define("avaliacao-talentrh/pods/reports/evaluation-feedback/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);
      this.filters = {
        users: Ember.A(),
        departments: Ember.A(),
        startDate: null,
        endDate: null,
        showNote: "false",
        notesPage: "false",
        groupingType: 'answer',
        visualization: 'appraiser',
        evaluationType: 'all'
      };
    },
    actions: {
      buildFilters() {
        let filters = this.filters;
        let build = {};
        if (filters.users.length) {
          build.users = filters.users.mapBy('id');
        }
        if (filters.departments.length) {
          build.departments = filters.departments.mapBy('id');
        }
        if (filters.startDate) {
          build.start = moment(filters.startDate).format('DD/MM/YYYY');
        }
        if (filters.endDate) {
          build.end = moment(filters.endDate).format('DD/MM/YYYY');
        }
        build.showNote = filters.showNote;
        build.notesPage = filters.notesPage;
        build.groupingType = filters.groupingType;
        build.visualization = filters.visualization;
        build.evaluationType = filters.evaluationType;
        return build;
      }
    }
  });
  _exports.default = _default;
});