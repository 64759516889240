define("avaliacao-talentrh/pods/indicators/pdi-detail/chart/component", ["exports", "chart-js"], function (_exports, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didRender() {
      this._super(...arguments);
      this.initChart();
    },
    initChart() {
      let chart = this.chart;
      let chartData = {
        labels: this.data.months,
        datasets: [{
          label: 'Evidências',
          data: this.data.values,
          backgroundColor: ['rgba(82, 180, 254)', 'rgba(82, 180, 254)', 'rgba(82, 180, 254)', 'rgba(82, 180, 254)', 'rgba(82, 180, 254)', 'rgba(82, 180, 254)', 'rgba(82, 180, 254)', 'rgba(82, 180, 254)', 'rgba(82, 180, 254)', 'rgba(82, 180, 254)', 'rgba(82, 180, 254)', 'rgba(82, 180, 254)'],
          borderWidth: 1
        }]
      };
      let options = {
        scales: {
          yAxes: [{
            ticks: {
              min: 0,
              max: Math.max.apply(null, this.data.values),
              stepSize: 10
            }
          }]
        }
      };
      if (chart) {
        chart.data = chartData;
        chart.options = options;
        chart.update();
      } else {
        let ctx = document.getElementById('pdiEvidenceDetails').getContext('2d');
        chart = new _chartJs.default(ctx, {
          type: 'bar',
          responsive: true,
          maintainAspectRatio: false,
          data: chartData,
          options
        });
      }
      this.set('chart', chart);
    }
  });
  _exports.default = _default;
});