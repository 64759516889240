define("avaliacao-talentrh/pods/pde/team-user-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ZHtUSO8z",
    "block": "{\"symbols\":[\"user\",\"@pdeAction\"],\"statements\":[[10,\"h6\"],[14,0,\"font-weight-bold text-primary\"],[12],[2,\"Integrantes da Equipe\"],[13],[2,\"\\n\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2,[\"team\",\"users\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"pde/team-user-list/item\",[],[[\"@pdeAction\",\"@user\"],[[32,2],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/pde/team-user-list/template.hbs"
    }
  });
});