define("avaliacao-talentrh/pods/application/route", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    router: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    userCookie: Ember.inject.service(),
    model() {
      if (this.userConfigs.loadUserConfigs.isRunning) {
        return;
      }
      return this.userConfigs.loadUserConfigs.perform();
    },
    beforeModel: function () {
      if (!Ember.get(this, 'session.isAuthenticated')) {
        this.router.transitionTo('login');
      }
    },
    afterModel() {
      return this.afterModelTask.perform();
    },
    afterModelTask: (0, _emberConcurrency.task)(function* () {
      if (!this.session.isAuthenticated) {
        return;
      }

      // Remove o backup da session (login do superUser) caso seja do mesmo usuário logado
      if (this.session.user && this.session.data && this.session.data.keybkp && this.session.user.id === this.session.data.keybkp?.tokenData.user.id) {
        this.session.set('data.keybkp', undefined);
      }
      try {
        yield this.userCookie.handle();
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    actions: {
      loading(transition) {
        let controller = this.controllerFor('application');
        Ember.set(controller, 'currentlyLoading', false);
        transition.promise.finally(function () {
          Ember.set(controller, 'currentlyLoading', true);
        });
      },
      reloadRoute() {
        this.refresh();
      }
    }
  });
});