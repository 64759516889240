define("avaliacao-talentrh/pods/components/modal-notes/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wjRaKjDr",
    "block": "{\"symbols\":[\"@annotation\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n  \"],[10,\"td\"],[14,0,\"align-middle\"],[12],[1,[30,[36,0],[[32,1,[\"createdAt\"]],\"DD/MM/YYYY\"],null]],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"align-middle text-justify\"],[12],[1,[32,1,[\"description\"]]],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"position-relative align-bottom\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row text-xl-right\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-12 user-info-table\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"m-1\"],[14,\"title\",\"Líder que registrou a anotação\"],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"far fa-user text-info mr-2\"],[12],[13],[2,\"\\n          \"],[1,[32,1,[\"userRegistration\",\"firstName\"]]],[2,\" \"],[1,[32,1,[\"userRegistration\",\"lastName\"]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"moment-format\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/components/modal-notes/item/template.hbs"
    }
  });
});