define("avaliacao-talentrh/pods/manage-goals/update-achieve/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    actions: {
      onCloseModal() {
        this.router.transitionTo('manage-goals');
      }
    }
  });
  _exports.default = _default;
});