define("avaliacao-talentrh/pods/pdi/form-percentage-action/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8vUWiEfD",
    "block": "{\"symbols\":[\"pdiAction\"],\"statements\":[[10,\"div\"],[14,0,\"mb-2 mr-3\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row w-100\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-10 pl-4\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"Título da Ação\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-2\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"Percentual\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"pdiActions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"pdi/form-percentage-action/item\",[],[[\"@pdiActions\",\"@pdiAction\",\"@countActions\",\"@defaultPercentage\",\"@disableInputs\"],[[32,0,[\"pdiActions\"]],[32,1],[32,0,[\"countActions\"]],[32,0,[\"defaultPercentage\"]],[30,[36,0],[[32,0,[\"canChangePercentages\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-10\"],[12],[2,\"\\n    \"],[10,\"small\"],[15,0,[32,0,[\"classColorTotal\"]]],[12],[2,\"* A soma dos percentuais deve ser igual a 100%\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-2\"],[12],[2,\"\\n    \"],[10,\"p\"],[15,0,[32,0,[\"classColorTotal\"]]],[12],[2,\"Total: \"],[10,\"strong\"],[12],[1,[32,0,[\"sumPercentages\"]]],[2,\" %\"],[13],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"i\"],[14,0,\"fas fa-sync btn text-avd\"],[14,\"title\",\"Distribuir percentuais automaticamente\"],[15,\"onclick\",[30,[36,3],[[32,0],\"reloadDefaultPercentage\"],null]],[12],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"not\",\"-track-array\",\"each\",\"action\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/pdi/form-percentage-action/template.hbs"
    }
  });
});