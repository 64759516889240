define("avaliacao-talentrh/pods/pdi/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XnrhW+kc",
    "block": "{\"symbols\":[\"View\",\"Section\",\"Form\"],\"statements\":[[8,\"ui-crud/view\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"section\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      Criar Novo\\n\\n\"],[6,[37,2],[[30,[36,1],[[32,0,[\"userIsMember\"]],[30,[36,0],[[32,0,[\"notes\",\"length\"]],0],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"link-to\",[[24,0,\"btn btn-sm btn-outline-primary ml-auto right\"],[24,\"title\",\"Listar anotações\"]],[[\"@route\"],[\"pdi.new.notes\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[10,\"i\"],[14,0,\"fa fa-file-signature\"],[12],[13],[2,\"\\n          Anotações\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"ui-form-renderer\",[],[[\"@formName\"],[\"pdi/form\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,3,[\"content\"]],[],[[\"@pdi\",\"@insightLed\",\"@afterSave\"],[[32,0,[\"model\",\"pdi\"]],[32,0,[\"model\",\"insightLed\"]],[30,[36,3],[\"afterSave\"],null]]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\\n      \"],[8,[32,2,[\"footer\"]],[[24,0,\"text-right\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,3,[\"cancel\"]],[],[[\"@linkTo\"],[\"pdi\"]],null],[2,\"\\n        \"],[8,[32,3,[\"submit\"]],[],[[],[]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[1,[30,[36,5],[[30,[36,4],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"gt\",\"and\",\"if\",\"route-action\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/pdi/new/template.hbs"
    }
  });
});