define("avaliacao-talentrh/pods/feedback/form/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "moment"], function (_exports, _component, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.computed.alias('userConfigs.accessProfile'), _dec8 = Ember.computed.alias('userConfigs.companyConfiguration'), _dec9 = Ember.computed('args.feedback.user'), _dec10 = Ember.computed('args.feedback.pdi'), _dec11 = Ember.computed('args.feedback'), _dec12 = Ember.computed('accessProfile.{admin,allowAccessSchedule}', 'args', 'companyConfiguration.hasAgendaModule'), _dec13 = Ember._action, (_class = class FormComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "swal", _descriptor4, this);
      _initializerDefineProperty(this, "userConfigs", _descriptor5, this);
      _initializerDefineProperty(this, "session", _descriptor6, this);
      _initializerDefineProperty(this, "accessProfile", _descriptor7, this);
      _initializerDefineProperty(this, "companyConfiguration", _descriptor8, this);
    }
    get disablePdiField() {
      const {
        feedback
      } = this.args;
      return !feedback.user.get('id');
    }
    get disableActionPdiField() {
      const {
        feedback
      } = this.args;
      let pdiId = feedback.pdi.get('id');
      return !pdiId;
    }
    get disableDateField() {
      const {
        feedback
      } = this.args;
      let endDate = (0, _moment.default)(feedback.date).format('YYYY-MM-DD');
      let today = (0, _moment.default)(Date.now()).format('YYYY-MM-DD');
      let isDisable = false;
      if (endDate < today) {
        isDisable = true;
      }
      return isDisable;
    }
    get showCheckboxAgendaSchedule() {
      const {
        feedback
      } = this.args;
      const isShown = !feedback.employeeEvaluation.get('id') && (this.accessProfile.allowAccessSchedule || this.accessProfile.admin) && this.companyConfiguration.hasAgendaModule;
      return isShown;
    }
    *save() {
      try {
        const {
          afterSave,
          feedback,
          feedbackRequest
        } = this.args;
        if (feedbackRequest) {
          Ember.set(feedback, 'feedbackRequest', feedbackRequest);
        }
        if (feedback.generateAgendaSchedule) {
          const date = (0, _moment.default)(feedback.date).format('YYYY-MM-DD');
          if (!feedback.date) {
            return this.swal.warning('É necessário informar data de realização.');
          }
          if (date < (0, _moment.default)(Date.now()).format('YYYY-MM-DD')) {
            return this.swal.warning('A data de realização deve superior ou igual a data de hoje.');
          }
          if (!feedback.startTime || !feedback.endTime) {
            return this.swal.warning('É necessário informar o Horário de início e Horário de encerramento.');
          }
          if (feedback.startTime > feedback.endTime) {
            return this.swal.warning('O horário de encerramento deve superior ao horário de início.');
          }
        } else {
          feedback.startTime = null;
          feedback.endTime = null;
        }
        if (!feedback.validations.isValid) {
          return this.swal.validation(feedback.validations);
        }
        const created = yield feedback.save();
        if (feedback.get('draft')) {
          this.swal.success('O seu feedback foi salvo como rascunho, quando o mesmo estiver pronto você pode publicá-lo para que fique visível ao colaborador.');
        } else {
          this.swal.success('O Feedback foi salvo com sucesso.');
        }
        if (afterSave) {
          afterSave(created);
        }
        this.router.transitionTo('feedback.show', created.get('id'));
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    onInsertForm() {
      const {
        onLoadForm,
        feedback,
        feedbackRequest
      } = this.args;
      onLoadForm(this.save);
      if (feedback?.isNew) {
        Ember.set(feedback, 'type', '1');
      }
      if (!feedback.user.get('id') && feedbackRequest) {
        Ember.set(feedback, 'user', feedbackRequest.get('createdBy'));
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "userConfigs", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "accessProfile", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "companyConfiguration", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "disablePdiField", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "disablePdiField"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disableActionPdiField", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "disableActionPdiField"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disableDateField", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "disableDateField"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showCheckboxAgendaSchedule", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "showCheckboxAgendaSchedule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInsertForm", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "onInsertForm"), _class.prototype)), _class));
  _exports.default = FormComponent;
});