define("avaliacao-talentrh/pods/pdi/show/new-action/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        pdi: this.modelFor('pdi.show'),
        pdiAction: this.store.createRecord('pdi-action')
      });
    },
    actions: {
      onCloseModal() {
        let parentId = this.controller.get('model.pdi.id');
        this.transitionTo('pdi.show', parentId);
      }
    }
  });
  _exports.default = _default;
});