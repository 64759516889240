define("avaliacao-talentrh/models/feedback", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    user: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione um colaborador.'
    }),
    type: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Informe qual é o tipo do feedback.'
    }),
    objective: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Informe qual é o objetivo.'
    })
  });
  var _default = _model.default.extend(Validations, {
    type: (0, _model.attr)('string'),
    objective: (0, _model.attr)('string'),
    local: (0, _model.attr)('string'),
    annotations: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    status: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    approvalStatus: (0, _model.attr)('string'),
    draft: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    generateAgendaSchedule: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    annotationsFeedback: (0, _model.attr)('string'),
    improvePoints: (0, _model.attr)('string'),
    // Pontos à melhorar
    keepPoints: (0, _model.attr)('string'),
    // Pontos à manter
    suggestionOrImprovement: (0, _model.attr)('string'),
    // Sugestão/Melhoria
    finalFeedback: (0, _model.attr)('string'),
    // Feedback final

    date: (0, _model.attr)('date'),
    startTime: (0, _model.attr)('string'),
    endTime: (0, _model.attr)('string'),
    // BelongsTo
    pdi: (0, _model.belongsTo)('pdi'),
    user: (0, _model.belongsTo)('user'),
    company: (0, _model.belongsTo)('company'),
    leaderUser: (0, _model.belongsTo)('user'),
    pdiAction: (0, _model.belongsTo)('pdiAction'),
    feedbackRequest: (0, _model.belongsTo)('feedback-request'),
    employeeEvaluation: (0, _model.belongsTo)('employee-evaluation'),
    // HasMany
    followups: (0, _model.hasMany)('followup'),
    historic: (0, _model.hasMany)('feedback-historic'),
    // Computed
    typeBadge: Ember.computed('type', function () {
      let type = this.type;
      let badges = {
        0: {
          text: 'Corretivo',
          color: 'danger'
        },
        1: {
          text: 'Positivo',
          color: 'success'
        },
        2: {
          text: 'Tarefa',
          color: 'info'
        },
        3: {
          text: 'Outros',
          color: 'info'
        },
        4: {
          text: 'Geral',
          color: 'info'
        }
      };
      return badges[type] || {};
    }),
    approvalStatusBadge: Ember.computed('approvalStatus', function () {
      let type = this.approvalStatus;
      let badges = {
        'pending': {
          text: 'Pendente',
          color: 'warning'
        },
        'approved': {
          text: 'Aprovado',
          color: 'success'
        },
        'disapproved': {
          text: 'Reprovado',
          color: 'danger'
        }
      };
      return badges[type] || {};
    })
  });
  _exports.default = _default;
});