define("avaliacao-talentrh/pods/pde/show/new-action/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        pde: this.modelFor('pde.show'),
        pdeAction: this.store.createRecord('pde-action')
      });
    },
    actions: {
      onCloseModal() {
        let parentId = this.controller.get('model.pde.id');
        this.transitionTo('pde.show', parentId);
      }
    }
  });
});