define("avaliacao-talentrh/pods/indicators/pdi/pdi-progress/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    swal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    // Default values
    openModalFilter: false,
    filters: null,
    branchFilter: null,
    departmentFilter: null,
    jobFunctionFilter: null,
    jobRoleFilter: null,
    endDateFilter: null,
    startDateFilter: null,
    list: null,
    init() {
      this._super(...arguments);
    },
    // Methods
    // Carrega filtros gerais
    loadGeneralFilters() {
      if (this.generalFilters) {
        let startDate = this.generalFilters.startAt || null;
        let endDate = this.generalFilters.endAt || null;
        let departments = this.generalFilters.departments || [];
        let jobFunctions = this.generalFilters.jobFunctions || [];
        let jobRoles = this.generalFilters.jobRoles || [];
        let branchs = this.generalFilters.branchs || [];
        Ember.setProperties(this, {
          saveFilter: true,
          branchFilter: branchs,
          departmentFilter: departments,
          jobFunctionFilter: jobFunctions,
          jobRoleFilter: jobRoles,
          endDateFilter: endDate ? moment(endDate) : null,
          startDateFilter: startDate ? moment(startDate) : null
        });
      }
    },
    loadList() {
      if (this.pdiProgressList) {
        Ember.set(this, 'list', this.pdiProgressList);
      }
    },
    getFilters() {
      let filters = {};
      if (this.branchFilter) {
        filters.branchs = this.branchFilter.map(b => b.id);
      }
      if (this.departmentFilter) {
        filters.departments = this.departmentFilter.map(d => d.id);
      }
      if (this.jobFunctionFilter) {
        filters.jobFunctions = this.jobFunctionFilter.map(jf => jf.id);
      }
      if (this.jobRoleFilter) {
        filters.jobRoles = this.jobRoleFilter.map(jr => jr.id);
      }
      if (this.startDateFilter) {
        filters.startAt = moment(this.startDateFilter).format('YYYY-MM-DD');
      }
      if (this.endDateFilter) {
        filters.endAt = moment(this.endDateFilter).format('YYYY-MM-DD');
      }
      filters.ledType = 'a';
      Ember.set(this, 'filters', filters);
    },
    // Tasks
    getPdiProgress: (0, _emberConcurrency.task)(function* () {
      try {
        this.swal.loading("Buscando...");
        this.getFilters();
        const list = yield this.ajax.request('/pdiActions/findPdiProgress', {
          data: this.filters
        });
        Ember.set(this, 'pdiProgressList', list);
        this.swal.close();
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    // Actions
    actions: {
      generateIndicator() {
        if (!this.endDateFilter || !this.startDateFilter) {
          return this.swal.warning('Data Inicial e Data Final são obrigatórios');
        }
        if (this.endDateFilter && this.startDateFilter) {
          if (moment(this.endDateFilter).isBefore(this.startDateFilter)) {
            return this.swal.warning('A data inicial precisa ser igual ou anterior a data final.');
          }
        }
        Ember.set(this, 'useGeneralFiltersPdiProgress', false);
        this.getPdiProgress.perform();
        Ember.set(this, 'openModalFilter', false);
      },
      clearFilters() {
        Ember.setProperties(this, {
          branchFilter: null,
          departmentFilter: null,
          jobFunctionFilter: null,
          jobRoleFilter: null,
          endDateFilter: null,
          startDateFilter: null,
          filters: null
        });
      }
    }
  });
  _exports.default = _default;
});