define("avaliacao-talentrh/pods/new-evaluation/new/route", ["exports", "avaliacao-talentrh/utils/quiz"], function (_exports, _quiz) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model() {
      return this.store.createRecord('questionnaire');
    },
    actions: {
      save(model) {
        if ((0, _quiz.default)(model)) {
          model.save().then(data => {
            this.swal.success('Registro salvo com sucesso!');
            this.transitionTo('new-evaluation.edit', data.get('id'));
          }).catch(() => {
            this.swal.error('Não foi possível salvar o registro!');
          });
        }
      },
      willTransition() {
        const record = this.modelFor('new-evaluation.new');
        if (record.get('isNew')) {
          return record.destroyRecord();
        }
      }
    }
  });
  _exports.default = _default;
});