define("avaliacao-talentrh/pods/feedback/error/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    model(params, transition) {
      let errors = transition.to.queryParams.errors;
      if (errors) {
        return JSON.parse(errors);
      } else {
        return [];
      }
    }
  });
});