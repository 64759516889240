define("avaliacao-talentrh/pods/components/answer-evaluation/section-control/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2V04h7Py",
    "block": "{\"symbols\":[\"@nextSection\",\"@finish\",\"@prevSection\",\"@currentSection\",\"@sectionList\"],\"statements\":[[10,\"button\"],[14,0,\"btn btn-primary btn-sm\"],[15,\"disabled\",[34,1]],[15,\"onclick\",[30,[36,0],[[32,0],[32,3]],null]],[12],[2,\"\\n  Anterior\\n\"],[13],[2,\"\\n\\n\"],[10,\"span\"],[14,0,\"py-1 px-2 font-weight-bold text-primary\"],[12],[2,\"\\n  \"],[1,[30,[36,2],[[32,4],1],null]],[2,\"/\"],[1,[32,5,[\"length\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"button\"],[14,0,\"btn btn-primary btn-sm\"],[15,\"onclick\",[30,[36,0],[[32,0],[32,2]],null]],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-check-circle\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\" Finalizar\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"button\"],[14,0,\"btn btn-primary btn-sm\"],[15,\"onclick\",[30,[36,0],[[32,0],[32,1]],null]],[12],[2,\"\\n    Próximo\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"isFirstSection\",\"sum\",\"isLastSection\",\"if\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/components/answer-evaluation/section-control/template.hbs"
    }
  });
});