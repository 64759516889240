define("avaliacao-talentrh/pods/components/form-new-evaluation/component", ["exports", "ember-concurrency", "avaliacao-talentrh/utils/quiz", "jquery"], function (_exports, _emberConcurrency, _quiz, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    save: (0, _emberConcurrency.task)(function* () {
      if (!(0, _quiz.default)(this.model)) {
        return;
      }
      let message = this.model.isNew ? 'Questionário cadastrado' : 'Questionário atualizado';
      try {
        yield this.model.save();
        this.toast.success(message);
        this.router.transitionTo('new-evaluation');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    actions: {
      goto(index) {
        (0, _jquery.default)('html,body').animate({
          scrollTop: (0, _jquery.default)((0, _jquery.default)('.page')[index]).offset().top - 100
        }, 1000);
      }
    }
  });
});