define("avaliacao-talentrh/pods/home/info-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.LinkComponent.extend({
    classNames: ['card', 'ui-card', 'shadow-sm', 'mb-2']
  });
  _exports.default = _default;
});