define("avaliacao-talentrh/pods/manage-goals/update-achieve/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    beforeModel(transition) {
      const filters = this.controllerFor('manage-goals').get('filters');
      if (!filters || !filters.startAt || !filters.endAt) {
        transition.abort();
        this.transitionTo('manage-goals');
      }
    },
    model(params = {}) {
      return this.store.findRecord('user-goal', params['user_goal_id']).then(userGoal => {
        return {
          goalAchieved: this.store.createRecord('goal-achieved'),
          userGoal
        };
      });
    },
    deactivate() {
      this.controller.get('model.goalAchieved').rollbackAttributes();
      this.controllerFor('manage-goals').getGoals.perform();
    }
  });
});