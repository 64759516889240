define("avaliacao-talentrh/pods/evaluations/show/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    // Services
    userConfigs: Ember.inject.service(),
    // Default values
    evaluationType: null,
    // Alias
    evaluation: Ember.computed.alias('model'),
    sessionUserId: Ember.computed.alias('userConfigs.sessionUser.id'),
    showEvaluationComparison: Ember.computed.alias('userConfigs.companyConfiguration.showEvaluationComparison'),
    allowConsensusInEmployee180Evaluation: Ember.computed.alias('userConfigs.companyConfiguration.allowConsensusInEmployee180Evaluation'),
    // Computed Properties
    canViewResult: Ember.computed.and('evaluation.isAvailable', 'evaluation.isConcluded'),
    canRate: Ember.computed('evaluation.appraisedUser.id', 'evaluation.appraiserUser.id', 'evaluation.{appraisedConcluded,appraiserConcluded,id,isAvailable}', 'sessionUserId', function () {
      let appraiserId = Ember.get(this, 'evaluation.appraiserUser.id');
      let appraisedId = Ember.get(this, 'evaluation.appraisedUser.id');
      let appraiserConcluded = Ember.get(this, 'evaluation.appraiserConcluded');
      let appraisedConcluded = Ember.get(this, 'evaluation.appraisedConcluded');
      return this.evaluation.isAvailable && (!appraiserConcluded && appraiserId === this.sessionUserId || !appraisedConcluded && appraisedId === this.sessionUserId);
    }),
    canViewAnswer: Ember.computed('evaluation.appraisedUser.id', 'evaluation.{isAvailable,isConcluded}', 'showEvaluationComparison', function () {
      let appraisedId = Ember.get(this, 'evaluation.appraisedUser.id');
      return this.evaluation.isAvailable && this.evaluation.isConcluded && (this.showEvaluationComparison || this.userConfigs.iCanManageUser(appraisedId));
    }),
    canAddFeedback: Ember.computed('evaluation.appraiserUser.id', 'evaluation.{appraisedUser,isAvailable,isConcluded}', 'sessionUserId', function () {
      let appraiserId = Ember.get(this, 'evaluation.appraiserUser.id');
      return this.evaluation.isAvailable && this.evaluation.isConcluded && appraiserId === this.sessionUserId;
    }),
    canAnswerConsensus: Ember.computed('allowConsensusInEmployee180Evaluation', 'evaluation.appraiserUser.id', 'evaluation.{appraisedConcluded,appraiserConcluded,consensusConcluded}', 'evaluationType', 'sessionUserId', function () {
      const appraiserId = Ember.get(this, 'evaluation.appraiserUser.id');
      return this.evaluation.appraisedConcluded && this.evaluation.appraiserConcluded && !this.evaluation.consensusConcluded && this.evaluationType === 2 && this.allowConsensusInEmployee180Evaluation && appraiserId === this.sessionUserId;
    })
  });
});