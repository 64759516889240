define("avaliacao-talentrh/models/question", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    company: _emberData.default.belongsTo('company'),
    description: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    typeAnswer: _emberData.default.belongsTo('typeAnswer'),
    skill: _emberData.default.belongsTo('skill')
  });
});