define("avaliacao-talentrh/pods/pde/ability", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCan.Ability.extend({
    session: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    canManagePde: Ember.computed('userConfigs.isLoaded', 'pde.id', function () {
      let pde = this.pde;
      let userConfigs = this.userConfigs;
      if (!userConfigs || !pde) {
        return;
      }
      let {
        accessProfile,
        sessionUser,
        companyConfiguration,
        admin
      } = userConfigs;
      if (!accessProfile || !sessionUser || !companyConfiguration) {
        return;
      }
      return companyConfiguration.hasAVDModule && (accessProfile.generalManager || admin || this.session.user.id === parseInt(pde.get('owner.id')));
    }),
    canManageAction: Ember.computed('userConfigs.isLoaded', 'pde.{status,owner}', function () {
      let pde = this.pde;
      let userConfigs = this.userConfigs;
      if (!userConfigs || !pde) {
        return;
      }
      let {
        accessProfile,
        sessionUser,
        companyConfiguration,
        teamsICanManage,
        admin
      } = userConfigs;
      if (!accessProfile || !sessionUser || !companyConfiguration || !teamsICanManage) {
        return;
      }
      teamsICanManage = teamsICanManage.map(team => team.id);
      return companyConfiguration.hasAVDModule && pde.status !== 1 && (accessProfile.generalManager || admin || teamsICanManage.includes(parseInt(pde.get('team.id'))));
    })
  });
});