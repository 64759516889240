define("avaliacao-talentrh/pods/analytics/nine-box/cycle/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0gXVzifP",
    "block": "{\"symbols\":[],\"statements\":[[8,\"analytics/nine-box/cycle/form\",[],[[\"@nineBoxCycle\",\"@onXAxisSelected\",\"@onYAxisSelected\"],[[32,0,[\"model\"]],[32,0,[\"onXAxisSelected\"]],[32,0,[\"onYAxisSelected\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/analytics/nine-box/cycle/edit/template.hbs"
    }
  });
});