define("avaliacao-talentrh/models/feedback-request", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  const Validations = (0, _emberCpValidations.buildValidations)({
    description: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Descreva o motivo da sua solicitação.'
    }),
    user: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Informe para qual colaborador você deseja solicitar um Feedback.'
    })
  });
  var _default = Model.extend(Validations, {
    description: attr('string'),
    refusalReason: attr('string'),
    status: attr('string', {
      defaultValue: 'pending'
    }),
    createdAt: attr('date'),
    // Relationships
    pdi: belongsTo('pdi'),
    user: belongsTo('user'),
    createdBy: belongsTo('user'),
    // Computed
    statusBadge: Ember.computed('status', function () {
      let status = this.status;
      let badges = {
        'pending': {
          text: 'Pendente',
          color: 'warning'
        },
        'accepted': {
          text: 'Aceito',
          color: 'success'
        },
        'refused': {
          text: 'Recusado',
          color: 'danger'
        }
      };
      return badges[status] || {};
    })
  });
  _exports.default = _default;
});