define("avaliacao-talentrh/pods/reports/evaluation-team/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    deactivate() {
      if (this.controller) {
        Ember.set(this, 'controller.filters', {
          users: Ember.A(),
          departments: Ember.A(),
          startDate: null,
          endDate: null,
          evaluationType: "all"
        });
      }
    }
  });
  _exports.default = _default;
});