define("avaliacao-talentrh/adapters/application", ["exports", "@ember-data/adapter/rest", "avaliacao-talentrh/config/environment"], function (_exports, _rest, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _rest.default.extend({
    session: Ember.inject.service(),
    coalesceFindRequests: true,
    host: _environment.default.apiUrl.avd,
    headers: Ember.computed('session.{isAuthenticated,authorizationBearer}', function () {
      let headers = {};
      if (this.session.isAuthenticated) {
        headers = this.session.authorizationBearer;
      }
      return headers;
    }),
    handleResponse(status) {
      if (status === 401 && this.get('session.isAuthenticated')) {
        this.get('session').invalidate();
      }
      return this._super(...arguments);
    }
  });
});