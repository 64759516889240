define("avaliacao-talentrh/pods/evaluations/show/feedback/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EvaluationsShowFeedbackEditRoute extends Ember.Route {
    model() {
      const evaluation = this.modelFor('evaluations.show');
      return Ember.RSVP.hash({
        feedback: evaluation.feedback,
        evaluation: evaluation
      });
    }
  }
  _exports.default = EvaluationsShowFeedbackEditRoute;
});