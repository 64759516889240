define("avaliacao-talentrh/pods/application/sidebar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let SidebarComponent = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('userConfigs.accessProfile.allowManageGoals'), _dec3 = Ember.computed.alias('userConfigs.accessProfile.admin'), _dec4 = Ember.computed.or('allowManageGoals', 'admin'), _dec5 = Ember.computed('userConfigs.accessProfile.{admin,allowAvdRegistration,generalManager,profileRh}', 'userConfigs.sessionUser.superUser'), _dec6 = Ember.computed.filter('moduleOptions', function (item) {
    let environment = Ember.getOwner(this).factoryFor('config:environment').class;
    let companyConfig = this.userConfigs.companyConfig;
    if (!environment) {
      return true;
    }
    return item.modulePrefix !== environment.modulePrefix && (!item.validation || companyConfig && companyConfig.get(item.validation));
  }), (_class = class SidebarComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "userConfigs", _descriptor, this);
      _initializerDefineProperty(this, "allowManageGoals", _descriptor2, this);
      _initializerDefineProperty(this, "admin", _descriptor3, this);
      _initializerDefineProperty(this, "allowManageGoalsOrAdmin", _descriptor4, this);
      _defineProperty(this, "moduleOptions", Ember.A([{
        title: 'Agenda',
        type: 'agenda',
        modulePrefix: 'agenda'
      }, {
        title: 'Gestão de Desempenho',
        type: 'avd',
        modulePrefix: 'avaliacao-talentrh'
      }, {
        title: 'Educação',
        type: 'ted',
        modulePrefix: 'ted-ui',
        validation: 'hasTEDModule'
      }, {
        title: 'Painel do Administrador',
        type: 'conta',
        modulePrefix: 'account-talentrh'
      }, {
        title: 'Pesquisas',
        type: 'pesquisa',
        modulePrefix: 'pesquisa-talentrh'
      }, {
        title: 'Projetos',
        type: 'projetos',
        modulePrefix: 'gestor-projetos'
      }, {
        title: 'Social',
        type: 'social',
        modulePrefix: 'portal-talentrh'
      }]));
      _initializerDefineProperty(this, "modules", _descriptor5, this);
    }
    get canAccessAccountModule() {
      return this.userConfigs.sessionUser.superUser || this.userConfigs.accessProfile.admin || this.userConfigs.accessProfile.allowAvdRegistration || this.userConfigs.accessProfile.profileRh || this.userConfigs.accessProfile.generalManager || this.userConfigs.accessProfile.allowCreateGoals;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "userConfigs", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "allowManageGoals", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "admin", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "allowManageGoalsOrAdmin", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "canAccessAccountModule", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "canAccessAccountModule"), _class.prototype), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modules", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SidebarComponent;
});