define("avaliacao-talentrh/pods/pdi/show/show-action/rating-note/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML Attributes
    classNames: ['row mt-2'],
    // Services
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Default values
    ratingNoteConfig: null,
    ratings: Ember.A(),
    ratingSort: Ember.A(['order']),
    ratingsOrdered: Ember.computed.sort('ratings', 'ratingSort'),
    // Single line computed properties
    ratingNotePdiAction: Ember.computed.alias('pdiAction.ratingNotePdiAction'),
    userFromPDI: Ember.computed.alias('pdiAction.user'),
    // Multiline computed properties
    isRatingPoints: Ember.computed('ratingNoteConfig.type', function () {
      return this.ratingNoteConfig ? this.ratingNoteConfig.type === 'points' : false;
    }),
    isRatingPercent: Ember.computed('ratingNoteConfig.type', function () {
      return this.ratingNoteConfig ? this.ratingNoteConfig.type === 'percent' : false;
    }),
    resultRatingNote: Ember.computed('pdiAction', 'ratingNotePdiAction', 'ratings.[]', function () {
      let result = 'Não informado';
      let orderRatingNoteItem = Ember.get(this.ratingNotePdiAction, 'orderRatingNoteItem');
      const orderRating = this.ratings.findBy('order', orderRatingNoteItem);
      if (orderRating) {
        result = {
          title: orderRating.title,
          value: orderRating.value
        };
      }
      return result;
    }),
    ratingsLoaded: Ember.computed.reads('ratings'),
    // Life cycle hooks
    didInsertElement() {
      this.loadRatings.perform();
    },
    // Custom Methods
    updateRatings(totalRating) {
      let ratings = this.ratings;
      ratings = ratings.map(rt => {
        Ember.set(rt, 'selected', false);
        return rt;
      });
      ratings = ratings.map((rt, index) => {
        if (index <= totalRating) {
          Ember.set(rt, 'selected', !rt.selected);
        }
        return rt;
      });
      Ember.set(this, 'ratings', ratings);
    },
    // Tasks
    loadRatings: (0, _emberConcurrency.task)(function* () {
      try {
        const pdiStatus = yield Ember.get(this.pdiAction, 'pdi.status');
        const ratingNoteOld = yield Ember.get(this.pdiAction, 'ratingNotePdiAction.ratingNote.id');
        let ratingNoteConfig;
        let ratings;

        // Só vai buscar na configuração da empresa, caso o PDI não esteja finalizado
        if (!pdiStatus) {
          ratingNoteConfig = yield this.store.queryRecord('rating-note', {
            company: this.session.user.company,
            active: 1
          });
          ratings = ratingNoteConfig.ratings.sortBy('order').filterBy('active', true);
          Ember.set(this, 'ratingNoteConfig', ratingNoteConfig);
          Ember.set(this, 'ratings', ratings);
        } else {
          if (ratingNoteOld) {
            ratingNoteConfig = yield this.store.queryRecord('rating-note', {
              id: ratingNoteOld
            });
            ratings = ratingNoteConfig.ratings.sortBy('order').filterBy('active', true);
            Ember.set(this, 'ratingNoteConfig', ratingNoteConfig);
            Ember.set(this, 'ratings', ratings);
          }
        }
        yield this.onRatingSelect.perform(null, null);
      } catch (e) {
        return this.swal.catch(e);
      }
    }),
    onRatingSelect: (0, _emberConcurrency.task)(function* (rating, indexSelected) {
      try {
        const pdiCreatedAt = yield this.pdiAction.pdi.get('createdAt');
        let orderRatingNoteItem = yield Ember.get(this.ratingNotePdiAction, 'orderRatingNoteItem');
        let totalRating = indexSelected ? indexSelected : orderRatingNoteItem ? orderRatingNoteItem - 1 : -1;
        if (orderRatingNoteItem && [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(indexSelected)) {
          let {
            value
          } = yield this.swal.confirm('Confirmar alteração da avaliação atual?');
          if (!value) {
            return;
          }
          Ember.setProperties(this.ratingNotePdiAction, {
            orderRatingNoteItem: rating.order,
            pdiActionNote: rating.value,
            pdiAction: this.pdiAction,
            ratingNote: this.ratingNoteConfig
          });
          const ratingNotePdiAction = yield this.store.peekRecord('rating-note-pdi-action', this.ratingNotePdiAction.get('id'));
          yield ratingNotePdiAction.save();
          Ember.set(this, 'pdiAction.partialScore', ratingNotePdiAction.pdiActionNote * this.pdiAction.percentage / 100);
          yield this.pdiAction.save();
          this.pdiAction.reload();
          this.toast.success('Avaliação atualizada');
        } else if (rating) {
          let {
            value
          } = yield this.swal.confirm('Deseja avaliar agora?');
          if (!value) {
            return;
          }
          let ratingNotePdiAction = yield this.store.createRecord('rating-note-pdi-action', {
            orderRatingNoteItem: rating.order,
            pdiActionNote: rating.value,
            pdiAction: this.pdiAction,
            ratingNote: this.ratingNoteConfig
          });
          ratingNotePdiAction = yield ratingNotePdiAction.save();
          Ember.set(this, 'pdiAction.partialScore', ratingNotePdiAction.pdiActionNote * this.pdiAction.percentage / 100);
          Ember.set(this, 'pdiAction.ratingNotePdiAction', ratingNotePdiAction);
          yield this.pdiAction.save();
          this.pdiAction.reload();
          ratingNotePdiAction.reload();
          this.toast.success('Avaliação realizada');
        }
        orderRatingNoteItem = Ember.get(this.ratingNotePdiAction, 'orderRatingNoteItem');
        totalRating = indexSelected ? indexSelected : orderRatingNoteItem ? orderRatingNoteItem - 1 : -1;
        this.updateRatings(totalRating);

        // Atualiza informações da meta
        const emailUserPDI = Ember.get(this, 'userFromPDI.email');
        if (!emailUserPDI) {
          Ember.debug('Não foi possível atualizar a meta do usuário. O mesmo não possuí e-mail no seu cadastro.');
          return;
        }
        yield this.ajax.request('/update-rating-note-in-goal', {
          data: {
            email: emailUserPDI,
            pdiCreatedAt
          }
        });
      } catch (e) {
        return this.swal.catch(e);
      }
    })
  });
  _exports.default = _default;
});