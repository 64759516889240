define("avaliacao-talentrh/pods/evaluation/answer-consensus/notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "07X5jGv4",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-notes\",[],[[\"@user\",\"@route\"],[[32,0,[\"appraisedUser\"]],\"evaluation.answer-consensus\"]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/evaluation/answer-consensus/notes/template.hbs"
    }
  });
  _exports.default = _default;
});