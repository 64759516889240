define("avaliacao-talentrh/pods/components/chart-performance-by-question/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['card', 'mb-4'],
    didInsertElement() {
      this.buildChart();
    },
    buildChart() {
      let data = this.data;
      let chart = this.chart;
      if (chart) {
        chart = this.chart;
        chart.data = data;
        chart.update();
      } else {
        let ctx = this.$('canvas')[0];
        chart = new Chart(ctx, {
          type: 'bar',
          responsive: true,
          maintainAspectRatio: false,
          data: data,
          options: {
            scales: {
              yAxes: [{
                ticks: {
                  min: 0,
                  max: 100,
                  stepSize: 10
                }
              }]
            }
          }
        });
      }
      this.set('chart', chart);
    }
  });
});