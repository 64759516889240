define("avaliacao-talentrh/pods/indicators/collaborator-evolution/item/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    swal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    showJobFunction: Ember.computed.alias('userConfigs.companyConfiguration.showJobFunction'),
    onlyAppraiserAnswers: Ember.computed.alias('userConfigs.companyConfiguration.onlyAppraiserAnswers'),
    jobRoleUser: null,
    reviewDateList: [],
    reviewTotalPercentageList: [],
    reviewValue: null,
    typeDescriptions: [],
    chart: null,
    jobRoleTitle: null,
    jobFunctionTitle: null,
    dataSets: [],
    employeeEvaluationList: [],
    didRender() {
      this._super(...arguments);
      this.buildData.perform();
    },
    buildChart() {
      let listEmployeeAux = null;
      if (this.employeeEvaluationList && this.employeeEvaluationList.length) {
        listEmployeeAux = Object.assign([], this.employeeEvaluationList);
      }
      let listTypeDescriptions = this.typeDescriptions;
      let onlyAppraiserAnswers = this.onlyAppraiserAnswers;
      let lastMonth = null;
      let firstMonth = true;
      let showJobFunction = this.showJobFunction;
      let data = {
        datasets: this.dataSets
      };
      if (this.chart) {
        this.chart.destroy();
      }
      let ctx = document.getElementById('chart').getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'line',
        responsive: true,
        data: data,
        options: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              type: 'time',
              time: {
                unit: 'month'
              },
              distribution: 'series',
              autoSkip: true,
              ticks: {
                callback: function (value) {
                  var monthSlipt = value.split(" ");
                  if (monthSlipt && monthSlipt.length > 0) {
                    var result;
                    switch (monthSlipt[0]) {
                      case 'Jan':
                        result = 'Jan';
                        break;
                      case 'Feb':
                        result = 'Fev';
                        break;
                      case 'Mar':
                        result = 'Mar';
                        break;
                      case 'Apr':
                        result = 'Abr';
                        break;
                      case 'May':
                        result = 'Mai';
                        break;
                      case 'Jun':
                        result = 'Jun';
                        break;
                      case 'Jul':
                        result = 'Jul';
                        break;
                      case 'Aug':
                        result = 'Ago';
                        break;
                      case 'Sep':
                        result = 'Set';
                        break;
                      case 'Oct':
                        result = 'Out';
                        break;
                      case 'Nov':
                        result = 'Nov';
                        break;
                      case 'Dec':
                        result = 'Dez';
                        break;
                    }
                    if (lastMonth && lastMonth === result) {
                      return '';
                    }
                    if (!firstMonth) {
                      lastMonth = result;
                    }
                    firstMonth = false;
                    result = result + ' ' + monthSlipt[1];
                    return result;
                  } else {
                    return '';
                  }
                }
              }
            }],
            yAxes: [{
              ticks: {
                min: 0,
                max: this.reviewValue ? this.reviewValue : 100,
                callback: function (value) {
                  return value + '%';
                }
              }
            }]
          },
          tooltips: {
            custom: function (tooltip) {
              if (!tooltip) return;
              tooltip.displayColors = false;
            },
            callbacks: {
              title: function () {},
              label: function (tooltipItem) {
                let listEmployee = listEmployeeAux[tooltipItem.datasetIndex];
                let roleOrFunction = 'Cargo: ';
                let valueRoleOrFunction = listEmployee[tooltipItem.index].jobRoleName;
                if (showJobFunction) {
                  roleOrFunction = 'Função: ';
                  valueRoleOrFunction = listEmployee[tooltipItem.index].jobFunctionName;
                }
                if (listEmployee[tooltipItem.index].consensusTotal) {
                  return ['Nome: ' + listEmployee[tooltipItem.index].userName, roleOrFunction + valueRoleOrFunction, listTypeDescriptions[tooltipItem.index], 'Data: ' + moment(listEmployee[tooltipItem.index].createdAt).format('DD/MM/YYYY'), 'Nota avaliador: ' + listEmployee[tooltipItem.index].appraiserTotal.toFixed(2) + '%', 'Nota avaliado: ' + listEmployee[tooltipItem.index].appraisedTotal.toFixed(2) + '%', 'Nota do consenso: ' + listEmployee[tooltipItem.index].consensusTotal.toFixed(2) + '%', 'Nota Final: ' + listEmployee[tooltipItem.index].consensusTotal.toFixed(2) + '%'];
                } else if (listEmployee[tooltipItem.index].averageTeam) {
                  let averageTotal = (listEmployee[tooltipItem.index].appraiserTotal + listEmployee[tooltipItem.index].appraisedTotal + listEmployee[tooltipItem.index].averageTeam) / 3;
                  return ['Nome: ' + listEmployee[tooltipItem.index].userName, roleOrFunction + valueRoleOrFunction, listTypeDescriptions[tooltipItem.index], 'Data: ' + moment(listEmployee[tooltipItem.index].createdAt).format('DD/MM/YYYY'), 'Nota avaliador: ' + listEmployee[tooltipItem.index].appraiserTotal.toFixed(2) + '%', 'Nota avaliado: ' + listEmployee[tooltipItem.index].appraisedTotal.toFixed(2) + '%', 'Nota da equipe: ' + listEmployee[tooltipItem.index].averageTeam.toFixed(2) + '%', 'Nota Final: ' + averageTotal.toFixed(2) + '%'];
                } else {
                  if (onlyAppraiserAnswers) {
                    return ['Nome: ' + listEmployee[tooltipItem.index].userName, roleOrFunction + valueRoleOrFunction, listTypeDescriptions[tooltipItem.index], 'Data: ' + moment(listEmployee[tooltipItem.index].createdAt).format('DD/MM/YYYY'), 'Nota Final: ' + listEmployee[tooltipItem.index].appraiserTotal.toFixed(2) + '%'];
                  } else {
                    return ['Nome: ' + listEmployee[tooltipItem.index].userName, roleOrFunction + valueRoleOrFunction, listTypeDescriptions[tooltipItem.index], 'Data: ' + moment(listEmployee[tooltipItem.index].createdAt).format('DD/MM/YYYY'), 'Nota avaliador: ' + listEmployee[tooltipItem.index].appraiserTotal.toFixed(2) + '%', 'Nota avaliado: ' + listEmployee[tooltipItem.index].appraisedTotal.toFixed(2) + '%', 'Nota Final: ' + listEmployee[tooltipItem.index].finalTotal.toFixed(2) + '%'];
                  }
                }
              }
            }
          }
        }
      });
    },
    typeReviewFormat(type) {
      switch (type) {
        case 1:
          return 'Primeira Avaliação';
        case 2:
          return 'Avaliação de Efetivação';
        case 3:
          return 'Avaliação de Função';
        case 4:
          return 'Avaliação de Skills';
      }
    },
    buildData: (0, _emberConcurrency.task)(function* () {
      this.reviewDateList = [];
      this.reviewTotalPercentageList = [];
      this.typeDescriptions = [];
      this.employeeEvaluationList = [];
      this.dataSets = [];
      this.reviewValue = null;
      let reviewValueAux = null;
      let reviewDateListAux = [];
      let userEmployeeEvaluations = this.listUserEmployee && this.listUserEmployee.users ? this.listUserEmployee.users : null;
      if (userEmployeeEvaluations && userEmployeeEvaluations.length) {
        for (const userEvaluation of userEmployeeEvaluations) {
          let datasetAux = [];
          if (userEvaluation.employeeEvaluations && userEvaluation.employeeEvaluations.length) {
            let employeeEvaluationList = Object.assign([], userEvaluation.employeeEvaluations).reverse();
            let reviewTotalPercentageList = [];
            let employeeEvaluationListAux = [];
            for (const employeeEvaluation of employeeEvaluationList) {
              this.reviewDateList.push(new Date(employeeEvaluation.createdAt));
              let value = employeeEvaluation.consensusTotal ? employeeEvaluation.consensusTotal : employeeEvaluation.finalTotal;
              if (this.onlyAppraiserAnswers) {
                if (!employeeEvaluation.consensusTotal && (!userEmployeeEvaluations[0].evaluationTeams || !userEmployeeEvaluations[0].evaluationTeams.length)) {
                  value = employeeEvaluation.appraiserTotal;
                  reviewTotalPercentageList.push(value);
                } else {
                  reviewTotalPercentageList.push(value);
                }
              } else {
                reviewTotalPercentageList.push(value);
              }
              if (reviewValueAux < value) {
                reviewValueAux = value.toFixed(2);
              }
              this.typeDescriptions.push(this.typeReviewFormat(employeeEvaluation.type));
              if (userEvaluation.evaluationTeams && userEvaluation.evaluationTeams.length) {
                for (const employeeTeamEvaluation of userEvaluation.evaluationTeams) {
                  if (employeeTeamEvaluation.employeeEvaluation === employeeEvaluation.id) {
                    employeeEvaluation.averageTeam = employeeTeamEvaluation.appraiserTotal;
                  }
                }
              }
              employeeEvaluation.userName = userEvaluation.firstName + " " + userEvaluation.lastName;
              employeeEvaluation.jobRoleName = userEvaluation.jobRoleName ? userEvaluation.jobRoleName : 'Usuário sem Cargo';
              employeeEvaluation.jobFunctionName = userEvaluation.jobFunctionName ? userEvaluation.jobFunctionName : 'Usuário sem Função';
              employeeEvaluationListAux.push(employeeEvaluation);
              datasetAux.push({
                x: new Date(employeeEvaluation.createdAt),
                y: value
              });
            }
            this.employeeEvaluationList.push(employeeEvaluationListAux);
            let r = Math.random() * 255;
            let g = Math.random() * 255;
            let b = Math.random() * 255;
            this.dataSets.push({
              label: this.typeReviewFormat(employeeEvaluationList[0].type),
              data: datasetAux,
              borderColor: `rgb(${r}, ${g}, ${b})`,
              fill: false
            });
          }
        }
        this.reviewValue = Math.ceil(reviewValueAux);
        reviewDateListAux = [];
        this.reviewDateList.sort(function (a, b) {
          return a.getTime() - b.getTime();
        });
        this.reviewDateList = reviewDateListAux;
        yield this.buildChart();
      } else {
        yield this.buildChart();
      }
    }).drop()
  });
  _exports.default = _default;
});