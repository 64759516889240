define("avaliacao-talentrh/pods/pdi/show/show-action/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    // Services
    swal: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    router: Ember.inject.service(),
    // Computed
    isConcluded: Ember.computed.equal('model.status', 1),
    canManagePdiAction: Ember.computed('model.user.id', function () {
      const model = this.model;
      const userConfigs = this.userConfigs;
      if (!userConfigs || !model) {
        return;
      }
      return userConfigs.iCanManageUser(model.get('user.id'));
    }),
    canEditPdiAction: Ember.computed('model.createdBy.id', 'model.user.id', function () {
      if (this.model.createdBy.get('id') === this.model.user.get('id')) {
        return true;
      }
      return false;
    }),
    // Concurrency Tasks
    toggleStatus: (0, _emberConcurrency.task)(function* (record) {
      let status = record.status || 0;
      try {
        let partialScore = record.get('partialScore');
        if (!partialScore && partialScore !== 0) {
          return this.swal.warning('Avalie a ação antes de finalizá-la');
        }
        Ember.set(record, 'status', Number(status === 0));
        yield record.save();
        this.swal.success('Salvo com sucesso');
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    deletePdiAction: (0, _emberConcurrency.task)(function* (record) {
      try {
        let result = yield this.swal.confirm('Tem certeza que deseja remover esta Ação?');
        if (!result.value) {
          return;
        }
        yield record.destroyRecord();
        this.swal.success('Removido com sucesso.');
        this.transitionToRoute('pdi.show.percentage-action', {
          queryParams: {
            pdiActionDelete: true
          }
        });
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    actions: {
      onCloseModal() {
        let parentId = Ember.get(this, 'model.pdi.id');
        this.router.transitionTo('pdi.show', parentId);
      }
    }
  });
});