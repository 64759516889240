define("avaliacao-talentrh/pods/evaluations/show/feedback/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EvaluationsShowFeedbackNewRoute extends Ember.Route {
    model() {
      const evaluation = this.modelFor('evaluations.show');
      return Ember.RSVP.hash({
        feedback: this.store.createRecord('feedback', {
          leaderUser: evaluation.appraiserUser,
          user: evaluation.appraisedUser,
          type: '1',
          status: 0,
          objective: evaluation.typeDescription,
          approvalStatus: 'pending',
          employeeEvaluation: evaluation,
          draft: 1
        }),
        evaluation: evaluation
      });
    }
  }
  _exports.default = EvaluationsShowFeedbackNewRoute;
});