define("avaliacao-talentrh/pods/evaluation/answer/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    // Services
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    // Lifecycle hooks
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model({
      id
    }, transition) {
      let {
        answerFor
      } = transition.to.queryParams;
      return Ember.RSVP.hash({
        employeeEvaluation: this.store.findRecord('employee-evaluation', id, {
          reload: true
        }),
        answerFor
      });
    },
    afterModel(model) {
      const {
        employeeEvaluation
      } = model;
      const {
        appraisedConcluded,
        appraiserConcluded
      } = employeeEvaluation;
      const sessionUserId = this.userConfigs?.sessionUser?.id;
      const isAppraiser = employeeEvaluation.appraiserUser.get('id') === sessionUserId;
      const isAppraised = employeeEvaluation.appraisedUser.get('id') === sessionUserId;
      if (employeeEvaluation.canceled) {
        this.swal.error('Esta avaliação foi cancelada');
        return this.replaceWith('/');
      }
      if (isAppraised && isAppraiser && !appraiserConcluded) {
        return;
      }
      if (appraiserConcluded && isAppraiser || appraisedConcluded && isAppraised) {
        return this.replaceWith('/evaluation/answer-forbidden');
      }
    }
  });
});