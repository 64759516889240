define("avaliacao-talentrh/pods/errors/forbidden/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+jyXjM/J",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"section-body contain-lg\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-lg-12 text-center\"],[12],[2,\"\\n        \"],[10,\"h1\"],[14,0,\"text-black-50\"],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"fa fa-meh-o\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"h2\"],[14,0,\"text-black-50\"],[12],[2,\"Ops! Você não tem autorização para ver esta página.\"],[13],[2,\"\\n        \"],[10,\"h3\"],[14,0,\"text-black-50\"],[12],[2,\"Fale com um administrador do sistema.\"],[13],[2,\"\\n        \"],[8,\"link-to\",[],[[\"@route\"],[\"home\"]],[[\"default\"],[{\"statements\":[[10,\"button\"],[14,0,\"btn btn-primary\"],[12],[2,\"Voltar\"],[13]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/errors/forbidden/template.hbs"
    }
  });
});