define("avaliacao-talentrh/models/pdi-action", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* globals moment */

  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Informe um título.'
    }),
    expirationDate: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Informe a data de expiração.'
    })
  });
  var _default = _model.default.extend(Validations, {
    // Attr
    title: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    expirationDate: (0, _model.attr)('date'),
    createdAt: (0, _model.attr)('date'),
    status: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    percentage: (0, _model.attr)('number'),
    partialScore: (0, _model.attr)('number'),
    // BelongsTo
    createdBy: (0, _model.belongsTo)('user'),
    company: (0, _model.belongsTo)('company'),
    pdi: (0, _model.belongsTo)('pdi'),
    skill: (0, _model.belongsTo)('skill'),
    user: (0, _model.belongsTo)('user'),
    ratingNotePdiAction: (0, _model.belongsTo)('rating-note-pdi-action'),
    // HasMany
    evidences: (0, _model.hasMany)('pdi-evidence'),
    // Computed
    isExpired: Ember.computed('expirationDate', function () {
      return moment().startOf('day').isAfter(this.expirationDate);
    }),
    statusBadge: Ember.computed('status', 'isExpired', function () {
      let status = this.status;
      let badges = {
        0: {
          text: 'Aberto',
          color: 'info'
        },
        1: {
          text: 'Finalizado',
          color: 'success'
        },
        2: {
          text: 'Expirado',
          color: 'warning'
        }
      };
      if (this.isExpired && this.status !== 1) {
        status = 2;
      }
      return badges[status] || {};
    })
  });
  _exports.default = _default;
});