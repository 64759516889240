define("avaliacao-talentrh/pods/manage-goals/goal-evolution/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    store: Ember.inject.service(),
    showAddGoalAchievedRow: false,
    goalAchievesSort: Ember.A(['achieveDate:desc']),
    goalAchieved: Ember.computed.alias('model.goalAchieved'),
    userGoal: Ember.computed.alias('model.userGoal'),
    goal: Ember.computed.alias('model.userGoal.goal'),
    goalAchieves: Ember.computed.alias('model.userGoal.goalsAchieved'),
    goalAchievesOrdered: Ember.computed.sort('goalAchieves', 'goalAchievesSort'),
    actions: {
      onCloseModal() {
        this.router.transitionTo('manage-goals');
      },
      showAddGoalAchievedInputs() {
        Ember.set(this, 'showAddGoalAchievedRow', true);
      },
      closeAddGoalAchieved() {
        Ember.set(this, 'goalAchieved.achieveDate', null);
        Ember.set(this, 'goalAchieved.value', null);
        Ember.set(this, 'goalAchieved.comment', null);
        Ember.set(this, 'showAddGoalAchievedRow', false);
      }
    }
  });
});