define("avaliacao-talentrh/pods/components/nine-box/arrow/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lNAvhOjK",
    "block": "{\"symbols\":[\"@description\",\"@upArrow\"],\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"nine-box/arrow/up-arrow\",[],[[\"@description\",\"@canSeeSettings\"],[[32,1],[32,0,[\"canSeeSettings\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"nine-box/arrow/right-arrow\",[],[[\"@description\",\"@canSeeSettings\"],[[32,1],[32,0,[\"canSeeSettings\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/components/nine-box/arrow/template.hbs"
    }
  });
  _exports.default = _default;
});