define("avaliacao-talentrh/pods/application/sidebar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let SidebarComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('userConfigs.accessProfile.allowManageGoals'), _dec3 = Ember.computed.alias('userConfigs.accessProfile.admin'), _dec4 = Ember.computed.or('allowManageGoals', 'admin'), _dec5 = Ember.computed('userConfigs.accessProfile.{admin,allowAvdRegistration,generalManager,profileRh}', 'userConfigs.sessionUser.superUser'), _dec6 = Ember.computed.filter('moduleOptions', function (item) {
    let environment = Ember.getOwner(this).factoryFor('config:environment').class;
    let companyConfig = this.userConfigs.companyConfig;
    if (!environment) {
      return true;
    }
    return item.modulePrefix !== environment.modulePrefix && (!item.validation || companyConfig && companyConfig.get(item.validation));
  }), _class = class SidebarComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "userConfigs", _descriptor, this);
      _initializerDefineProperty(this, "allowManageGoals", _descriptor2, this);
      _initializerDefineProperty(this, "admin", _descriptor3, this);
      _initializerDefineProperty(this, "allowManageGoalsOrAdmin", _descriptor4, this);
      _defineProperty(this, "moduleOptions", Ember.A([{
        title: 'Agenda',
        type: 'agenda',
        modulePrefix: 'agenda'
      }, {
        title: 'Gestão de Desempenho',
        type: 'avd',
        modulePrefix: 'avaliacao-talentrh'
      }, {
        title: 'Educação',
        type: 'ted',
        modulePrefix: 'ted-ui',
        validation: 'hasTEDModule'
      }, {
        title: 'Painel do Administrador',
        type: 'conta',
        modulePrefix: 'account-talentrh'
      }, {
        title: 'Pesquisas',
        type: 'pesquisa',
        modulePrefix: 'pesquisa-talentrh'
      }, {
        title: 'Projetos',
        type: 'projetos',
        modulePrefix: 'gestor-projetos'
      }, {
        title: 'Social',
        type: 'social',
        modulePrefix: 'portal-talentrh'
      }]));
      _initializerDefineProperty(this, "modules", _descriptor5, this);
    }
    get canAccessAccountModule() {
      return this.userConfigs.sessionUser.superUser || this.userConfigs.accessProfile.admin || this.userConfigs.accessProfile.allowAvdRegistration || this.userConfigs.accessProfile.profileRh || this.userConfigs.accessProfile.generalManager || this.userConfigs.accessProfile.allowCreateGoals;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "userConfigs", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "allowManageGoals", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "admin", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "allowManageGoalsOrAdmin", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "canAccessAccountModule", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "canAccessAccountModule"), _class.prototype), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modules", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});