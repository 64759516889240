define("avaliacao-talentrh/pods/home/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    userCookie: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model() {
      return Ember.RSVP.hash({
        aboutMe: this.ajax.request('home/aboutMe'),
        appraisedByMe: this.ajax.request('home/appraisedByMe'),
        evaluationsPending: this.ajax.request('home/evaluationsPending')
      });
    },
    async afterModel() {
      await this.userCookie.handle();
    }
  });
});