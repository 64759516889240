define("avaliacao-talentrh/helpers/app-url-conta", ["exports", "avaliacao-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.appUrlConta = appUrlConta;
  _exports.default = void 0;
  function appUrlConta(params /*, hash*/) {
    return _environment.default.appUrl.conta + params[0];
  }
  var _default = _exports.default = Ember.Helper.helper(appUrlConta);
});