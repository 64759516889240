define("avaliacao-talentrh/pods/indicators/pdi/pdi-evidence-period/component", ["exports", "ember-concurrency", "chart-js"], function (_exports, _emberConcurrency, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    swal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    // Default values
    openModalFilter: false,
    filters: null,
    branchFilter: null,
    departmentFilter: null,
    jobFunctionFilter: null,
    jobRoleFilter: null,
    endDateFilter: null,
    startDateFilter: null,
    didInsertElement() {
      this._super(...arguments);
      // this.loadGeneralFilters();
      // this.loadData();
    },
    didUpdate() {
      this._super(...arguments);
      // this.loadGeneralFilters();
      this.loadData();
    },
    // Methods
    // Carrega filtros gerais
    loadGeneralFilters() {
      if (this.generalFilters) {
        let startDate = this.generalFilters.startAt || null;
        let endDate = this.generalFilters.endAt || null;
        let departments = this.generalFilters.departments || [];
        let jobFunctions = this.generalFilters.jobFunctions || [];
        let jobRoles = this.generalFilters.jobRoles || [];
        let branchs = this.generalFilters.branchs || [];
        Ember.setProperties(this, {
          saveFilter: true,
          branchFilter: branchs,
          departmentFilter: departments,
          jobFunctionFilter: jobFunctions,
          jobRoleFilter: jobRoles,
          endDateFilter: endDate ? moment(endDate) : null,
          startDateFilter: startDate ? moment(startDate) : null
        });
      }
    },
    loadData() {
      if (this.pdiEvidencesData) {
        this.initChart();
      }
    },
    getFilters() {
      let filters = {};
      if (this.branchFilter) {
        filters.branchs = this.branchFilter.map(b => b.id);
      }
      if (this.departmentFilter) {
        filters.departments = this.departmentFilter.map(d => d.id);
      }
      if (this.jobFunctionFilter) {
        filters.jobFunctions = this.jobFunctionFilter.map(jf => jf.id);
      }
      if (this.jobRoleFilter) {
        filters.jobRoles = this.jobRoleFilter.map(jr => jr.id);
      }
      if (this.startDateFilter) {
        filters.startAt = moment(this.startDateFilter).format('YYYY-MM-DD');
      }
      if (this.endDateFilter) {
        filters.endAt = moment(this.endDateFilter).format('YYYY-MM-DD');
      }
      Ember.set(this, 'filters', filters);
    },
    // Tasks
    getPdiEvidencesPeriod: (0, _emberConcurrency.task)(function* () {
      try {
        this.swal.loading("Buscando...");
        this.getFilters();
        const data = yield this.ajax.request('/pdievidences/findPdiEvidencesPeriod', {
          data: this.filters
        });
        Ember.set(this, 'pdiEvidencesData', data);
        this.initChart();
        this.swal.close();
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    initChart() {
      let chart = this.chart;
      let chartData = {
        labels: this.pdiEvidencesData.months,
        datasets: [{
          label: 'Evidências',
          data: this.pdiEvidencesData.values,
          backgroundColor: ['rgba(82, 180, 254)', 'rgba(82, 180, 254)', 'rgba(82, 180, 254)', 'rgba(82, 180, 254)', 'rgba(82, 180, 254)', 'rgba(82, 180, 254)', 'rgba(82, 180, 254)', 'rgba(82, 180, 254)', 'rgba(82, 180, 254)', 'rgba(82, 180, 254)', 'rgba(82, 180, 254)', 'rgba(82, 180, 254)'],
          borderWidth: 1
        }]
      };
      let options = {
        scales: {
          yAxes: [{
            ticks: {
              min: 0,
              max: Math.max.apply(null, this.pdiEvidencesData.values),
              stepSize: 10
            }
          }]
        }
      };
      if (chart) {
        chart.data = chartData;
        chart.options = options;
        chart.update();
      } else {
        let ctx = document.getElementById('pdiEvidencePeriodChart').getContext('2d');
        chart = new _chartJs.default(ctx, {
          type: 'bar',
          responsive: true,
          maintainAspectRatio: false,
          data: chartData,
          options
        });
      }
      this.set('chart', chart);
    },
    // Actions
    actions: {
      generateChart() {
        if (!this.endDateFilter || !this.startDateFilter) {
          return this.swal.warning('Data Inicial e Data Final são obrigatórios');
        }
        if (this.endDateFilter && this.startDateFilter) {
          if (moment(this.endDateFilter).isBefore(this.startDateFilter)) {
            return this.swal.warning('A data inicial precisa ser igual ou anterior a data final.');
          }
        }
        Ember.set(this, 'useGeneralFiltersPdiEvidencePeriod', false);
        this.getPdiEvidencesPeriod.perform();
        Ember.set(this, 'openModalFilter', false);
      },
      clearFilters() {
        Ember.setProperties(this, {
          branchFilter: null,
          departmentFilter: null,
          jobFunctionFilter: null,
          jobRoleFilter: null,
          endDateFilter: null,
          startDateFilter: null,
          filters: null
        });
      }
    }
  });
});