define("avaliacao-talentrh/pods/pdi/show/edit-action/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qV8Y/qJm",
    "block": "{\"symbols\":[\"Modal\",\"Form\",\"Footer\"],\"statements\":[[8,\"ui-modal\",[[24,\"data-backdrop\",\"static\"]],[[\"@open\",\"@modalSize\",\"@onCloseModal\"],[true,\"modal-lg\",[30,[36,0],[\"onCloseModal\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Editar Ação de PDI\"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"ui-form-renderer\",[],[[\"@formName\"],[\"pdi/form-action\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"content\"]],[],[[\"@model\",\"@pdi\"],[[32,0,[\"model\",\"pdiAction\"]],[32,0,[\"model\",\"pdi\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,3,[\"cancel\"]],[],[[],[]],null],[2,\"\\n      \"],[8,[32,2,[\"submit\"]],[],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/pdi/show/edit-action/template.hbs"
    }
  });
});