define("avaliacao-talentrh/pods/evaluation/answer-forbidden/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "HZDs7DkJ",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[10,\"div\"],[14,0,\"container mt-5 pt-5\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row justify-content-center pt-5\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-8\"],[12],[2,\"\\n      \"],[8,\"ui-card\",[[24,0,\"shadow-sm\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"body\"]],[[24,0,\"text-center\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[10,\"h3\"],[12],[1,[35,0,[\"title\"]]],[13],[2,\"\\n          \"],[10,\"h6\"],[12],[1,[35,0,[\"subtitle\"]]],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/evaluation/answer-forbidden/template.hbs"
    }
  });
  _exports.default = _default;
});