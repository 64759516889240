define("avaliacao-talentrh/pods/evaluations/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    userConfigs: Ember.inject.service(),
    sessionUser: Ember.computed.alias('userConfigs.sessionUser'),
    canCreateEvaluation: Ember.computed('userConfigs.accessProfile.{admin,generalManager}', 'userConfigs.admin', function () {
      return Ember.get(this, 'userConfigs.accessProfile.generalManager') || Ember.get(this, 'userConfigs.accessProfile.admin');
    }),
    filters: Ember.computed('endDateFilter', 'reloadFilter', 'sessionUser.id', 'startDateFilter', 'statusFilter', 'tabFilter', 'userFilter', function () {
      let query = {};
      if (!this.sessionUser) {
        return null;
      }
      if (this.tabFilter === 'self-evaluation') {
        query.appraisedUser = this.sessionUser.id;
      } else if (this.tabFilter === 'team') {
        query.appraiserUser = this.sessionUser.id;
      }
      if (this.userFilter && this.userFilter.get('id')) {
        query.appraisedUser = this.userFilter.get('id');
      }
      if (this.startDateFilter && this.endDateFilter) {
        query.createdAt = {
          '>=': moment(this.startDateFilter).format('YYYY-MM-DD 00:00:00'),
          '<=': moment(this.endDateFilter).format('YYYY-MM-DD 23:59:59')
        };
      }
      if (this.statusFilter === 'pending') {
        if (this.tabFilter === 'team') {
          query.appraiserConcluded = 0;
        } else {
          query.concluded = 0;
        }
      } else if (this.statusFilter === 'finished') {
        if (this.tabFilter === 'team') {
          query.appraiserConcluded = 1;
        } else {
          query.concluded = 1;
        }
      }
      return query;
    }),
    init() {
      this._super(...arguments);
      Ember.set(this, 'statusFilter', 'all');
      Ember.set(this, 'tabFilter', 'self-evaluation');
    },
    actions: {
      filter() {
        Ember.set(this, 'reloadFilter', new Date());
      },
      clearFilters() {
        Ember.setProperties(this, {
          userFilter: null,
          statusFilter: 'all',
          startDateFilter: null,
          endDateFilter: null
        });
        this.send('filter');
      }
    }
  });
});