define("avaliacao-talentrh/pods/components/answer-evaluation/question/info/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "tleMNwpg",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],[[32,0,[\"textWithLimit\"]],\"Não informado\"],null]],[2,\"\\n\"],[6,[37,1],[[32,0,[\"isTextGreaterThenLimit\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"button\"],[14,0,\"btn btn-link btn-small float-right\"],[15,\"onclick\",[30,[36,0],[[32,0],\"toggleShowAllText\"],null]],[12],[2,\"\\n    Ver \"],[1,[30,[36,1],[[32,0,[\"showAllText\"]],\"menos\",\"mais\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"coalesce\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/components/answer-evaluation/question/info/item/template.hbs"
    }
  });
});