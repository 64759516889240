define("avaliacao-talentrh/pods/printed-evaluation/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "EX1Cl+oz",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"text-truncate\"],[12],[2,\"\\n  \"],[10,\"h6\"],[14,0,\"text-truncate mb-1\"],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[2,\"Avaliação de:\"],[13],[2,\" \"],[1,[35,0,[\"appraisedUser\",\"fullName\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"h6\"],[14,0,\"text-truncate mb-1\"],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[2,\"Descrição:\"],[13],[2,\" \"],[1,[35,0,[\"typeDescription\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"ml-auto text-right\"],[12],[2,\"\\n  \"],[10,\"small\"],[14,0,\"d-block\"],[14,\"title\",\"Data de Criação\"],[12],[1,[30,[36,1],[[35,0,[\"createdAt\"]],\"DD/MM/YYYY\"],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"evaluation\",\"moment-format\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/printed-evaluation/item/template.hbs"
    }
  });
});