define("avaliacao-talentrh/pods/reports/print-evaluation-by-employee/controller", ["exports", "ember-concurrency-decorators", "avaliacao-talentrh/config/environment", "moment"], function (_exports, _emberConcurrencyDecorators, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ReportsPrintEvaluationByEmployeeController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember.computed('filtersSelected.users.length', 'filtersSelected'), _dec10 = Ember.computed('filtersSelected.{branches.length,departments.length,jobFunctions.length,jobRoles.length}', 'filtersSelected'), (_class = class ReportsPrintEvaluationByEmployeeController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "fetch", _descriptor2, this);
      _initializerDefineProperty(this, "session", _descriptor3, this);
      _initializerDefineProperty(this, "toast", _descriptor4, this);
      _initializerDefineProperty(this, "filters", _descriptor5, this);
      _initializerDefineProperty(this, "filtersSelected", _descriptor6, this);
      _initializerDefineProperty(this, "evaluationTypeSelected", _descriptor7, this);
      _initializerDefineProperty(this, "statusOptionSelected", _descriptor8, this);
      _defineProperty(this, "statusOptions", [{
        label: 'Concluídas',
        value: 'concluded'
      }, {
        label: 'Canceladas',
        value: 'canceled'
      }]);
    }
    get disabledIfEmployeeSelected() {
      return Boolean(this.filtersSelected && this.filtersSelected.users && this.filtersSelected.users.length);
    }
    get disabledUsers() {
      return Boolean(this.filtersSelected && this.filtersSelected.branches && this.filtersSelected.branches.length || this.filtersSelected && this.filtersSelected.departments && this.filtersSelected.departments.length || this.filtersSelected && this.filtersSelected.jobRoles && this.filtersSelected.jobRoles.length || this.filtersSelected && this.filtersSelected.jobFunctions && this.filtersSelected.jobFunctions.length);
    }
    *generateReportXls() {
      try {
        if (!this.filtersSelected.startDate || !this.filtersSelected.endDate) {
          if (!this.filtersSelected.startDate) {
            return this.swal.warning('A data inicial deve ser informada.');
          }
          if (!this.filtersSelected.endDate) {
            return this.swal.warning('A data final deve ser informada.');
          }
        }
        const startDate = (0, _moment.default)(this.filtersSelected.startDate).startOf('day');
        const endDate = (0, _moment.default)(this.filtersSelected.endDate).endOf('day');
        const isSameOrAfter = (0, _moment.default)(endDate).isSameOrAfter(startDate);
        if (!isSameOrAfter) {
          return this.swal.warning('A data final deve ser igual ou posterior a data inicial.');
        }
        this.getFilters();
        let url = `${_environment.default.apiUrl.avd}/reports/xlxs/evaluationByEmployee/`;
        const search = new URLSearchParams(this.filters).toString();
        url += `?${search}`;
        this.swal.loading('Carregando...');
        let xlxs = yield fetch(url, {
          headers: {
            Authorization: `Bearer ${this.session.token}`
          }
        });
        if (xlxs.status === 204) {
          return this.toast.warning('Nenhum registro encontrado.');
        }
        let filename = yield xlxs.headers.get('content-disposition').match(/filename="(.+)"/)[1];
        xlxs = yield xlxs.blob();
        let element = document.createElement('a');
        element.href = URL.createObjectURL(xlxs);
        element.download = filename;
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        this.swal.close();
      } catch (e) {
        console.log('Error:', e);
        return this.swal.catch(e);
      }
    }
    getFilters() {
      const filters = {};
      if (this.statusOptionSelected) {
        filters.status = this.statusOptionSelected.value;
      }
      if (this.filtersSelected.startDate) {
        filters.startDate = (0, _moment.default)(this.filtersSelected.startDate).format('YYYY-MM-DD');
      }
      if (this.filtersSelected.endDate) {
        filters.endDate = (0, _moment.default)(this.filtersSelected.endDate).format('YYYY-MM-DD');
      }
      if (this.filtersSelected.branches && this.filtersSelected.branches.length) {
        filters.branches = this.filtersSelected.branches.map(b => b.id);
      }
      if (this.filtersSelected.departments && this.filtersSelected.departments.length) {
        filters.departments = this.filtersSelected.departments.map(d => d.id);
      }
      if (this.filtersSelected.jobRoles && this.filtersSelected.jobRoles.length) {
        filters.jobRoles = this.filtersSelected.jobRoles.map(jr => jr.id);
      }
      if (this.filtersSelected.jobFunctions && this.filtersSelected.jobFunctions.length) {
        filters.jobFunctions = this.filtersSelected.jobFunctions.map(jf => jf.id);
      }
      if (this.filtersSelected.users && this.filtersSelected.users.length) {
        filters.users = this.filtersSelected.users.map(u => u.id);
      }
      if (this.evaluationTypeSelected) {
        filters.evaluationType = this.evaluationTypeSelected;
      }
      this.filters = filters;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filtersSelected", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "evaluationTypeSelected", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'all';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "statusOptionSelected", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        label: 'Concluídas',
        value: 'concluded'
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "disabledIfEmployeeSelected", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "disabledIfEmployeeSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disabledUsers", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "disabledUsers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateReportXls", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "generateReportXls"), _class.prototype)), _class));
  _exports.default = ReportsPrintEvaluationByEmployeeController;
});