define("avaliacao-talentrh/services/notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    isListLoaded: false,
    unreadNotifications: Ember.A(),
    init() {
      this._super(...arguments);
      this.loadList();
    },
    loadList() {
      this.set('isListLoaded', false);
      this.store.query('notification', {
        limit: 5
      }).then(notifications => {
        this.set('unreadNotifications', notifications);
        this.set('isListLoaded', true);
      });
    }
  });
});