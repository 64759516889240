define("avaliacao-talentrh/models/pde-action", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "moment"], function (_exports, _model, _attr, _relationships, _emberCpValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', {
      ignoreBlank: true,
      presence: true,
      message: 'Informe um título.'
    }),
    finalDate: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Informe a data de conclusão.'
    })
  });
  var _default = _model.default.extend(Validations, {
    // Attributes
    createdAt: (0, _attr.default)('date'),
    description: (0, _attr.default)('string'),
    finalDate: (0, _attr.default)('date'),
    status: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    title: (0, _attr.default)('string'),
    // BelongsTo
    pde: (0, _relationships.belongsTo)('pde'),
    skill: (0, _relationships.belongsTo)('skill'),
    team: (0, _relationships.belongsTo)('team'),
    owner: (0, _relationships.belongsTo)('user'),
    // HasMany
    evidences: (0, _relationships.hasMany)('pde-evidence'),
    // Computed
    isExpired: Ember.computed('finalDate', function () {
      return (0, _moment.default)().startOf('day').isAfter(this.finalDate);
    }),
    statusBadge: Ember.computed('status', 'isExpired', function () {
      let status = this.status;
      let badges = {
        0: {
          text: 'Aberto',
          color: 'info'
        },
        1: {
          text: 'Finalizado',
          color: 'success'
        },
        2: {
          text: 'Expirado',
          color: 'warning'
        }
      };
      if (this.isExpired && this.status !== 1) {
        status = 2;
      }
      return badges[status] || {};
    })
  });
  _exports.default = _default;
});