define("avaliacao-talentrh/pods/analytics/nine-box/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AnalyticsNineBoxRoute extends Ember.Route {
    model() {
      return this.store.queryRecord('nine-box', {});
    }
    setupController(controller) {
      super.setupController(...arguments);
      controller.filterNineBoxCycle.perform();
    }
  }
  _exports.default = AnalyticsNineBoxRoute;
});