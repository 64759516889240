define("avaliacao-talentrh/pods/components/nine-box/arrow/right-arrow/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Df5ERN/D",
    "block": "{\"symbols\":[\"@description\",\"@canSeeSettings\"],\"statements\":[[10,\"div\"],[14,0,\"text-center mt-3\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"the-arrow right\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"shaft\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n   \"],[10,\"h4\"],[14,0,\"mt-1\"],[12],[1,[32,1]],[2,\"\\n\"],[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[14,0,\"fa fa-cog ml-1 btn-link cursor-pointer\"],[14,\"title\",\"Configurações Nine box\"],[15,\"onclick\",[32,0,[\"onSettingsClick\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"   \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/components/nine-box/arrow/right-arrow/template.hbs"
    }
  });
});