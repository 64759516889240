define("avaliacao-talentrh/pods/components/nine-box/arrow/up-arrow/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mW6rcScr",
    "block": "{\"symbols\":[\"@description\",\"@canSeeSettings\"],\"statements\":[[10,\"div\"],[14,0,\"the-arrow-up-container mr-3 d-flex flex-row-reverse justify-content-center\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"ml-4 the-arrow up\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"shaft\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"align-self-center the-arrow-up-description\"],[12],[2,\"\\n       \"],[10,\"h4\"],[14,0,\"text-nowrap\"],[12],[2,\"\\n         \"],[1,[32,1]],[2,\"\\n\"],[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"i\"],[14,0,\"fa fa-cog ml-1 btn-link cursor-pointer\"],[14,\"title\",\"Configurações Nine box\"],[15,\"onclick\",[32,0,[\"onSettingsClick\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"       \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/components/nine-box/arrow/up-arrow/template.hbs"
    }
  });
  _exports.default = _default;
});