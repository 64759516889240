define("avaliacao-talentrh/pods/feedback-request/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "V5dv5MbG",
    "block": "{\"symbols\":[\"View\",\"Section\",\"Form\"],\"statements\":[[8,\"ui-crud/view\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"section\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Criar Novo\"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"ui-form-renderer\",[],[[\"@formName\"],[\"feedback-request/form\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,3,[\"content\"]],[],[[\"@model\",\"@afterSave\"],[[34,0],[30,[36,1],[\"afterSave\"],null]]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\\n      \"],[8,[32,2,[\"footer\"]],[[24,0,\"text-right\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,3,[\"cancel\"]],[],[[\"@linkTo\"],[\"feedback-request\"]],null],[2,\"\\n        \"],[8,[32,3,[\"submit\"]],[],[[],[]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"route-action\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/feedback-request/new/template.hbs"
    }
  });
  _exports.default = _default;
});