define("avaliacao-talentrh/pods/reports/performance-by-question/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.filters = {
        questions: Ember.A(),
        users: Ember.A(),
        jobFunctions: Ember.A(),
        departments: Ember.A(),
        year: null,
        visualization: null,
        evaluationType: "all"
      };
    },
    actions: {
      generate() {
        this.swal.loading('Gerando Relatório...');
        this.ajax.request('/reports/generate/' + 'performanceByQuestion/?' + this.buildQueryFilter()).then(reportData => {
          this.swal.close();
          this.set('reportData', reportData);
          if (!reportData || !reportData.length) {
            this.swal.info('Não existem indicadores para os filtros informados.');
          }
        }).catch(err => {
          this.swal.catch(err);
        });
      }
    },
    buildQueryFilter() {
      let filters = this.filters;
      let objFilter = {};
      if (filters.question) {
        objFilter.question = filters.question.title;
      }
      if (filters.evaluationType !== 'all') {
        objFilter.evaluationType = filters.evaluationType;
      }
      if (filters.year) {
        objFilter.year = filters.year;
      }
      if (filters.visualization) {
        objFilter.visualization = filters.visualization;
      }
      if (filters.users.get('length')) {
        objFilter.users = filters.users.mapBy('id');
      }
      if (filters.departments.get('length')) {
        objFilter.departments = filters.departments.mapBy('id');
      }
      if (filters.jobFunctions.get('length')) {
        objFilter.jobFunctions = filters.jobFunctions.mapBy('id');
      }
      return jQuery.param(objFilter);
    }
  });
  _exports.default = _default;
});