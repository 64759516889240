define("avaliacao-talentrh/pods/notification/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model() {
      return this.store.query('notification', {
        sort: 'createdAt DESC'
      });
    }
  });
  _exports.default = _default;
});