define("avaliacao-talentrh/pods/reports/experience-ratings/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    // Services
    swal: Ember.inject.service(),
    // Lifecycle hooks
    init() {
      this._super(...arguments);
      this.filters = {
        users: Ember.A(),
        departments: Ember.A(),
        startDate: null,
        endDate: null
      };
    },
    // Actions
    actions: {
      buildFilters() {
        let filters = this.filters;
        let build = {};
        if (filters.users.length) {
          build.users = filters.users.mapBy('id');
        }
        if (filters.departments.length) {
          build.departments = filters.departments.mapBy('id');
        }
        if (filters.startDate) {
          build.startDate = moment(filters.startDate).format('DD/MM/YYYY');
        }
        if (filters.endDate) {
          build.endDate = moment(filters.endDate).format('DD/MM/YYYY');
        }
        return build;
      },
      validateFilters() {
        let filters = this.filters;
        if (!filters.departments.length && !filters.users.length && !filters.startDate && !filters.endDate) {
          this.swal.warning('Selecione ao menos um dos filtros');
          return;
        }
        if (!filters.startDate && !filters.endDate) {
          this.swal.warning('Intervalo de datas não informado');
          return;
        }
        if (!filters.startDate && filters.endDate) {
          this.swal.warning('Data inicial não informada');
          return;
        }
        if (filters.startDate && !filters.endDate) {
          this.swal.warning('Data final não informada');
          return;
        }
        return true;
      }
    }
  });
});