define("avaliacao-talentrh/pods/feedback-request/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YWsGR8Ha",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ui-crud/view\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui-crud__preview\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui-crud__preview__head\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui-crud__preview__icon\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-comment-smile\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"h5\"],[14,0,\"ui-crud__preview__title\"],[12],[2,\"Solicitações de Feedback\"],[13],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"ui-crud__preview__description\"],[12],[2,\"Solicitação de Feedbacks para outros Colaboradores.\"],[13],[2,\"\\n\\n\"],[6,[37,0],null,[[\"class\",\"route\"],[\"btn btn-primary mt-5\",\"feedback-request.new\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[14,0,\"fa fa-plus\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\" Criar uma Solicitação\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/feedback-request/index/template.hbs"
    }
  });
  _exports.default = _default;
});