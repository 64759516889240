define("avaliacao-talentrh/pods/evaluations/answer-list/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['list-group', 'list-group-flush', 'border-top'],
    evaluationTeams: Ember.A(),
    groupedAnswers: Ember.computed('evaluation', 'evaluationTeams', function () {
      let {
        appraiserAnswer,
        appraisedAnswer,
        consensusAnswer
      } = this.evaluation;
      if (!appraiserAnswer) {
        return;
      }
      return appraiserAnswer.map((section, sectionIndex) => {
        let questions = section.questions.map((question, questionIndex) => {
          let appraised;
          let consensus;
          let appraiser = question;
          if (appraisedAnswer) {
            appraised = appraisedAnswer[sectionIndex].questions[questionIndex];
          }
          if (consensusAnswer) {
            consensus = consensusAnswer[sectionIndex].questions[questionIndex];
          }
          const teamAnswers = this.evaluationTeams.map(evaluationTeam => {
            const teamAnswer = evaluationTeam.get('appraiserAnswer');
            const appraiserUser = evaluationTeam.get('appraiserUser');
            const teamQuestion = teamAnswer[sectionIndex].questions[questionIndex];
            return {
              teamQuestion: this.populateAnswer(teamQuestion),
              appraiserUser
            };
          });
          return {
            name: question.title || question.description,
            appraiser: this.populateAnswer(appraiser),
            appraised: this.populateAnswer(appraised),
            consensus: this.populateAnswer(consensus),
            teamAnswers
          };
        });
        return {
          description: section.description,
          questions
        };
      });
    }),
    didReceiveAttrs() {
      this._super(...arguments);
      this.loadEvaluationTeams.perform();
    },
    populateAnswer(question) {
      if (!question) {
        return;
      }
      let answer = (question.possibleAnswers || []).find(obj => {
        return Number(obj.percentage) === Number(question.answer) && Boolean(obj.ignoreToCalculate) === Boolean(question.ignoreToCalculate);
      }) || {};
      Ember.set(question, 'answerTitle', answer.description);
      return question;
    },
    loadEvaluationTeams: (0, _emberConcurrency.task)(function* () {
      yield this.evaluation.get('evaluationTeams').then(evaluationTeams => {
        Ember.set(this, 'evaluationTeams', evaluationTeams);
      });
    })
  });
  _exports.default = _default;
});