define("avaliacao-talentrh/pods/evaluation/answer-consensus/notes/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EvaluationAnswerConsensusNotesRoute extends Ember.Route {
    model() {
      return this.modelFor('evaluation.answer-consensus');
    }
  }
  _exports.default = EvaluationAnswerConsensusNotesRoute;
});