define("avaliacao-talentrh/pods/feedback/show/edit-followup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MUKcFPSE",
    "block": "{\"symbols\":[\"Modal\",\"Form\",\"Footer\"],\"statements\":[[8,\"ui-modal\",[],[[\"@open\",\"@modalSize\",\"@onCloseModal\"],[true,\"modal-lg\",[30,[36,0],[\"onCloseModal\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Editar Acompanhamento\"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"ui-form-renderer\",[],[[\"@formName\"],[\"feedback/form-followup\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"content\"]],[],[[\"@model\",\"@feedback\"],[[34,1,[\"followup\"]],[34,1,[\"feedback\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,3,[\"cancel\"]],[],[[],[]],null],[2,\"\\n      \"],[8,[32,2,[\"submit\"]],[],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\",\"model\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/feedback/show/edit-followup/template.hbs"
    }
  });
});