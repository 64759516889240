define("avaliacao-talentrh/pods/manage-goals/import-goals/controller", ["exports", "ember-concurrency", "xlsx"], function (_exports, _emberConcurrency, _xlsx) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    toast: Ember.inject.service(),
    session: Ember.inject.service(),
    // Default Values
    importedUserGoals: null,
    // Methods
    clearFileInput() {
      const inputFile = document.getElementById('import-goal-file');
      if (inputFile) {
        inputFile.value = "";
      }
    },
    // Tasks
    importGoals: (0, _emberConcurrency.task)(function* (workbook) {
      try {
        const confirm = yield this.swal.confirm('Deseja realmente importar essas metas?');
        if (!confirm.value) return;
        this.swal.loading('importando...');
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const json = _xlsx.default.utils.sheet_to_json(sheet, {
          header: 1,
          blankrows: false
        });
        // Pula a primeira linha que é o cabeçario;
        const lines = json.splice(1);
        const goalsAchieved = lines.map(line => {
          return {
            userGoal: line[0],
            value: line[7],
            comment: line[12] === 'Não informado' ? null : line[12]
          };
        });
        const response = yield this.ajax.post(`goalAchieveds/import`, {
          data: {
            goalsAchieved
          }
        });
        Ember.set(this, 'importedUserGoals', response.userGoals);
        this.swal.close();
        this.toast.success('Metas importadas com sucesso!');
        this.router.transitionTo('manage-goals');
        this.swal.loading("Buscando as metas...");
      } catch (e) {
        this.swal.catch(e);
      } finally {
        this.clearFileInput();
      }
    }).restartable(),
    // Actions
    actions: {
      onCloseModal() {
        this.router.transitionTo('manage-goals');
      },
      loadXlsx(event) {
        const file = event.target.files[0];
        if (!file) return;
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const data = new Uint8Array(reader.result);
            const workbook = _xlsx.default.read(data, {
              type: 'array',
              raw: true
            });
            this.importGoals.perform(workbook);
          } catch (e) {
            this.swal.catch("Não foi possível carregar o arquivo!");
          }
        };
        reader.readAsArrayBuffer(file);
      },
      getFile() {
        const inputFile = document.getElementById('import-goal-file');
        if (inputFile) {
          inputFile.click();
        }
      }
    }
  });
  _exports.default = _default;
});