define("avaliacao-talentrh/pods/feedback/show/new-followup/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        feedback: this.modelFor('feedback.show'),
        followup: this.store.createRecord('followup')
      });
    },
    actions: {
      onCloseModal() {
        let parentId = this.controller.get('model.feedback.id');
        this.transitionTo('feedback.show', parentId);
      }
    }
  });
  _exports.default = _default;
});