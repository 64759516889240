define("avaliacao-talentrh/pods/reports/consolidated-ratings/controller", ["exports", "avaliacao-talentrh/config/environment", "ember-concurrency", "moment", "jquery"], function (_exports, _environment, _emberConcurrency, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    swal: Ember.inject.service(),
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.filters = {
        branches: Ember.A(),
        users: Ember.A(),
        departments: Ember.A(),
        startDate: null,
        endDate: null,
        groupingType: 'appraiser'
      };
    },
    generate: (0, _emberConcurrency.task)(function* () {
      try {
        if (!this.filters.startDate || !this.filters.endDate) {
          return this.toast.warning('Necessário informar as datas');
        }
        let filters = _jquery.default.param(this.buildFilters());
        this.swal.loading('Carregando');
        let xlxs = yield fetch(`${_environment.default.apiBaseUrl}/reports/xlxs/consolidatedRatings/?${filters}`, {
          headers: {
            Authorization: `Bearer ${this.session.token}`
          }
        });
        if (xlxs.status === 204) {
          return this.toast.warning('Nenhum registro encontrado.');
        }
        let filename = yield xlxs.headers.get('content-disposition').match(/filename="(.+)"/)[1];
        xlxs = yield xlxs.blob();
        let element = document.createElement('a');
        element.href = URL.createObjectURL(xlxs);
        element.download = filename;
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        this.swal.close();
      } catch (e) {
        this.swal.catch(e);
      }
    }).restartable(),
    buildFilters() {
      let filters = this.filters;
      let build = {};
      if (filters.users.length) {
        build.users = filters.users.mapBy('id');
      }
      if (filters.departments.length) {
        build.departments = filters.departments.mapBy('id');
      }
      if (filters.branches.length) {
        build.branches = filters.branches.mapBy('id');
      }
      if (filters.startDate) {
        build.start = (0, _moment.default)(filters.startDate).format('DD/MM/YYYY');
      }
      if (filters.endDate) {
        build.end = (0, _moment.default)(filters.endDate).format('DD/MM/YYYY');
      }
      build.groupingType = filters.groupingType;
      return build;
    }
  });
  _exports.default = _default;
});