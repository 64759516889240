define("avaliacao-talentrh/pods/indicators/pdi-detail/controller", ["exports", "avaliacao-talentrh/pods/application/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _controller.default.extend({
    pdi: null,
    init() {
      this._super(...arguments);
    }
  });
  _exports.default = _default;
});