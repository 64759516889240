define("avaliacao-talentrh/pods/manage-goals/import-goals/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    beforeModel(transition) {
      const filters = this.controllerFor('manage-goals').get('filters');
      if (!transition.from && (!filters || !filters.startAt || !filters.endAt)) {
        transition.abort();
        this.transitionTo('manage-goals');
      }
    },
    deactivate() {
      const managerController = this.controllerFor('manage-goals');
      const filters = managerController.get('filters');
      const importedUserGoals = this.controller.get('importedUserGoals');
      if (filters && filters.startAt && filters.endAt) {
        this.controllerFor('manage-goals').getGoals.perform();
      } else if (importedUserGoals && importedUserGoals.length) {
        managerController.set('goals', importedUserGoals);
      }
    }
  });
  _exports.default = _default;
});