define("avaliacao-talentrh/pods/reports/performance-by-question/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    router: Ember.inject.service(),
    companyConfiguration: Ember.computed.alias('userConfigs.companyConfiguration'),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
      let userConfigs = this.userConfigs;
      const allowed = this.companyConfiguration?.get('hasAVDModule') && (userConfigs.accessProfile.generalManager || userConfigs.admin || userConfigs.sessionUser.isLeader);
      if (!allowed) {
        this.router.transitionTo('home');
      }
    },
    model() {
      return {
        question: null,
        users: Ember.A(),
        departments: Ember.A(),
        jobFunctions: Ember.A(),
        evaluationType: 'all',
        visualization: 'appraiser'
      };
    }
  });
  _exports.default = _default;
});