define("avaliacao-talentrh/helpers/style-inline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.styleInline = styleInline;
  function styleInline(params /*, hash*/) {
    let keys = params[0];
    keys = keys.split('|');
    let values = params.slice(0);
    values.shift();
    if (keys.length !== values.length) {
      return Ember.debug('Número de chaves é diferente ao de valores.');
    }
    let styles = [];
    keys.forEach((key, index) => {
      styles.push(key + ': ' + values[index]);
    });
    return Ember.String.htmlSafe(styles.join(';'));
  }
  var _default = _exports.default = Ember.Helper.helper(styleInline);
});