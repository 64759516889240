define("avaliacao-talentrh/pods/feedback/show/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    session: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    // Single line computed properties
    feedback: Ember.computed.alias('model'),
    // Multiline computed properties
    isConcluded: Ember.computed.equal('feedback.status', 1),
    showUserApproval: Ember.computed('feedback.{user.id,approvalStatus}', 'userConfigs.sessionUser.id', function () {
      return Ember.get(this, 'feedback.approvalStatus') === 'pending' && Ember.get(this, 'feedback.user.id') === Ember.get(this, 'userConfigs.sessionUser.id');
    }),
    // Taks
    toggleStatus: (0, _emberConcurrency.task)(function* (record) {
      let swal = this.swal;
      let status = Ember.get(record, 'status') || 0;
      try {
        let newStatus = status === 0 ? 1 : 0;
        Ember.set(record, 'status', newStatus);
        yield record.save();
        swal.success('Salvo com sucesso');
      } catch (e) {
        swal.catch(e);
      }
    }),
    publish: (0, _emberConcurrency.task)(function* () {
      let swal = this.swal;
      try {
        let result = yield swal.confirm('Deseja publicar? Essa operação tornará o feedback visível ao colaborador.');
        if (!result.value) {
          return;
        }
        let feedbackId = this.feedback.id;
        let response = yield this.ajax.put(`/feedbacks/${feedbackId}/publish`);
        this.store.pushPayload(response);
        swal.success('Seu feedback foi publicado e está visível ao colaborador.');
      } catch (e) {
        swal.catch(e);
      }
    }),
    deleteFeedback: (0, _emberConcurrency.task)(function* (record) {
      let swal = this.swal;
      try {
        let result = yield swal.confirm('Tem certeza que deseja remover este Feedback?');
        if (!result.value) {
          return;
        }
        yield record.destroyRecord();
        swal.success('Removido com sucesso.');
        this.transitionToRoute('feedback');
        this.send('reload');
      } catch (e) {
        swal.catch(e);
      }
    }),
    deleteFollowup: (0, _emberConcurrency.task)(function* (record) {
      let swal = this.swal;
      try {
        let result = yield swal.confirm('Tem certeza que deseja remover este Acompanhamento?');
        if (!result.value) {
          return;
        }
        yield record.destroyRecord();
        swal.success('Removido com sucesso.');
      } catch (e) {
        swal.catch(e);
      }
    }),
    userApproval: (0, _emberConcurrency.task)(function* () {
      let swal = this.swal;
      try {
        let result = yield swal.confirm('Confirma que está de acordo com esse feedback recebido?');
        if (!result.value) {
          return;
        }
        let response = yield this.ajax.put(`feedbacks/${this.feedback.id}/userApproval`);
        this.store.pushPayload(response);
        this.send('refreshRoute');
        swal.success('Aprovado com sucesso.');
      } catch (e) {
        swal.catch(e);
      }
    }),
    userDisapproval: (0, _emberConcurrency.task)(function* () {
      let swal = this.swal;
      try {
        let result = yield swal.fire({
          title: 'Informe o motivo para reprovar este feedback',
          text: 'Ao reprovar o feedback, o seu líder será notificado e poderá fazer as alterações necessárias',
          input: 'text',
          confirmButtonText: 'Reprovar',
          showCancelButton: true
        });
        if (result.isDismissed) {
          return;
        }
        if (!result.value) {
          return swal.warning('É necessário informar um motivo');
        }
        let response = yield this.ajax.put(`feedbacks/${this.feedback.id}/userDisapproval`, {
          data: {
            reason: result.value
          }
        });
        this.store.pushPayload(response);
        this.transitionToRoute('feedback');
        this.send('reload');
        swal.success('O feedback foi reprovado, após ser analisado pelo seu líder o mesmo voltará a ficar disponível para aprovação.');
      } catch (e) {
        swal.catch(e);
      }
    }),
    verifyTabActive(el, args) {
      const [feedback, user] = args;
      const leader = feedback.get('leaderUser.id');
      if (user.id === Number(leader)) {
        const [tab] = document.getElementsByName('createdByMe');
        if (!tab) {
          return;
        }
        tab.click();
      }
    }
  });
});