define("avaliacao-talentrh/models/pde", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* globals moment */

  const Validations = (0, _emberCpValidations.buildValidations)({
    team: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione uma equipe'
    }),
    finalDate: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione uma data de conclusão'
    }),
    objective: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Descreva o objetivo do PDE'
    })
  });
  var _default = _exports.default = _model.default.extend(Validations, {
    // Attributes
    annotationsPde: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date'),
    description: (0, _attr.default)('string'),
    finalDate: (0, _attr.default)('date'),
    objective: (0, _attr.default)('string'),
    status: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    // Associations
    company: (0, _relationships.belongsTo)('company'),
    owner: (0, _relationships.belongsTo)('user'),
    skill: (0, _relationships.belongsTo)('skill'),
    team: (0, _relationships.belongsTo)('team'),
    pdeActions: (0, _relationships.hasMany)('pde-action'),
    // Multiline computed properties
    isExpired: Ember.computed('finalDate', function () {
      return moment().startOf('day').isAfter(this.finalDate);
    }),
    statusBadge: Ember.computed('status', 'isExpired', function () {
      let status = this.status;
      let badges = {
        0: {
          text: 'Aberto',
          color: 'info'
        },
        1: {
          text: 'Finalizado',
          color: 'success'
        },
        2: {
          text: 'Expirado',
          color: 'warning'
        }
      };
      if (this.isExpired && this.status !== 1) {
        status = 2;
      }
      return badges[status] || {};
    })
  });
});