define("avaliacao-talentrh/models/user-preference", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    showSpecificIndicators: _emberData.default.attr('raw'),
    user: _emberData.default.belongsTo('user')
  });
});