define("avaliacao-talentrh/locales/en/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    /* Configuração da bandeira da lĩngua */
    "locale.flag": "/images/en_flag.png",
    /* TELA DE LOGIN */
    "login.username": "User",
    "login.password": "Password",
    "login.keep.connected": "Keep Connected",
    "login.password.recover": "Recover Password",
    "login.a.solution": "A solution by"
    /* FIM DA TELA DE LOGIN */
  };
  _exports.default = _default;
});