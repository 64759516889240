define("avaliacao-talentrh/pods/indicators/collaborator-evolution/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    userConfigs: Ember.inject.service(),
    router: Ember.inject.service(),
    accessProfile: Ember.computed.alias('userConfigs.accessProfile'),
    leds: Ember.computed.alias('userConfigs.usersICanManage.users'),
    setupController(controller, model) {
      this._super(controller, model);
      controller.set('openModalFilter', true);
    },
    deactivate() {
      Ember.set(this, 'controller.employeeEvaluationList', []);
      Ember.set(this, 'controller.user', []);
      Ember.set(this, 'controller.userFilter', null);
      Ember.set(this, 'controller.startDateFilter', null);
      Ember.set(this, 'controller.endDateFilter', null);
    },
    beforeModel() {
      const allowed = this.leds.length || this.accessProfile.admin || this.accessProfile.generalManager;
      if (!allowed) {
        return this.router.transitionTo('errors.forbidden');
      }
    }
  });
});