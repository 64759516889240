define("avaliacao-talentrh/models/rating-note-item", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    createdAt: (0, _model.attr)('date'),
    order: (0, _model.attr)('number'),
    title: (0, _model.attr)('string'),
    icon: (0, _model.attr)('string'),
    updatedAt: (0, _model.attr)('date'),
    value: (0, _model.attr)('number'),
    active: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    company: (0, _model.belongsTo)('company'),
    ratingNote: (0, _model.belongsTo)('rating-note')
  });
  _exports.default = _default;
});