define("avaliacao-talentrh/pods/pdi/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/bF6PJBq",
    "block": "{\"symbols\":[\"@pdi\"],\"statements\":[[8,\"link-to\",[[24,0,\"list-group-item\"]],[[\"@route\",\"@model\",\"@tagName\"],[\"pdi.show\",[32,1,[\"id\"]],\"li\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"small\"],[14,0,\"float-right\"],[14,\"title\",\"Data de Criação\"],[12],[1,[30,[36,0],[[32,1,[\"createdAt\"]],\"DD/MM/YYYY\"],null]],[13],[2,\"\\n  \"],[10,\"h6\"],[14,0,\"text-truncate font-weight-bold\"],[12],[1,[32,1,[\"objective\"]]],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text-truncate\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"font-weight-bold\"],[12],[2,\"De:\"],[13],[2,\"\\n    \"],[1,[32,1,[\"createdBy\",\"fullName\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text-truncate\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"font-weight-bold\"],[12],[2,\"Para:\"],[13],[2,\"\\n    \"],[1,[32,1,[\"user\",\"fullName\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"moment-format\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/pdi/item/template.hbs"
    }
  });
  _exports.default = _default;
});