define("avaliacao-talentrh/pods/indicators/pdi-detail/item-pdi-action/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nC08QeqB",
    "block": "{\"symbols\":[\"@pdiAction\"],\"statements\":[[10,\"div\"],[14,0,\"card-body bg-white py-2\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"numEvidences\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"float-right badge-primary\"],[14,5,\"padding: 0.2rem; margin: 10px\"],[14,\"title\",\"Número de evidências\"],[12],[2,\"\\n      \"],[10,\"strong\"],[12],[1,[32,1,[\"numEvidences\"]]],[2,\" Evidência(s)\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"h6\"],[14,0,\"text-truncate font-weight-bold\"],[12],[1,[32,1,[\"createdBy\",\"fullName\"]]],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text-truncate\"],[12],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"font-weight-bold\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fas fa-percent text-primary mr-2\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n      Valor da ação: \"],[1,[32,1,[\"partialScore\"]]],[2,\"%\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text-truncate\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"font-weight-bold\"],[12],[2,\"\\n      \"],[1,[32,1,[\"title\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/indicators/pdi-detail/item-pdi-action/template.hbs"
    }
  });
  _exports.default = _default;
});