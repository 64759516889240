define("avaliacao-talentrh/pods/components/answer-evaluation/question/comment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WZEzPTqQ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[12],[2,\"Observação (opcional)\"],[13],[2,\"\\n\"],[8,\"textarea\",[[24,0,\"form-control\"],[24,\"placeholder\",\"Você pode dizer algo sobre sua resposta...\"],[16,\"maxlength\",[32,0,[\"maxLengthComment\"]]]],[[\"@value\"],[[32,0,[\"comment\"]]]],null],[2,\"\"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n  \"],[1,[32,0,[\"comment\",\"length\"]]],[2,\" / \"],[1,[32,0,[\"maxLengthComment\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/components/answer-evaluation/question/comment/template.hbs"
    }
  });
  _exports.default = _default;
});