define("avaliacao-talentrh/locales/pt-br/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    /* Configuração da bandeira da lĩngua */
    "locale.flag": "/images/pt-br_flag.png",
    /*Palavras genericas*/
    "add": "Adicionar",
    "active": "Ativo",
    "action": "Ação",
    "actions": "Ações",
    "admin": "Administrador",
    "agenda": "Agenda",
    "announcements": "Comunicados",
    "about": "Sobre",
    "author": "Autor",
    "appointment": "Compromisso",
    "appointments": "Compromissos",
    "address": "Endereço",
    "apresentation": "Apresentação",
    "avaliation.trainers": "Avaliação dos Instrutores",
    /* B */
    "book": "Livro",
    "books": "Livros",
    "benefit": "Benefícios",
    "branch": "Ramal",
    "brigade": "Brigadista",
    /* C */
    "cancel": "Cancelar",
    "cellphone": "Celular",
    "close": "Fechar",
    "create": "Cadastrar",
    "company": "Empresa",
    "course": "Curso",
    "courses": "Cursos",
    "contact": "Contato",
    "change": "Alterar",
    "classified": "Classificados",
    "complement": "Complemento",
    "corporate": "Corporativo",
    "conclusionDate": "Data de Conclusão",
    "city": "Cidade",
    "color": "Cor",
    "cnpj": "CNPJ",
    "cipeiro": "Cipeiro",
    "closed": "Encerrado",
    "content": "Conteúdo",
    "contributors": "Colaboradores",
    /* D */
    "department": "Departamento",
    "departments": "Departamentos",
    "description": "Descrição",
    "destroy": "Deletar",
    "day": "Dia",
    "de": "de",
    "date": "Data",
    "avaliableDates": "Datas Disponíveis",
    "delete": "Excluir",
    "default": "Padrão",
    /* E */
    "email": "Email",
    "event": "Eventos",
    "endDate": "Data Final",
    "experience": "Experiência",
    "edit": "Editar",
    "explication.training": "Planeje seus próximos treinamentos arrastando-os para o calendário.",
    /* F */
    "favorites": "Favoritos",
    "find": "Exibir",
    "finish": "Finalizar",
    "first": "Primeiro",
    "function": "Função",
    "functions": "Funções",
    /* G */
    "graduation": "Graduação",
    "group": "Grupo",
    /* H */
    "home": "Home",
    "historical": "Históricos",
    /* I */
    "initialDate": "Data Inicial",
    "indicators": "Indicadores",
    "institution": "Instituição",
    "institutions": "Instituições",
    "informations": "Informações",
    "information": "Informação",
    "interest": "Interesse",
    /* L */
    "last": "Último",
    "lastName": "Sobrenome",
    "local": "Local",
    "link": "Link",
    "list": "Lista",
    /* M */
    "month": "Mês",
    "message": "Mensagem",
    "member": "Membro",
    "more.details": "Mais Detalhes",
    "minimumFrequency": "Frequência Mínina",
    "minimumGrade": "Nota Mínina",
    "myCompany": "Minha Empresa",
    /* N */
    "news": "Noticias",
    "name": "Nome",
    "number": "Número",
    "neighborhood": "Bairro",
    "new": "Novo(a)",
    "next": "Próximo",
    /* O */
    "order": "Ordem",
    "open": "Aberto",
    /* P */
    "password": "Senha",
    "personalDocument": "CPF",
    "phone": "Telefone",
    "photos": "Fotos",
    "photo": "Foto",
    "profile": "Profile",
    "platform": "Plataforma",
    "projects": "Projetos",
    "project": "Projeto",
    "publications": "Publicações",
    "publish": "Publicar",
    "previous": "Anterior",
    "priority": "Prioridade",
    "policy": "Politica",
    "progress": "Andamento",
    "participants": "Participantes",
    /* R */
    "reason": "Motivo",
    "responsible": "Responsável",
    "requests": "Solicitações",
    "request": "Solicitação",
    "room": "Salas",
    /* S */
    "search": "Procurar",
    "status": "Status",
    "select": "Selecionar",
    "survey": "Enquetes",
    "save": "Salvar",
    "settings": "Configurações",
    "summary": "Resumo",
    "subscribers": "Inscritos",
    "started.in": "Inícia na",
    /* T */
    "task": "Tarefa",
    "time": "Hora",
    "trainings": "Treinamentos",
    "tasks": "Tarefas",
    "title": "Título",
    "training": "Capacitação",
    "type": "Tipo",
    "to": "Até",
    "trainers": "Instrutores",
    /* U */
    "user": "Usuário",
    "users": "Usuários",
    "update": "Atualizar",
    /* V */
    /* w */
    "work": "Trabalho",
    "week": "Semana",
    /* Z */
    "zipcode": "CEP",
    /*Fim palavras genericas*/

    /*Palavras Compostas*/
    "aboutUser": "Sobre o usuário",
    "admissionDate": "Data de Admissão",
    "aditionalData": "Dados Adicionais",
    "adminConfiguration": "Administração/Configuração",
    "albumPhoto": "Album de Fotos",
    /* B */
    "birthDate": "Data de Nascimento",
    "birthCity": "Cidade de Nascimento",
    /* C */
    "corporatePortal": "Portal Corporativo",
    "confirmPassword": "Confirmação nova Senha",
    "contactData": "Dados do Contato",
    /* D */
    "descriptionActivity": "Descrição Atividades",
    /* F */
    "favoriteSport": "Esporte Favorito",
    "favoriteFood": "Comida Favorita",
    "finishMessage": "Finalizar Recado",
    /* G */
    "generateCommitment": "Gerar Compromisso",
    /* H */
    "heartTime": "Time do coração",
    /* L */
    "listEmployees": "Lista de Colaboradores",
    "literacy": "Grau de Instrução",
    "linkFacebook": "Link Facebook",
    "linkTwitter": "Link Twitter",
    "linkGoogle": "Link Google Plus",
    "linkInstagram": "Link Instagram",
    "personalEmail": "Email Pessoal",
    "sendMessage": "Enviar Mensagem",
    "showAllAppointments": "Ver todos os Compromissos",
    /* M */
    "messagesHr": "Solicitações RH",
    "messagesRegistered": "Recados Cadastrados",
    "messageBoards": "Mural de Recados",
    "myPage": "Minha Página",
    "myMessage": "Meu Recado",
    "messageInformation": "Informações do Recado",
    /* N */
    "newPassword": "Nova Senha",
    "noRecordsFound": "Sem registros cadastrados",
    "noAppointments": "Sem compromissos Cadastrados",
    /* P */
    "policyCompany": "Politicas da Empresa",
    "personalData": "Dados Pessoais",
    "professionalData": "Dados Profissionais",
    "professionalExperience": "Experiência Profissional",
    "particularInformation": "Informações Particulares",
    /* R */
    "readBooks": "Livros Lidos",
    "registrationData": "Dados Cadastrais",
    "receiptPayment": "Recibo de Pagamento",
    "sugestionBox": "Caixa de Sugestões",
    "userInformation": "Informações do Usuário",
    "write.something.for": "Escreva algo para",
    /*Fim Palavras Compostas*/

    /* LOGIN */
    "login.username": "Usuário",
    "login.password": "Senha",
    "login.keep.connected": "Manter Conectado",
    "login.password.recover": "Recuperar Senha",
    "login.a.solution": "Uma solução",
    /* FIM - LOGIN */

    /*  PERFIL DE USUARIO */
    /* /configuration/user-profiles/edit */
    "accessRule.appointment": "Compromissos",
    "accessRule.accessprofile": "Perfil de acesso",
    "accessRule.accessrule": "Regras de acesso",
    "accessRule.benefit": "Benefícios",
    "accessRule.book": "Livros",
    "accessRule.comment": "Comentários",
    "accessRule.company": "Empresa",
    "accessRule.companyAlbum": "Albuns da empresa",
    "accessRule.companyConfiguration": "Configurações da plataforma TalentRH",
    "accessRule.post": "Postagem",
    "accessRule.find": "Listar",
    "accessRule.findOne": "Exibir",
    "accessRule.create": "Cadastrar",
    "accessRule.update": "Atualizar",
    "accessRule.destroy": "Deletar",
    "accessRule.like": "Curtir",
    "accessRule.user": "Usuário",
    /* FIM - PERFIL DE USUARIO */

    "editBenefit": "Edição do Benefício",
    "editPolicy": "Edição da Politica",
    "editTask": "Edição da Tarefa",
    "usersProfile": "Usuários neste perfil",
    "change.password": "Atualizar Senha",
    "editCourse": "Edição do Curso",
    "editReason": "Edição do Motivo",
    "editBook": "Edição do Livro",
    "editRequest": "Edição da Solicitação",
    "editCompany": "Edição da Empresa",
    "editFunction": "Edição da Função",
    "editUserMessage": "Edição do Recado",
    "editInstitution": "Edição da Instituição",
    "editAppointment": "Ediçao do Compromisso",
    "editProject": "Edição do Projeto",
    "appointmentList": " Compromissos",
    "manageAppointment": "Gerenciar Compromissos",
    "manageTask": "Gerenciar Tarefas",
    "userMessage": "Mural de Recados",
    "reasonMessage": "Motivos de Recado",
    /*Textos*/
    "organizeAgenda": "Organize sua agenda arrastando seus compromissos.",
    /*Fim textos*/

    /*Register*/
    "register.title": "Cadastre seu usuário e começe a aproveitar o portal agora mesmo",
    "register.title.company": "Cadastre sua empresa e começe a usar o Portal agora mesmo",
    /*Fim register*/

    /*Project work*/
    "project.edit.project": "Editar Projeto",
    "project.delete.project": "Excluir Projeto",
    "project.end.project": "Finalizar Projeto",
    "project.select.project": "Selecione um projeto",
    "project.add.list.task": "Adicionar lista de Tarefas",
    "project.list.project": "Lista de Tarefas",
    "project.name.list": "Nome da Lista",
    "project.tooltip.play": "Iniciar desenvolvimento Lista",
    "project.add.task": "Adicionar Tarefa",
    "project.select.view": "Selecione um projeto para visualizar as tarefas",
    "project.work.in": "Trabalhando em",
    "project.open.task": "Tarefa Aberta",
    "project.no.task": "Sem tarefas",
    "project.edit.list": "Editar Lista",
    "project.delete.list": "Excluir Lista",
    "project.low": "Baixa",
    "project.high": "Alta",
    "project.medium": "Média",
    "project.add.column": "Add. Coluna",
    "project.edit.column": "Editar Coluna",
    "project.delete.column": "Excluir Coluna",
    "project.subtask": "Subtarefa"
    /*Fim Project work*/
  };
  _exports.default = _default;
});