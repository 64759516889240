define("avaliacao-talentrh/models/rating-note", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    active: (0, _model.attr)('boolean'),
    createdAt: (0, _model.attr)('date'),
    description: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    totalNumberOfOrders: (0, _model.attr)('number'),
    updatedAt: (0, _model.attr)('date'),
    setTitleManual: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    setPercentManual: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    company: (0, _model.belongsTo)('company'),
    ratings: (0, _model.hasMany)('rating-note-item')
  });
});