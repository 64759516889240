define("avaliacao-talentrh/pods/application/super-user-login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yE0QAzWr",
    "block": "{\"symbols\":[\"Modal\"],\"statements\":[[8,\"ui-modal\",[],[[\"@open\"],[[34,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n      \"],[10,\"h3\"],[12],[2,\"Login\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n          \"],[10,\"label\"],[14,0,\"control-label\"],[12],[2,\"Email ou CPF do usuário\"],[13],[2,\"\\n          \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@type\",\"@value\",\"@enter\"],[\"text\",[34,1],[30,[36,3],[[35,2]],null]]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n        \"],[10,\"button\"],[14,0,\"btn btn-primary full-width\"],[15,\"onclick\",[30,[36,3],[[35,2]],null]],[14,4,\"button\"],[12],[2,\"\\n          Logar\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"openModal\",\"userIdentifier\",\"superUserLogin\",\"perform\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/application/super-user-login/template.hbs"
    }
  });
  _exports.default = _default;
});