define("avaliacao-talentrh/pods/evaluations/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "GA59PcAH",
    "block": "{\"symbols\":[\"@evaluation\"],\"statements\":[[8,\"link-to\",[[24,0,\"list-group-item d-flex\"]],[[\"@route\",\"@model\",\"@tagName\"],[[34,0],[32,0,[\"evaluationId\"]],\"li\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-truncate\"],[12],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"text-truncate mb-1\"],[12],[2,\"\\n      \"],[10,\"strong\"],[12],[2,\"Avaliação de:\"],[13],[2,\" \"],[1,[32,1,[\"appraisedUser\",\"fullName\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"text-truncate mb-1\"],[12],[2,\"\\n      \"],[10,\"strong\"],[12],[2,\"Tipo:\"],[13],[2,\" \"],[1,[32,1,[\"typeDescription\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"span\"],[14,0,\"badge border border-primary text-primary mt-2\"],[12],[1,[34,1]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"ml-auto text-right\"],[12],[2,\"\\n    \"],[10,\"small\"],[14,0,\"d-block\"],[14,\"title\",\"Data de Criação\"],[12],[1,[30,[36,2],[[32,1,[\"createdAt\"]],\"DD/MM/YYYY\"],null]],[13],[2,\"\\n    \"],[10,\"span\"],[15,0,[31,[\"badge text-white badge-\",[34,3,[\"color\"]]]]],[12],[1,[35,3,[\"text\"]]],[13],[2,\"\\n\"],[6,[37,4],[[32,1,[\"reopened\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"badge badge-info mt-2\"],[14,\"title\",\"Essa avaliação foi reaberta pelo administrador\"],[12],[2,\"Reaberta\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"evaluationRoute\",\"evaluationRole\",\"moment-format\",\"statusBadge\",\"if\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/evaluations/item/template.hbs"
    }
  });
  _exports.default = _default;
});