define("avaliacao-talentrh/pods/components/answer-evaluation/question/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['answer-evaluation__question'],
    unansweredClassError: Ember.computed('finishError', 'question.answer', function () {
      if (!this.finishError || !this.finishError.unansweredQuestions || this.question.answer >= 0) {
        return;
      }
      let hasError = this.finishError.unansweredQuestions.find(obj => {
        return obj.section === this.sectionIndex && obj.question === this.questionIndex;
      });
      return hasError ? 'border border-danger' : '';
    }),
    selectedAnswer: Ember.computed('question.answer', function () {
      if (!this.question) {
        return;
      }
      return this.question.possibleAnswers.objectAt(this.question.answerOrder);
    }),
    appraiserQuestion: Ember.computed('questionIndex', 'sectionIndex', 'appraiserAnswer', function () {
      if (!this.appraiserAnswer) {
        return;
      }
      const question = this.appraiserAnswer[this.sectionIndex].questions[this.questionIndex];
      return question;
    }),
    appraiserQuestionAnswer: Ember.computed('questionIndex', 'sectionIndex', 'appraiserAnswer', function () {
      const question = this.appraiserQuestion;
      if (question.answerOrder < 0) {
        return null;
      }
      const answer = question.possibleAnswers[question.answerOrder];
      return answer.description;
    }),
    appraiserQuestionComment: Ember.computed('questionIndex', 'sectionIndex', 'appraiserAnswer', function () {
      const answer = this.appraiserQuestion;
      if (!answer) return answer;
      return answer.comment;
    }),
    appraisedQuestion: Ember.computed('questionIndex', 'sectionIndex', 'appraisedAnswer', function () {
      if (!this.appraisedAnswer) {
        return;
      }
      const question = this.appraisedAnswer[this.sectionIndex].questions[this.questionIndex];
      return question;
    }),
    appraisedQuestionAnswer: Ember.computed('questionIndex', 'sectionIndex', 'appraisedAnswer', function () {
      const question = this.appraisedQuestion;
      if (question.answerOrder < 0) {
        return null;
      }
      const answer = question.possibleAnswers[question.answerOrder];
      return answer.description;
    }),
    appraisedQuestionComment: Ember.computed('questionIndex', 'sectionIndex', 'appraisedAnswer', function () {
      const question = this.appraisedQuestion;
      if (!question) return question;
      return question.comment;
    }),
    actions: {
      onSelectAnswer(possibleAnswer) {
        Ember.set(this, 'question.answer', possibleAnswer.percentage);
        Ember.set(this, 'question.answerOrder', possibleAnswer.order);
        Ember.set(this, 'question.ignoreToCalculate', possibleAnswer.ignoreToCalculate);
      }
    }
  });
});