define("avaliacao-talentrh/helpers/concatenate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.concatenate = concatenate;
  _exports.default = void 0;
  function concatenate(params /*, hash*/) {
    var concatenated = '';
    for (var i = 0; i < params.length; i++) {
      concatenated += params[i] || '';
    }
    // console.log('Concatenated: ', concatenated);
    return concatenated;
  }
  var _default = Ember.Helper.helper(concatenate);
  _exports.default = _default;
});