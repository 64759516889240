define("avaliacao-talentrh/pods/components/quiz-question-block/component", ["exports", "ember-concurrency", "jquery", "lodash"], function (_exports, _emberConcurrency, _jquery, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['card', 'quiz', 'shadow-sm', 'mb-3'],
    classNameBindings: ['isActive:active'],
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    description: null,
    isActive: false,
    typeAnswers: Ember.computed.alias('loadAnswerType.lastSuccessful.value'),
    typeSelected: Ember.computed('question.typeAnswer', 'typeAnswers', function () {
      if (!this.typeAnswers) {
        return;
      }
      return this.typeAnswers.findBy('id', this.question.typeAnswer);
    }),
    // eslint-disable-next-line ember/no-observers
    select: Ember.observer('personalized', function () {
      if (this.question.description) {
        Ember.set(this, 'question.description', this.question.description);
      } else {
        Ember.set(this, 'question.description', '');
      }
      switch (this.personalized) {
        case '1':
          Ember.set(this, 'question.question', '');
          break;
        case '0':
          Ember.set(this, 'question.skill', '');
          break;
        default:
          Ember.set(this, 'question.skill', '');
          Ember.set(this, 'question.question', '');
      }
    }),
    //Ao iniciar, verifica se tem questões, se tiver,
    //seta a primeira pergunta como ativa, adicionando as opções
    didInsertElement() {
      this._super(...arguments);
      if (this.questions.length) {
        Ember.set(this, 'isActive', true);
      }
      if (this.question.skill) {
        Ember.set(this, 'personalized', '1');
      } else if (this.question.question) {
        Ember.set(this, 'personalized', '0');
      } else {
        Ember.set(this, 'personalized', '2');
      }
    },
    //Ao clicar em um card da questão, remove todos os active da pagina,
    //e adiciona somente no clicado
    mouseUp() {
      (0, _jquery.default)('.card.quiz').removeClass('active');
      this.element.classList.add('active');
    },
    loadAnswerType: (0, _emberConcurrency.task)(function* () {
      try {
        return yield this.store.findAll('type-answer');
      } catch (e) {
        Ember.debug(e);
      }
    }).on('didInsertElement').drop(),
    updatePercent(array, description, needConfirm) {
      let updatePercent = () => {
        let pagesQuantity = array.length;
        let valuePerPage = (100 / pagesQuantity).toFixed(2);
        array.forEach(page => {
          Ember.set(page, 'percentage', valuePerPage);
        });
      };
      if (needConfirm) {
        this.swal.confirm(description).then(result => {
          if (result.value) {
            updatePercent();
            this.toast.success('Percentuais ajustados');
          }
        });
      } else {
        updatePercent();
        this.toast.success('Percentuais ajustados');
      }
    },
    updatePercentQuestions(page) {
      let questionsQuantity = page.questions.length;
      let valuePerQuestion = (100 / questionsQuantity).toFixed(2);
      page.questions.forEach(question => {
        Ember.set(question, 'percentage', valuePerQuestion);
      });
    },
    actions: {
      //Adiciona uma alternativa na questão atual
      addOption(alternatives) {
        //Insere no array de alternativas
        //Pega a maior numero da ordem e adiciona mais 1
        const orders = this.question.alternatives.map(({
          order
        }) => order);
        const lastOrder = Math.max(...orders);
        alternatives.pushObject({
          label: null,
          order: lastOrder + 1
        });
      },
      removeOption(alternative) {
        this.question.alternatives.removeObject(alternative);
      },
      afterSelectQuestion(question) {
        Ember.set(this, 'question.title', question.title);
        Ember.set(this, 'question.question', question);
        Ember.set(this, 'question.description', question.description);
      },
      afterSelectSkill(skill) {
        Ember.set(this, 'question.title', skill.name);
        Ember.set(this, 'question.skill', skill);
        Ember.set(this, 'question.description', skill.description);
      },
      //Adiciona uma nova questão na página
      addQuestion() {
        //Metodo utilizad para descer até o fim da página
        var $target = (0, _jquery.default)('html,body');
        $target.animate({
          scrollTop: this.$().offset().top + this.$().height() - 100
        }, 1000);

        //Esconde as opções do componente atual e remove a classe active de todos
        Ember.set(this, 'isActive', false);
        (0, _jquery.default)('.ui-block.quiz').removeClass('active');

        //Pega a ultima questão
        //Adiona uma nova questão com a ordem +1 do que a ultima
        //Ao ser inserida, por causa do metodo didInsertElement,
        //ela terá automaticamente as opções do questionário
        this.questions.pushObject({
          title: null,
          type: 'radio',
          required: false,
          order: this.questions.length,
          alternatives: [{
            label: null,
            order: 0
          }]
        });
        this.updatePercent(this.questions, 'Deseja ajustar a porcentagem das questões automaticamente?', true);
      },
      //Adiciona nova página ao questionário
      addPage() {
        var $target = (0, _jquery.default)('html,body');
        $target.animate({
          scrollTop: $target.height()
        }, 1000);

        //cria a pagina com o questionário e a alternativa com ordem 0,
        //pois são as primeiras da pagina
        this.pages.pushObject({
          questions: [{
            title: null,
            type: 'radio',
            required: false,
            order: 0,
            alternatives: [{
              label: null,
              order: 0
            }]
          }]
        });
        this.updatePercent(this.pages, 'Deseja ajustar a porcentagem das páginas automaticamente?', true);
      },
      deleteSession(model) {
        this.swal.confirm('Confirmar exclusão desta seção?').then(result => {
          if (result.value) {
            //Usa o lodash para percorrer o array, se encontrar uma questão
            //igual a da passada por parâmetro, é removida,
            //depois retornado o questionário sem a questão
            // const questions = _.remove(this.questions, question => question.order != model.order);

            //Seta a nova lista de questionário, sem o recem excluído
            this.pages.removeObject(model);
            //Marca o primeiro questionário da página como atívo
            (0, _jquery.default)('.ui-block.quiz').first().addClass('active');
            this.updatePercent(this.pages);
          }
        });
      },
      //Clona uma questão
      cloneQuestion(model) {
        //Metodo usado para descer até o fim da página
        var $target = (0, _jquery.default)('html,body');
        $target.animate({
          scrollTop: this.$().offset().top + this.$().height() - 100
        }, 1000);

        //Esconde as opções do componente atual e remove a classe active de todos
        Ember.set(this, 'isActive', false);
        (0, _jquery.default)('.ui-block.quiz').removeClass('active');

        //Utiliza o lodash para clonar até as alternativas
        const question = _lodash.default.cloneDeep(model);
        //Pega a ultima questão e adiciona uma nova com a ordem +1 da ultima
        const last = _lodash.default.last(_lodash.default.sortBy(this.questions, ['order']));
        Ember.set(question, 'order', last.order + 1);

        //Insere a questão clonada na página
        this.questions.pushObject(question);
      },
      //Deleta uma questão
      deleteQuestion(model) {
        this.swal.confirm('Confirmar exclusão desta questão?').then(result => {
          if (result.value) {
            //Seta a nova lista de questionário, sem o recem excluído
            this.questions.removeObject(model);
            //Marca o primeiro questionário da página como atívo
            (0, _jquery.default)('.ui-block.quiz').first().addClass('active');
            this.updatePercent(this.questions);
          }
        });
      },
      show() {
        this.$('.more').toggleClass('active');
      },
      hide() {
        this.$('.more').removeClass('active');
      },
      updatePercentQuestions() {
        this.updatePercent(this.questions);
      },
      cleanSkill(e) {
        this.set('question.skill', '');
        this.set('question.question', '');
        this.set('personalized', e.target.checked);
      },
      selectType(selected) {
        Ember.setProperties(this.question, {
          alternatives: Ember.A(),
          typeAnswer: selected.id
        });
        const answersPercent = selected.answers.filterBy('percentage');
        const answersNoPercent = selected.answers.filter(a => !a.percentage && !a.ignoreToCalculate);
        let answerPercent = answersPercent.mapBy('percentage');
        if (!answerPercent.length) {
          return this.swal.warning('Não foi possível carregar as alternativas pois algumas estão com o percentual vazio. Corrija o cadastro e tente novamente.');
        }
        let findPercentIncorrect = answerPercent.find(p => p < 0);
        if (answerPercent.length && findPercentIncorrect) {
          return this.swal.warning('Não foi possível carregar as alternativas pois algumas estão com o percentual negativo. Corrija o cadastro e tente novamente.');
        }
        answerPercent = answerPercent.reduce((sum, value) => sum + value);
        let percentage = (100 - answerPercent) / answersNoPercent.length;
        selected.answers.forEach(answer => {
          this.question.alternatives.pushObject({
            description: answer.description,
            definition: answer.definition,
            id: answer.id,
            ignoreToCalculate: answer.ignoreToCalculate,
            needJustification: answer.needJustification,
            order: answer.order,
            percentage: answer.percentage === null && !answer.ignoreToCalculate ? percentage : answer.percentage
          });
        });

        //Ordena cada alternativa pelo número da propriedade "Order"
        this.question.alternatives = this.question.alternatives.sort((firstOrder, secondOrder) => {
          if (firstOrder.order > secondOrder.order) return 1;
          if (firstOrder.order < secondOrder.order) return -1;
          return 0;
        });
      }
    }
  });
});