define("avaliacao-talentrh/models/model-questionnaire", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    description: _emberData.default.attr('string'),
    pages: _emberData.default.attr('raw', {
      defaultValue: () => [{
        percentage: null,
        description: null,
        questions: [{
          title: null,
          order: 0,
          type: null,
          percentage: null,
          alternatives: [{
            definition: null,
            description: null,
            percentage: null,
            order: 0
          }]
        }]
      }]
    })
  });
});