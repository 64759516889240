define("avaliacao-talentrh/pods/indicators/collaborator-evolution/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gvc5lYaQ",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[8,\"ui-card\",[[24,0,\"shadow-sm mb-2\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"card-body bg-white py-2\"],[12],[2,\"\\n      \"],[10,\"canvas\"],[14,1,\"chart\"],[14,\"width\",\"1650\"],[14,\"height\",\"600\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/indicators/collaborator-evolution/item/template.hbs"
    }
  });
  _exports.default = _default;
});