define("avaliacao-talentrh/pods/printed-evaluation/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.LinkComponent.extend({
    tagName: 'li',
    classNames: ['list-group-item'],
    route: 'printed-evaluation.show',
    model: Ember.computed.alias('evaluation.id')
  });
});