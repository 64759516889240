define("avaliacao-talentrh/pods/feedback-request/show/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    store: Ember.inject.service(),
    // Computed Properties
    isPending: Ember.computed('model.status', function () {
      return this.model.status === 'pending';
    }),
    statusLabels: Ember.computed(function () {
      return {
        'pending': 'Pendente',
        'refused': 'Recusado',
        'accepted': 'Aceito'
      };
    }),
    statusColors: Ember.computed(function () {
      return {
        'pending': 'warning',
        'refused': 'danger',
        'accepted': 'success'
      };
    }),
    // Tasks
    acceptRequest: (0, _emberConcurrency.task)(function* (record) {
      try {
        let result = yield this.swal.confirm('Deseja aceitar essa solicitação?', 'Após esta operação a solicitação não poderá ser editada.');
        if (!result.value) {
          return;
        }
        let response = yield this.ajax.put(`/feedbackRequests/${record.id}/accept`);
        this.store.pushPayload(response);
        this.swal.success('Solicitação aceita com sucesso.');
        this.transitionToRoute('feedback.new', {
          queryParams: {
            feedbackRequestId: record.id
          }
        });
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    refuseRequest: (0, _emberConcurrency.task)(function* (record) {
      try {
        let result = yield this.swal.fire({
          input: 'textarea',
          title: 'Deseja dispensar essa solicitação?',
          text: 'Após esta operação a solicitação não poderá ser editada.',
          inputPlaceholder: 'Informe o motivo.',
          inputValidator: value => {
            return !value && 'Você precisa informar o motivo.';
          }
        });
        if (result.value === undefined) {
          return;
        }
        let response = yield this.ajax.put(`/feedbackRequests/${record.id}/refuse`, {
          data: {
            refusalReason: result.value
          }
        });
        this.store.pushPayload(response);
        this.swal.success('A solicitação foi dispensada.');
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    deleteRequest: (0, _emberConcurrency.task)(function* (record) {
      try {
        let result = yield this.swal.confirm('Tem certeza que deseja remover esta solicitação?');
        if (!result.value) {
          return;
        }
        yield record.destroyRecord();
        this.swal.success('Removido com sucesso.');
        this.transitionToRoute('feedback-request');
        this.send('reload');
      } catch (e) {
        this.swal.catch(e);
      }
    })
  });
  _exports.default = _default;
});