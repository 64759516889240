define("avaliacao-talentrh/models/pde-evidence", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    createdAt: (0, _attr.default)('date'),
    description: (0, _attr.default)('string'),
    path: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    // BelongsTo
    company: (0, _relationships.belongsTo)('company'),
    createdBy: (0, _relationships.belongsTo)('user'),
    pde: (0, _relationships.belongsTo)('pde'),
    pdeAction: (0, _relationships.belongsTo)('pde-action'),
    team: (0, _relationships.belongsTo)('team')
  });
});