define("avaliacao-talentrh/pods/feedback/show/historic/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    sortingDesc: ['createdAt:desc'],
    sorted: Ember.computed.sort('historic', 'sortingDesc')
  });
  _exports.default = _default;
});