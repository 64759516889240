define("avaliacao-talentrh/pods/new-evaluation/index/controller", ["exports", "ember-concurrency", "avaliacao-talentrh/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    session: Ember.inject.service(),
    delete: (0, _emberConcurrency.task)(function* (item) {
      let {
        value
      } = yield this.swal.confirm('Confirmar exclusão do questionário?', {
        text: 'Todos os dados associados a ele serão excluídos!'
      });
      if (!value) {
        return;
      }
      try {
        let response = yield fetch(`${_environment.default.apiBaseUrl}/questionnaires/${item.id}`, {
          headers: {
            Authorization: `Bearer ${this.session.token}`
          },
          method: 'DELETE'
        });
        if (response.status === 202) {
          yield this.swal.warning('', {
            text: 'Há avaliações vinculadas ao questionário.',
            title: 'Não foi possível remover'
          });
        } else {
          document.getElementById("questionnaire-" + item.id).remove();
          this.toast.success('Questionário removido');
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    copy: (0, _emberConcurrency.task)(function* (item) {
      let {
        value
      } = yield this.swal.confirm('Confirmar duplicação do questionário?');
      if (!value) {
        return;
      }
      try {
        const questionnaire = this.store.createRecord('questionnaire', item.toJSON());
        Ember.setProperties(questionnaire, {
          id: null,
          createdAt: null,
          updatedAt: null
        });
        let data = yield questionnaire.save();
        this.toast.success('Questionário duplicado');
        this.transitionToRoute('new-evaluation.edit', data.id);
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    createdQuestionnaire: (0, _emberConcurrency.task)(function* (item) {
      try {
        const questionnaire = this.store.createRecord('questionnaire', {
          pages: item.get('pages'),
          description: item.get('description')
        });
        let data = yield questionnaire.save();
        this.transitionToRoute('new-evaluation.edit', data);
      } catch (e) {
        this.swal.catch(e);
      }
    })
  });
});