define("avaliacao-talentrh/pods/pde/item-pde-action/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "VAEgJZuf",
    "block": "{\"symbols\":[\"@pdeAction\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-lg-8\"],[12],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"font-weight-bold\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n\\n    \"],[10,\"h6\"],[12],[2,\"\\n      \"],[10,\"strong\"],[12],[2,\"Criado Por:\"],[13],[2,\"\\n      \"],[1,[32,1,[\"owner\",\"fullName\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"h6\"],[12],[2,\"\\n      \"],[10,\"span\"],[15,0,[31,[\"badge badge-\",[32,1,[\"statusBadge\",\"color\"]]]]],[12],[2,\"\\n        \"],[1,[32,1,[\"statusBadge\",\"text\"]]],[2,\"\\n      \"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,1,[\"evidences\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"badge badge-success\"],[12],[2,\"\\n          \"],[1,[32,1,[\"evidences\",\"length\"]]],[2,\" Evidência(s)\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-lg-4 text-right align-itens-center align-self-center\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-primary btn-sm\"],[24,\"title\",\"Visualizar\"]],[[\"@route\",\"@model\"],[\"pde.show.show-action\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-eye\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/pde/item-pde-action/template.hbs"
    }
  });
});