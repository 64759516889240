define("avaliacao-talentrh/pods/manage-goals/export-goals/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    beforeModel(transition) {
      const filters = this.controllerFor('manage-goals').get('filters');
      if (!transition.from && (!filters || !filters.startAt || !filters.endAt)) {
        transition.abort();
        this.transitionTo('manage-goals');
      }
    },
    model() {
      const manageGoalsController = this.controllerFor('manage-goals');
      return {
        filters: manageGoalsController.get('filters'),
        nameFilter: manageGoalsController.get('nameFilter')
      };
    },
    deactivate() {
      const filters = this.controllerFor('manage-goals').get('filters');
      if (filters && filters.startAt && filters.endAt) {
        this.controllerFor('manage-goals').getGoals.perform();
      }
    }
  });
  _exports.default = _default;
});