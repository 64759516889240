define("avaliacao-talentrh/pods/indicators/pdi/controller", ["exports", "avaliacao-talentrh/pods/application/controller", "ember-concurrency"], function (_exports, _controller, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* globals moment */
  var _default = _controller.default.extend({
    swal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    router: Ember.inject.service(),
    accessProfile: Ember.computed.alias('userConfigs.accessProfile'),
    loadUserConfigs: Ember.computed.alias('userConfigs.loadUserConfigs'),
    // Default values
    openModalFilter: false,
    generalFilters: null,
    generalFiltersComponent: null,
    branchFilter: null,
    departmentFilter: null,
    jobFunctionFilter: null,
    jobRoleFilter: null,
    endDateFilter: null,
    startDateFilter: null,
    saveFilter: false,
    pdiProgressList: null,
    pdiEvidencesData: null,
    useGeneralFiltersPdiProgress: true,
    useGeneralFiltersPdiEvidencePeriod: true,
    init() {
      this._super(...arguments);
      this.getFilters();
      this.loadFilterData();
    },
    // Methods
    getFilters() {
      let generalFilters = {};
      let generalFiltersComponent = {};
      if (this.branchFilter) {
        generalFilters.branchs = this.branchFilter.map(b => b.id);
        generalFiltersComponent.branchs = this.branchFilter;
      }
      if (this.departmentFilter) {
        generalFilters.departments = this.departmentFilter.map(d => d.id);
        generalFiltersComponent.departments = this.departmentFilter;
      }
      if (this.jobFunctionFilter) {
        generalFilters.jobFunctions = this.jobFunctionFilter.map(jf => jf.id);
        generalFiltersComponent.jobFunctions = this.jobFunctionFilter;
      }
      if (this.jobRoleFilter) {
        generalFilters.jobRoles = this.jobRoleFilter.map(jr => jr.id);
        generalFiltersComponent.jobRoles = this.jobRoleFilter;
      }
      if (this.startDateFilter) {
        generalFilters.startAt = moment(this.startDateFilter).format('YYYY-MM-DD');
        generalFiltersComponent.startAt = generalFilters.startAt;
      }
      if (this.endDateFilter) {
        generalFilters.endAt = moment(this.endDateFilter).format('YYYY-MM-DD');
        generalFiltersComponent.endAt = generalFilters.endAt;
      }
      generalFilters.ledType = 'a';
      Ember.setProperties(this, {
        generalFilters,
        generalFiltersComponent
      });
    },
    // Carrega filtros do localStorage e iniciar o carregamento dos indicadores
    loadFilterData() {
      let storageFilter = this.getStorageFilters() || false;
      if (storageFilter) {
        let startDate = storageFilter.startDate || null;
        let endDate = storageFilter.endDate || null;
        let departments = storageFilter.departments || [];
        let jobFunctions = storageFilter.jobFunctions || [];
        let jobRoles = storageFilter.jobRoles || [];
        let branchs = storageFilter.branchs || [];
        Ember.setProperties(this, {
          saveFilter: true,
          branchFilter: branchs,
          departmentFilter: departments,
          jobFunctionFilter: jobFunctions,
          jobRoleFilter: jobRoles,
          endDateFilter: endDate ? moment(endDate) : null,
          startDateFilter: startDate ? moment(startDate) : null
        });
        Ember.set(this, 'generalFilters', {
          startDate,
          endDate,
          departments,
          jobFunctions,
          jobRoles,
          branchs
        });
      }
      this.loadIndicators();
    },
    // Retorna os filtros do localStorage ou FALSE caso ainda não exista
    getStorageFilters() {
      let storageData = localStorage.getItem('pdiIndicatorFilters');
      try {
        if (storageData) {
          return JSON.parse(storageData);
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    loadIndicators() {
      this.getFilters();
      if (this.useGeneralFiltersPdiProgress) {
        this.getPdiProgress.perform();
      }
      if (this.useGeneralFiltersPdiEvidencePeriod) {
        this.getPdiEvidencesPeriod.perform();
      }
    },
    // Tasks
    getPdiProgress: (0, _emberConcurrency.task)(function* () {
      try {
        this.swal.loading("Buscando...");
        const list = yield this.ajax.request('/pdiActions/findPdiProgress', {
          data: this.generalFilters
        });
        Ember.set(this, 'pdiProgressList', list);
        this.swal.close();
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    getPdiEvidencesPeriod: (0, _emberConcurrency.task)(function* () {
      try {
        this.swal.loading("Buscando...");
        const data = yield this.ajax.request('/pdievidences/findPdiEvidencesPeriod', {
          data: this.generalFilters
        });
        Ember.set(this, 'pdiEvidencesData', data);
        this.swal.close();
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    // Actions
    actions: {
      generateIndicators() {
        if (!this.endDateFilter || !this.startDateFilter) {
          return this.swal.warning('Data Inicial e Data Final são obrigatórios');
        }
        if (this.endDateFilter && this.startDateFilter) {
          if (moment(this.endDateFilter).isBefore(this.startDateFilter)) {
            return this.swal.warning('A data inicial precisa ser igual ou anterior a data final');
          }
        }
        this.loadIndicators();
        if (this.saveFilter) {
          let storageFilters = {
            startDate: this.generalFilters.startAt,
            endDate: this.generalFilters.endAt
          };
          if (this.generalFilters.branchs) {
            storageFilters.branchs = this.branchFilter.map(b => {
              return {
                id: b.id,
                name: b.name
              };
            });
          }
          if (this.generalFilters.departments) {
            storageFilters.departments = this.departmentFilter.map(d => {
              return {
                id: d.id,
                name: d.name
              };
            });
          }
          if (this.generalFilters.jobRoles) {
            storageFilters.jobRoles = this.jobRoleFilter.map(jr => {
              return {
                id: jr.id,
                title: jr.title
              };
            });
          }
          if (this.generalFilters.jobFunctions) {
            storageFilters.jobFunctions = this.jobFunctionFilter.map(jf => {
              return {
                id: jf.id,
                title: jf.title
              };
            });
          }
          localStorage.setItem('pdiIndicatorFilters', JSON.stringify(storageFilters));
        }
        Ember.set(this, 'openModalFilter', false);
      },
      clearFilters() {
        localStorage.removeItem('pdiIndicatorFilters');
        Ember.setProperties(this, {
          branchFilter: null,
          departmentFilter: null,
          jobFunctionFilter: null,
          jobRoleFilter: null,
          endDateFilter: null,
          startDateFilter: null,
          generalFilters: null
        });
      }
    }
  });
  _exports.default = _default;
});