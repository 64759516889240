define("avaliacao-talentrh/pods/pdi/show/show-action/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    swal: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    pdiAction: Ember.computed.alias('model'),
    foundEvidences: Ember.computed.and('canManageEvidence', 'pdiAction.evidences.length'),
    canManageEvidence: Ember.computed('userConfigs.sessionUser', 'pdiAction.{user,pdi}', function () {
      let pdi = Ember.get(this, 'pdiAction.pdi');
      let pdiAction = this.pdiAction;
      if (!pdi || !pdiAction) {
        return;
      }
      return !Ember.get(pdi, 'isExpired') && !Ember.get(pdiAction, 'isExpired') && Ember.get(pdi, 'status') !== 1 && Ember.get(pdiAction, 'status') !== 1 && Ember.get(this, 'userConfigs.sessionUser.id') === Ember.get(pdiAction, 'user.id');
    }),
    noCanManageMessage: Ember.computed('pdiAction', 'pdiAction.pdi', function () {
      let pdi = Ember.get(this, 'pdiAction.pdi');
      let pdiAction = this.pdiAction;
      let message;
      if (!pdi || !pdiAction) {
        return;
      }
      if (Ember.get(pdi, 'isExpired')) {
        message = 'Este PDI expirou';
      } else if (Ember.get(pdiAction, 'isExpired')) {
        message = 'Esta Ação de PDI expirou';
      } else if (Ember.get(pdi, 'status') === 1) {
        message = 'Este PDI foi finalizado';
      } else if (Ember.get(pdiAction, 'status') === 1) {
        message = 'Esta Ação de PDI foi finalizada';
      } else {
        message = 'Somente Leitura';
      }
      return message;
    })
  });
});