define("avaliacao-talentrh/pods/application/ability", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCan.Ability.extend({
    userConfigs: Ember.inject.service(),
    canAllowReports: Ember.computed('userConfigs.isLoaded', function () {
      let userConfigs = this.userConfigs;
      if (!userConfigs) {
        return;
      }
      let {
        accessProfile,
        sessionUser,
        companyConfiguration
      } = userConfigs;
      if (!accessProfile || !sessionUser || !companyConfiguration) {
        return;
      }
      return companyConfiguration.get('hasAVDModule') && (accessProfile.generalManager || sessionUser.userAppraisers.length || userConfigs.admin);
    }),
    canAllowIndicators: Ember.computed('userConfigs.isLoaded', function () {
      let userConfigs = this.userConfigs;
      if (!userConfigs) {
        return;
      }
      let {
        accessProfile,
        usersICanManage,
        companyConfiguration
      } = userConfigs;
      if (!accessProfile || !usersICanManage || !companyConfiguration) {
        return;
      }
      return companyConfiguration.get('hasAVDModule') && (accessProfile.generalManager || userConfigs.admin || usersICanManage.users.length);
    }),
    canAllowConfigurations: Ember.computed('userConfigs.isLoaded', function () {
      let userConfigs = this.userConfigs;
      if (!userConfigs) {
        return;
      }
      let {
        accessProfile,
        sessionUser,
        companyConfiguration
      } = userConfigs;
      if (!accessProfile || !sessionUser || !companyConfiguration) {
        return;
      }
      return companyConfiguration.get('hasAVDModule') && (accessProfile.generalManager || userConfigs.admin);
    }),
    canAllowPrintEvaluation: Ember.computed('userConfigs.isLoaded', function () {
      let userConfigs = this.userConfigs;
      if (!userConfigs) {
        return;
      }
      let {
        accessProfile,
        sessionUser,
        companyConfiguration
      } = userConfigs;
      if (!accessProfile || !companyConfiguration) {
        return;
      }
      return companyConfiguration.hasAVDModule && (userConfigs.admin || accessProfile.generalManager);
    }),
    canAllowAvdRegistrations: Ember.computed('userConfigs.isLoaded', function () {
      let userConfigs = this.userConfigs;
      if (!userConfigs) {
        return;
      }
      let {
        accessProfile,
        sessionUser,
        companyConfiguration
      } = userConfigs;
      if (!accessProfile || !sessionUser || !companyConfiguration) {
        return;
      }
      return companyConfiguration.get('hasAVDModule') && (accessProfile.generalManager || userConfigs.admin || accessProfile.allowAvdRegistration);
    }),
    canAllowCreateQuestionnaire: Ember.computed('userConfigs.isLoaded', function () {
      let userConfigs = this.userConfigs;
      if (!userConfigs) {
        return;
      }
      let {
        accessProfile,
        sessionUser,
        companyConfiguration
      } = userConfigs;
      if (!accessProfile || !sessionUser || !companyConfiguration) {
        return;
      }
      return companyConfiguration.hasAVDModule && (accessProfile.generalManager || accessProfile.allowAvdRegistration || userConfigs.admin);
    }),
    canAllowManageGoals: Ember.computed('userConfigs.isLoaded', function () {
      let userConfigs = this.userConfigs;
      if (!userConfigs) {
        return;
      }
      let {
        accessProfile,
        sessionUser,
        companyConfiguration
      } = userConfigs;
      if (!accessProfile || !sessionUser || !companyConfiguration) {
        return;
      }
      return companyConfiguration.hasAVDModule && (accessProfile.generalManager || userConfigs.admin || sessionUser.userAppraisers.length);
    }),
    canAllowExperienceRatingsReport: Ember.computed.and('userConfigs.companyConfiguration.hasAVDModule', 'userConfigs.companyConfiguration.singleExperienceEvaluation'),
    canAllowCreateFeedback: Ember.computed('userConfigs.isLoaded', function () {
      let userConfigs = this.userConfigs;
      if (!userConfigs) {
        return;
      }
      let {
        accessProfile,
        companyConfiguration,
        sessionUser
      } = userConfigs;
      if (!accessProfile || !companyConfiguration || !sessionUser) {
        return;
      }
      return companyConfiguration.hasAVDModule && (accessProfile.generalManager || userConfigs.admin || sessionUser.userAppraisers.length);
    }),
    canAllowNineBox: Ember.computed('userConfigs.isLoaded', function () {
      let userConfigs = this.userConfigs;
      if (!userConfigs) {
        return;
      }
      let {
        accessProfile,
        sessionUser,
        companyConfiguration
      } = userConfigs;
      if (!accessProfile || !sessionUser || !companyConfiguration) {
        return;
      }
      return companyConfiguration.get('hasAVDModule') && companyConfiguration.useNineBox && (accessProfile.generalManager || sessionUser.userAppraisers.length || accessProfile.admin);
    })
  });
});