define("avaliacao-talentrh/helpers/is-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isEmpty = isEmpty;
  function isEmpty(params /*, hash*/) {
    let value = params[0];
    return !value && value !== 0 ? 'is-empty' : '';
  }
  var _default = Ember.Helper.helper(isEmpty);
  _exports.default = _default;
});