define("avaliacao-talentrh/pods/feedback-request/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    // Services
    userConfigs: Ember.inject.service(),
    actions: {
      tabFilters(filter) {
        let filters = {};
        let sessionUserId = this.userConfigs.sessionUser.id;
        if (filter === "user") {
          filters.user = sessionUserId;
        } else if (filter === "createdBy") {
          filters.createdBy = sessionUserId;
        }
        Ember.set(this, 'filters', filters);
      }
    }
  });
});