define("avaliacao-talentrh/pods/evaluation/answer-consensus/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    // Services
    session: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    // Lifecycle hooks
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model({
      id
    }) {
      return Ember.RSVP.hash({
        employeeEvaluation: this.store.findRecord('employee-evaluation', id, {
          reload: true
        }),
        answerFor: 'consensus'
      });
    },
    afterModel(model) {
      let appraiserConcluded = Ember.get(model, 'employeeEvaluation.appraiserConcluded');
      let appraisedConcluded = Ember.get(model, 'employeeEvaluation.appraisedConcluded');
      let consensusConcluded = Ember.get(model, 'employeeEvaluation.consensusConcluded');
      let isConcluded = Ember.get(model, 'employeeEvaluation.isConcluded');
      if (!appraiserConcluded || !appraisedConcluded || isConcluded || consensusConcluded) {
        this.replaceWith('/evaluation/answer-forbidden');
      }
    }
  });
});