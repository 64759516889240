define("avaliacao-talentrh/pods/analytics/nine-box/filter-modal/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let AnalyticsNineBoxFilterModalComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.alias('userConfigs.companyConfiguration.showJobFunction'), _dec4 = Ember.computed.alias('userConfigs.accessProfile'), _dec5 = Ember.computed.or('accessProfile.admin', 'accessProfile.generalManager'), _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class AnalyticsNineBoxFilterModalComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "userConfigs", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "showJobFunction", _descriptor3, this);
      _initializerDefineProperty(this, "accessProfile", _descriptor4, this);
      _initializerDefineProperty(this, "notShowLedFilter", _descriptor5, this);
      _initializerDefineProperty(this, "filters", _descriptor6, this);
    }
    usersSelected(users, select) {
      select.actions.search('');
      this.filters = {
        ...this.filters,
        users,
        branch: null,
        department: null,
        jobFunction: null,
        jobRole: null
      };
    }
    onClose() {
      this.filters = {
        ledType: 'a'
      };
      if (this.args.onClose) {
        this.args.onClose();
      }
    }
    updateLedTypeFilter() {
      this.filters = {
        ...this.filters,
        users: []
      };
    }
    clearFilter() {
      this.filters = {
        ledType: 'a'
      };
    }
    onFilter() {
      if (!this.filters.cycle) {
        return this.swal.warning('Ciclo é um campo obrigatório.');
      }
      const filters = {};
      if (this.filters.users && this.filters.users.length) {
        filters.usersIds = this.filters.users.map(user => user.id);
      }
      if (this.filters.cycle) {
        filters.id = this.filters.cycle.id;
      }
      if (this.filters.branch) {
        filters.branch = this.filters.branch.id;
      }
      if (this.filters.department) {
        filters.department = this.filters.department.id;
      }
      if (this.filters.jobFunction) {
        filters.jobFunction = this.filters.jobFunction.id;
      }
      if (this.filters.jobRole) {
        filters.jobRole = this.filters.jobRole.id;
      }
      if (this.filters.ledType) {
        filters.ledType = this.filters.ledType;
      }
      if (this.args.onFilter) {
        this.args.onFilter(filters);
        this.filters = {
          ledType: 'a'
        };
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "userConfigs", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showJobFunction", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "accessProfile", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "notShowLedFilter", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        ledType: 'a'
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "usersSelected", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "usersSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateLedTypeFilter", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "updateLedTypeFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilter", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFilter", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onFilter"), _class.prototype)), _class));
  _exports.default = AnalyticsNineBoxFilterModalComponent;
});