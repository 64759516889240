define("avaliacao-talentrh/pods/external-evaluation/form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Services
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    // Lifecycle Hooks
    didInsertElement() {
      this._super(...arguments);
      this.onLoadForm(this.save);
    },
    // Tasks
    save: (0, _emberConcurrency.task)(function* () {
      let model = this.externalEvaluation;
      if (!model.validations.isValid) {
        return this.swal.validation(model.validations);
      }
      try {
        let created = yield model.save();
        this.swal.success('Registro salvo com sucesso.');
        if (this.afterSave) {
          this.afterSave(created);
        }
        this.router.transitionTo('external-evaluation.show', created.id);
      } catch (e) {
        return this.swal.catch(e);
      }
    })
  });
});