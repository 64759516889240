define("avaliacao-talentrh/models/company", ["exports", "ember-data", "avaliacao-talentrh/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    cnpj: _emberData.default.attr('string'),
    serial: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    complement: _emberData.default.attr('string'),
    zipcode: _emberData.default.attr('string'),
    neighborhood: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    number: _emberData.default.attr('string'),
    registerToken: _emberData.default.attr('string'),
    logoUrl: _emberData.default.attr('string'),
    tradingName: _emberData.default.attr('string'),
    standardName: _emberData.default.attr('string'),
    city: _emberData.default.belongsTo('city'),
    companyName: Ember.computed.or('standardName', 'tradingName', 'name'),
    logo: Ember.computed('logoUrl', function () {
      return _environment.default.bucketUrl + `${this.logoUrl}`;
    })
  });
});