define("avaliacao-talentrh/models/employee-external-evaluation", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    appraisedUser: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Informe para qual colaborador você esta cadastrando a avaliação externa.'
    }),
    date: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Informe a data da avaliação externa.'
    }),
    description: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Descreva qual a avaliação externa.'
    })
  });
  var _default = _exports.default = _model.default.extend(Validations, {
    date: (0, _attr.default)('date'),
    description: (0, _attr.default)('string'),
    result: (0, _attr.default)('string'),
    appraisedUser: (0, _relationships.belongsTo)('user'),
    appraiserUser: (0, _relationships.belongsTo)('user'),
    company: (0, _relationships.belongsTo)('company')
  });
});