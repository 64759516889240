define("avaliacao-talentrh/pods/external-evaluation/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    deactivate() {
      this.controller.model.rollbackAttributes();
    }
  });
});