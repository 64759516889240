define("avaliacao-talentrh/pods/home/btn-action/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Ay6eZU5K",
    "block": "{\"symbols\":[\"@icon\",\"@title\"],\"statements\":[[10,\"div\"],[14,0,\"text-center p-3 text-body\"],[12],[2,\"\\n  \"],[10,\"i\"],[15,0,[31,[\"fa \",[32,1],\" fa-2x text-primary\"]]],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n  \"],[10,\"h6\"],[14,0,\"mb-0 mt-3\"],[12],[1,[32,2]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"i\"],[14,\"aria-hidden\",\"\"],[14,0,\"position-absolute centralize opacity-1 fa fa-edit fa-4x text-primary\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/home/btn-action/template.hbs"
    }
  });
});