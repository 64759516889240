define("avaliacao-talentrh/pods/pdi/item-pdi-evidence/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['list-group-item', 'py-3'],
    // Services
    swal: Ember.inject.service(),
    showComments: false,
    // Concurrency Tasks
    deleteEvidence: (0, _emberConcurrency.task)(function* (record) {
      try {
        let result = yield this.swal.confirm('Tem certeza que deseja remover esta Evidência?');
        if (!result.value) {
          return;
        }
        yield record.destroyRecord();
        this.swal.success('Removido com sucesso.');
      } catch (e) {
        this.swal.catch(e);
      }
    })
  });
  _exports.default = _default;
});