define("avaliacao-talentrh/pods/components/answer-evaluation/question/alternative/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "kjXbRnFr",
    "block": "{\"symbols\":[\"@possibleAnswer\",\"@question\",\"@onSelectAnswer\"],\"statements\":[[8,\"ui-radio\",[[24,0,\"d-block\"],[16,\"title\",[32,1,[\"definition\"]]]],[[\"@option\",\"@selected\",\"@onChange\"],[[32,1,[\"percentage\"]],[32,2,[\"answer\"]],[30,[36,0],[[32,0],[32,3],[32,1]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"strong\"],[14,0,\"ml-1 text-muted\"],[12],[2,\"\\n    \"],[1,[32,1,[\"description\"]]],[2,\"\\n\"],[6,[37,1],[[32,1,[\"needJustification\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[14,0,\"fa fa-exclamation-circle fa-sm ml-1 text-warning\"],[14,\"title\",\"Requer Justificativa\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/components/answer-evaluation/question/alternative/template.hbs"
    }
  });
});