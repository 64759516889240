define("avaliacao-talentrh/pods/application/controller", ["exports", "avaliacao-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    notAllowed: Ember.computed.or('userConfigs.isLoaded', 'currentlyLoading'),
    isReady: Ember.computed('session.{isAuthenticated,user}', 'userConfigs.{isLoaded,loadUserConfigs}', function () {
      if (!this.userConfigs.isLoaded) {
        this.userConfigs.loadUserConfigs.perform();
      }
      this.send('reloadRoute');
      return this.session.isAuthenticated && this.userConfigs.isLoaded && this.session.user;
    }),
    isProductionBuild: Ember.computed(function () {
      return _environment.default.environment === 'production';
    }),
    actions: {
      logout() {
        this.session.invalidate();
      }
    }
  });
});