define("avaliacao-talentrh/pods/pdi/form/component", ["exports", "ember-concurrency", "moment", "avaliacao-talentrh/models/pdi"], function (_exports, _emberConcurrency, _moment, _pdi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Services
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    router: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    // Life Cycle
    didInsertElement() {
      this._super(...arguments);
      this.onLoadForm(this.save);
    },
    pdis: null,
    // Computed Properties
    showAnnotations: Ember.computed('pdi.{createdBy.id,isNew}', 'userConfigs.sessionUser.id', function () {
      return Ember.get(this, 'pdi.isNew') || Ember.get(this, 'pdi.createdBy.id') === Ember.get(this, 'userConfigs.sessionUser.id');
    }),
    showSelectCollaborators: Ember.computed('createForMe', 'userConfigs.usersICanManage', function () {
      let {
        usersICanManage,
        sessionUser,
        admin
      } = this.userConfigs;
      if (!usersICanManage || !sessionUser) {
        return;
      }
      return usersICanManage.users.length || usersICanManage.canManageAll || admin && !this.createForMe;
    }),
    createForMe: Ember.computed('userConfigs.usersICanManage', {
      get() {
        let {
          usersICanManage,
          sessionUser
        } = this.userConfigs;
        if (!usersICanManage || !sessionUser) {
          return;
        }
        return !(usersICanManage.users.length || usersICanManage.canManageAll || sessionUser.admin);
      },
      set(key, selected) {
        return selected;
      }
    }),
    // Concurrency Tasks
    save: (0, _emberConcurrency.task)(function* () {
      let {
        sessionUser
      } = this.userConfigs;
      let model = this.pdi;
      if (!this.showSelectCollaborators && !this.createForMe) {
        return this.swal.warning('Você não é líder, selecione a opção para criar para você mesmo.');
      }
      if (this.createForMe) {
        Ember.set(model, 'user', sessionUser);
        Ember.set(model, 'createdBy', sessionUser);
      }
      if (!model.validations.isValid) {
        return this.swal.validation(model.validations);
      }

      // Valida a data de expiração dos pdis do usuário para evitar que se crie dois pdi no mesmo período
      const userPdis = yield this.ajax.request(`/pdi/${model.user.get('id')}/findAllUserPdis`);
      let invalidExpirationDate = [];
      userPdis.forEach(pdi => {
        if ((0, _moment.default)(new Date()).isSameOrBefore(pdi.expirationDate) || (0, _moment.default)(model.expirationDate).isSameOrBefore(pdi.expirationDate)) {
          invalidExpirationDate.push(true);
        } else {
          invalidExpirationDate.push(false);
        }
      });
      if (invalidExpirationDate.length && invalidExpirationDate.includes(true) && model.isNew) {
        return this.swal.warning('Já existe um PDI cadastrado para este período');
      }
      try {
        let created = yield model.save();
        this.swal.success('O PDI foi salvo com sucesso.');
        this.pdisExpirationDate = [];
        if (this.afterSave) {
          this.afterSave(created);
        }
        this.router.transitionTo('pdi.show', created.id);
      } catch (e) {
        return this.swal.catch(e);
      }
    })
  });
});