define("avaliacao-talentrh/models/job-role", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    active: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    visible: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    description: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    company: (0, _model.belongsTo)('company'),
    jobFunctions: (0, _model.hasMany)('job-function', {
      inverse: 'jobRole'
    }),
    users: (0, _model.hasMany)('user', {
      inverse: 'jobRole'
    }),
    skills: (0, _model.hasMany)('skill', {
      inverse: 'jobRoles'
    }),
    goals: (0, _model.hasMany)('goal', {
      inverse: 'jobRoles'
    })
  });
});