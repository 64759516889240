define("avaliacao-talentrh/pods/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "F+KgJ8YJ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ui-login\",[],[[\"@icon\",\"@appName\",\"@onAuthenticate\"],[\"avd\",\"Gestão de Desempenho\",[30,[36,0],[[32,0,[\"authenticate\"]]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"perform\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/login/template.hbs"
    }
  });
});