define("avaliacao-talentrh/pods/manage-goals/show-achieve/item/component", ["exports", "moment", "avaliacao-talentrh/utils/formatDate"], function (_exports, _moment, _formatDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    userConfigs: Ember.inject.service(),
    tagName: 'tr',
    unit: null,
    openInfo: false,
    value: Ember.computed.alias('goalAchieved.value'),
    lastUpdate: Ember.computed('goalAchieved.{ProjectDetails,taskDetails}', function () {
      if (this.goalAchieved.taskDetails) {
        return `Atualizado em ${this.goalAchieved.taskDetails[0].lastUpdate}`;
      } else if (this.goalAchieved.ProjectDetails) {
        return `Atualizado em ${this.goalAchieved.ProjectDetails[0].lastUpdate}`;
      }
      return null;
    }),
    showButton: Ember.computed.or('goalAchieved.projectDetails', 'goalAchieved.taskDetails'),
    formatedValue: Ember.computed('goalAchieved.{unit,userGoal}', 'unit', 'value', function () {
      if (this.unit) {
        if (this.unit === 'percentage') {
          const value = Number(this.value.replace(',', '.'));
          return Intl.NumberFormat('pt-br', {
            style: 'percent',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          }).format(value);
        }
        if (this.unit === 'monetary') {
          return Intl.NumberFormat('pt-br', {
            currency: 'BRL',
            style: 'currency',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          }).format(this.value);
        }
        if (this.unit === 'qualitative') {
          return this.value === 'S' ? 'Atingiu' : 'Não atingiu';
        }
        if (this.unit === 'hours') {
          let str = '';
          let duration = _moment.default.duration(+this.value, 'minutes');
          let hours = parseInt(duration.as('hours'));
          if (hours) {
            str += `${hours}h `;
          }
          if (duration.get('minutes')) {
            str += `${duration.get('minutes')}min`;
          }
          if (!str) {
            return 'Não informado';
          }
          return str;
        }
      }
      return this.value;
    }),
    formatedDate: Ember.computed('goalAchieved.achieveDate', function () {
      const timezone = new Date().getTimezoneOffset() / 60;
      let date = (0, _moment.default)(this.goalAchieved.achieveDate).add(6, 'h');
      date = date.subtract(timezone, 'h');
      return (0, _moment.default)((0, _formatDate.default)(date), "DD/MM/YYYY HH:mm").format('DD/MM/YYYY');
    }),
    didInsertElement() {
      this._super(...arguments);
    },
    actions: {
      formatData() {
        if (this.goalAchieved.projectDetails) {
          Ember.set(this, "goalAchieved.projectDetails", JSON.parse(this.goalAchieved.projectDetails));
        }
        if (this.goalAchieved.taskDetails) {
          Ember.set(this, "goalAchieved.taskDetails", JSON.parse(this.goalAchieved.taskDetails));
        }
      },
      async retrieveInfo() {
        const goal = await this.goalAchieved.get("userGoal").get("goal");
        Ember.set(this, 'unit', goal.unit);
      },
      showInfo() {
        Ember.set(this, "openInfo", !this.openInfo);
      }
    }
  });
});