define("avaliacao-talentrh/pods/components/answer-evaluation/question/justification/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['answer-evaluation__question__justification', 'form-group'],
    classNameBindings: ['selectedAnswer.needJustification:d-block:d-none'],
    unjustifiedClassError: Ember.computed('finishError', 'question.justification', function () {
      if (!this.finishError || !this.finishError.unjustifiedQuestions || this.question.justification) {
        return;
      }
      let hasError = this.finishError.unjustifiedQuestions.find(obj => {
        return obj.section === this.sectionIndex && obj.question === this.questionIndex;
      });
      return hasError ? 'border border-danger' : '';
    })
  });
  _exports.default = _default;
});