define("avaliacao-talentrh/models/employee-evaluation-team", ["exports", "@ember-data/model", "ember-data/relationships"], function (_exports, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* globals moment */
  var _default = _exports.default = _model.default.extend({
    employeeEvaluation: (0, _relationships.belongsTo)('employee-evaluation'),
    appraisedUser: (0, _relationships.belongsTo)('user'),
    appraiserUser: (0, _relationships.belongsTo)('user'),
    appraiserAnswer: (0, _model.attr)('raw'),
    appraiserTotal: (0, _model.attr)('number'),
    appraiserConcluded: (0, _model.attr)('boolean'),
    createdAt: (0, _model.attr)('date'),
    limitDate: Ember.computed('createdAt', function () {
      var endDate = moment(`${this.createdAt}`).endOf('month');
      return endDate;
    }),
    isConcluded: Ember.computed.reads('appraiserConcluded')
  });
});