define("avaliacao-talentrh/pods/indicators/collaborator-evolution/controller", ["exports", "avaliacao-talentrh/pods/application/controller", "ember-concurrency"], function (_exports, _controller, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* globals moment */
  var _default = _controller.default.extend({
    swal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    // Default values
    openModalFilter: false,
    generalFilters: null,
    generalFiltersComponent: null,
    endDateFilter: null,
    startDateFilter: null,
    branchFilter: null,
    departmentFilter: null,
    jobRoleFilter: null,
    jobFunctionFilter: null,
    listUserEmployee: null,
    userFilter: null,
    user: null,
    showLedType: true,
    ledType: null,
    init() {
      this._super(...arguments);
      let {
        accessProfile
      } = this.userConfigs;
      if (this.userConfigs.admin || accessProfile && accessProfile.generalManager) {
        this.showLedType = false;
      } else {
        this.ledType = 'd';
      }
    },
    // Methods
    getFilters() {
      let generalFilters = {};
      if (this.startDateFilter && this.endDateFilter) {
        let startDate = moment(this.startDateFilter).format('YYYY-MM-DD') + ' 00:00:00';
        let finalDate = moment(this.endDateFilter).format('YYYY-MM-DD') + ' 23:59:59';
        generalFilters.createdAt = [startDate, finalDate];
      }
      if (this.userFilter && this.userFilter.length) {
        generalFilters.user = this.userFilter.map(b => parseInt(b.id));
      }
      if (!generalFilters.user) {
        if (this.branchFilter && this.branchFilter.length) {
          generalFilters.branch = this.branchFilter.map(b => parseInt(b.id));
        }
        if (this.departmentFilter && this.departmentFilter.length) {
          generalFilters.department = this.departmentFilter.map(b => parseInt(b.id));
        }
        if (this.jobRoleFilter && this.jobRoleFilter.length) {
          generalFilters.jobRole = this.jobRoleFilter.map(b => parseInt(b.id));
        }
        if (this.jobFunctionFilter && this.jobFunctionFilter.length) {
          generalFilters.jobFunction = this.jobFunctionFilter.map(b => parseInt(b.id));
        }
      }
      generalFilters.concluded = 1;
      generalFilters.ledType = this.ledType;
      Ember.setProperties(this, {
        generalFilters
      });
    },
    getSelectedBranchesIds: Ember.computed('branchFilter.length', function () {
      if (this.branchFilter && this.branchFilter.length) {
        return this.branchFilter.map(b => b.id);
      }
      return null;
    }),
    // Tasks
    getEmployeeEvaluationProgress: (0, _emberConcurrency.task)(function* () {
      try {
        this.getFilters();
        this.swal.loading("Buscando...");
        Ember.set(this, 'listUserEmployee', {});
        const listUserEmployee = yield this.ajax.request('/employeeEvaluations/findEmployeeByUsers', {
          data: this.generalFilters
        });
        if (listUserEmployee && listUserEmployee.users && listUserEmployee.users.length) {
          Ember.set(this, 'listUserEmployee', listUserEmployee);
          this.swal.close();
          Ember.set(this, 'openModalFilter', false);
        } else {
          return this.swal.warning('Não foram localizados registros de avaliações com os filtros informados');
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    // Actions
    actions: {
      generateIndicators() {
        if (!this.endDateFilter || !this.startDateFilter) {
          return this.swal.warning('Data Inicial e Data Final são obrigatórios');
        }
        if (this.endDateFilter && this.startDateFilter) {
          if (moment(this.endDateFilter).isBefore(this.startDateFilter)) {
            return this.swal.warning('A data inicial precisa ser igual ou anterior a data final');
          }
        }
        this.getEmployeeEvaluationProgress.perform();
      },
      clearFilters() {
        Ember.setProperties(this, {
          generalFilters: null,
          generalFiltersComponent: null,
          endDateFilter: null,
          startDateFilter: null,
          branchFilter: null,
          departmentFilter: null,
          jobRoleFilter: null,
          jobFunctionFilter: null,
          userFilter: null
        });
      },
      reloadUserFilter() {
        let userFilter = this.userFilter;
        if (userFilter && !userFilter.length) {
          Ember.set(this, 'userFilter', null);
        }
      },
      updateFilter() {
        let userFilter = this.userFilter;
        if (userFilter) {
          Ember.set(this, 'userFilter', null);
        }
      }
    }
  });
  _exports.default = _default;
});