define("avaliacao-talentrh/session-stores/application", ["exports", "ember-simple-auth/session-stores/cookie", "avaliacao-talentrh/config/environment"], function (_exports, _cookie, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _cookie.default.extend({
    sameSite: 'Strict',
    cookieDomain: _environment.default.domain
  });
  _exports.default = _default;
});