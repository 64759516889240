define("avaliacao-talentrh/models/appointment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* globals moment */
  var _default = _exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    start: _emberData.default.attr('date'),
    end: _emberData.default.attr('date'),
    company: _emberData.default.belongsTo('company'),
    user: _emberData.default.belongsTo('user'),
    startDate: Ember.computed('start', function () {
      var vDate = moment(`${this.start}`);
      if (vDate.isValid()) {
        return vDate.format('DD/MM/YYYY');
      } else {
        return null;
      }
    }),
    startTime: Ember.computed('start', function () {
      var vDate = moment(`${this.start}`);
      if (vDate.isValid()) {
        return vDate.format('HH:mm');
      } else {
        return null;
      }
    }),
    endDate: Ember.computed('end', function () {
      var vDate = moment(`${this.end}`);
      if (vDate.isValid()) {
        return vDate.format('DD/MM/YYYY');
      } else {
        return null;
      }
    }),
    endTime: Ember.computed('end', function () {
      var vDate = moment(`${this.end}`);
      if (vDate.isValid()) {
        return vDate.format('HH:mm');
      } else {
        return null;
      }
    })
  });
});