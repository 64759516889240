define("avaliacao-talentrh/pods/feedback-request/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    userConfigs: Ember.inject.service(),
    router: Ember.inject.service(),
    accessProfile: Ember.computed.alias('userConfigs.accessProfile'),
    sessionUserId: Ember.computed.alias('userConfigs.sessionUser.id'),
    usersICanManage: Ember.computed.alias('userConfigs.usersICanManage.users'),
    afterModel(model) {
      let toUser = model.user.get('id');
      let fromUser = model.createdBy.get('id');
      const isPartOfTheRequest = this.sessionUserId === fromUser || this.sessionUserId === toUser;
      const allowed = this.accessProfile.admin || this.accessProfile.generalManager || isPartOfTheRequest;
      if (!allowed) {
        return this.router.transitionTo('errors.forbidden');
      }
    }
  });
});