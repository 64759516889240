define("avaliacao-talentrh/pods/manage-goals/export-goals/controller", ["exports", "ember-concurrency", "avaliacao-talentrh/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    swal: Ember.inject.service(),
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    router: Ember.inject.service(),
    // One line copmputed
    filters: Ember.computed.alias('model.filters'),
    nameFilter: Ember.computed.alias('model.nameFilter'),
    // Tasks
    exportGoals: (0, _emberConcurrency.task)(function* () {
      try {
        if (this.nameFilter) {
          this.filters.term = this.nameFilter;
        }
        let url = `${_environment.default.apiBaseUrl}/userGoals/export`;
        const search = new URLSearchParams(this.filters).toString();
        url += `?${search}`;
        this.swal.loading('Carregando...');
        let xlxs = yield fetch(url, {
          headers: {
            Authorization: `Bearer ${this.session.token}`
          }
        });
        let filename = yield xlxs.headers.get('content-disposition').match(/filename="(.+)"/)[1];
        xlxs = yield xlxs.blob();
        let element = document.createElement('a');
        element.href = URL.createObjectURL(xlxs);
        element.download = filename;
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        this.swal.close();
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    // Actions
    actions: {
      onCloseModal() {
        this.router.transitionTo('manage-goals');
      }
    }
  });
  _exports.default = _default;
});