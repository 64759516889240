define("avaliacao-talentrh/pods/pdi/form-percentage-action/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Services
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    store: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    defaultPercentage: false,
    // Single line computed properties
    pdiActions: Ember.computed.alias('pdi.pdiActions'),
    countActions: Ember.computed.alias('pdi.pdiActions.length'),
    isLeader: Ember.computed.alias('userConfigs.sessionUser.isLeader'),
    isAdmin: Ember.computed.alias('userConfigs.sessionUser.admin'),
    isGeneralManager: Ember.computed.alias('userConfigs.accessProfile.generalManager'),
    canChangePercentages: Ember.computed.or('isLeader', 'isAdmin', 'isGeneralManager'),
    // Multiline computed properties
    sumPercentages: Ember.computed('pdiActions.@each.percentage', function () {
      let percentages = this.pdiActions.mapBy('percentage');
      percentages = percentages.map(n => Number(n));
      return percentages.reduce((sum, current) => sum + current);
    }),
    classColorTotal: Ember.computed('sumPercentages', function () {
      let color = 'text-success';
      if (this.sumPercentages !== 100) {
        color = 'text-danger';
      }
      return color;
    }),
    // Life cycles hooks
    didInsertElement() {
      this._super(...arguments);
      this.checkDefaultPercentage.perform();
      this.onLoadForm(this.save);
    },
    didReceiveAttrs() {
      this._super(...arguments);
      let pdiActions = this.pdiActions;
      Ember.RSVP.map(pdiActions.toArray(), pdiAction => {
        return this.recalculatePdiRating.perform(pdiAction);
      });
    },
    // Tasks
    checkDefaultPercentage: (0, _emberConcurrency.task)(function* () {
      try {
        if (this.pdiActions.length < 2) {
          Ember.set(this, 'defaultPercentage', true);
          return;
        }
        if (this.canChangePercentages) {
          let {
            value
          } = yield this.swal.confirm('Distribuir os percentuais automaticamente? ', {
            cancelButtonText: 'Escolher manual'
          });
          if (value) {
            this.send('reloadDefaultPercentage');
          } else {
            return;
          }
        } else {
          Ember.set(this, 'defaultPercentage', true);
        }
      } catch (e) {
        return this.swal.catch(e);
      }
    }),
    save: (0, _emberConcurrency.task)(function* () {
      try {
        let pdiActions = this.pdiActions;
        let percentages = pdiActions.mapBy('percentage');
        percentages = percentages.map(n => Number(n));
        let sumPercentages = percentages.reduce((sum, current) => sum + current);
        if (Math.round(sumPercentages) !== 100) {
          return this.swal.warning('A soma dos percentuais deve ser igual a 100%');
        }
        yield Ember.RSVP.map(pdiActions.toArray(), pdiAction => {
          return this.recalculatePdiRating.perform(pdiAction);
        });
        this.swal.success('A ação de PDI foi salva com sucesso.');
        if (!this.pdiActionId) {
          this.router.transitionTo('pdi.show');
        } else {
          this.router.transitionTo('pdi.show.show-action', this.pdiActionId);
        }
      } catch (e) {
        return this.swal.catch(e);
      }
    }),
    recalculatePdiRating: (0, _emberConcurrency.task)(function* (pdiAction) {
      let orderRatingNoteItem = pdiAction.get('ratingNotePdiAction.orderRatingNoteItem');
      let ratingNotePdiAction = pdiAction.get('ratingNotePdiAction');
      if (orderRatingNoteItem) {
        ratingNotePdiAction = yield this.store.peekRecord('rating-note-pdi-action', ratingNotePdiAction.get('id'));
        yield ratingNotePdiAction.save();
        Ember.set(pdiAction, 'partialScore', ratingNotePdiAction.pdiActionNote * pdiAction.percentage / 100);
      }
      yield pdiAction.save();
    }),
    actions: {
      // Sem julgamentos, força o reload dos percentuais. Melhorar a lógica no futuro quando migrar para Octane.
      reloadDefaultPercentage() {
        if (this.defaultPercentage) {
          Ember.set(this, 'defaultPercentage', false);
        }
        Ember.set(this, 'defaultPercentage', true);
      }
    }
  });
});