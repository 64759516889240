define("avaliacao-talentrh/pods/pde/team-user-list/item/action-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8HfI+OyO",
    "block": "{\"symbols\":[\"@evidence\",\"@canEdit\"],\"statements\":[[10,\"td\"],[12],[2,\"\\n  \"],[10,\"i\"],[15,0,[31,[\"fa fa-\",[30,[36,2],[[32,1,[\"path\"]],\"paperclip\",\"align-left\"],null],\" fa-lg text-primary pr-3\"]]],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n  \"],[1,[30,[36,3],[[32,1,[\"description\"]]],[[\"limit\"],[50]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[14,0,\"text-right\"],[12],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"btn btn-link\"],[24,\"title\",\"Visualizar\"]],[[\"@route\",\"@model\"],[\"pde.show.show-action.show-evidence\",[34,4,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-eye\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"button\"],[14,0,\"btn btn-link text-danger\"],[14,\"title\",\"Remover\"],[15,\"onclick\",[30,[36,1],[[35,0],[32,1]],null]],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-trash\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"deleteEvidence\",\"perform\",\"if\",\"truncate-text\",\"evidence\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/pde/team-user-list/item/action-item/template.hbs"
    }
  });
  _exports.default = _default;
});