define("avaliacao-talentrh/pods/feedback/ability", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCan.Ability.extend({
    userConfigs: Ember.inject.service(),
    canManageFeedback: Ember.computed('userConfigs.isLoaded', 'record.id', function () {
      let record = this.record;
      let userConfigs = this.userConfigs;
      if (!userConfigs || !record) {
        return;
      }
      return userConfigs.iCanManageUser(record.get('user.id'));
    }),
    canManageFollowup: Ember.computed('userConfigs.isLoaded', 'feedback.status', 'record.createdBy.id', function () {
      let record = this.record;
      let feedback = this.feedback;
      let userConfigs = this.userConfigs;
      if (!userConfigs || !record || !feedback) {
        return;
      }
      let {
        sessionUser
      } = userConfigs;
      if (!sessionUser) {
        return;
      }
      return feedback.get('status') !== 1 && sessionUser.get('id') === record.get('createdBy.id');
    })
  });
});