define("avaliacao-talentrh/pods/pdi/show/show-action/new-evidence/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    pdiAction: Ember.computed.alias('model'),
    saveEvidence: (0, _emberConcurrency.task)(function* () {
      try {
        let evidence = this.store.createRecord('pdi-evidence');
        evidence.set('description', this.description);
        evidence.set('pdiAction', this.pdiAction);
        yield evidence.save();
        this.swal.success('Salvo com sucesso!');
        this.transitionToRoute('pdi.show.show-action');
        return;
      } catch (e) {
        return this.swal.catch(e);
      }
    }).drop(),
    saveEvidenceWithAttachment: (0, _emberConcurrency.task)(function* (file) {
      let data = {
        type: file.blob.type,
        filename: file.blob.name,
        model: 'pdiEvidence',
        parentModel: 'pdiAction',
        parentModelId: this.get('pdiAction.id')
      };
      try {
        this.swal.loading('Estamos subindo seu anexo para o servidor, isso pode levar alguns segundos...');
        let response = yield this.ajax.request('s3/signedUploadUrl', {
          data: data
        });
        yield file.uploadBinary(response.url, {
          method: 'PUT'
        });
        response.pdiEvidences.description = this.description;
        this.store.pushPayload({
          pdiEvidences: response.pdiEvidences
        });
        yield this.store.peekRecord('pdi-evidence', response.pdiEvidences.id).save();
        this.swal.success('Salvo com sucesso!');
        this.transitionToRoute('pdi.show.show-action');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    actions: {
      selectedImage(file) {
        Ember.set(this, 'filePreview', {
          name: file.blob.name,
          type: file.blob.type
        });
        Ember.set(this, 'fileSelected', file);
      },
      save() {
        if (!this.description) {
          return this.swal.warning('É necessário informar a descrição.');
        }

        // Evidência com anexo
        if (this.fileSelected) {
          this.saveEvidenceWithAttachment.perform(this.fileSelected);
        } else {
          this.saveEvidence.perform();
        }
      }
    }
  });
  _exports.default = _default;
});