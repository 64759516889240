define("avaliacao-talentrh/utils/quiz", ["exports", "sweet-alert2"], function (_exports, _sweetAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = quiz;
  function quiz(model) {
    if (!model.get('description')) {
      _sweetAlert.default.fire('Ops!', 'Informe o Título do Questionário', 'warning');
      return false;
    }
    if (!model.get('pages.length')) {
      _sweetAlert.default.fire('Ops!', 'Informe ao menos uma Página do Questionário', 'warning');
      return false;
    } else {
      let result = true;

      /*
       * Caclula porcentagem das sessoes que não possuem porcentagem
       */
      const pagePercentage = calculaPorcentagem(model.get('pages'));
      model.get('pages').forEach(page => {
        if (!page.questions.length) {
          _sweetAlert.default.fire('Ops!', 'Informe ao menos uma Pergunta', 'warning');
          result = false;
        } else if (!page.description) {
          _sweetAlert.default.fire('Ops!', 'Informe o Nome da Seção', 'warning');
          result = false;
        } else {
          if (!page.percentage) {
            //Insere porcentagem nas sessões que estão em branco
            Ember.set(page, 'percentage', pagePercentage);
          }

          /*
           * Caclula porcentagem das questões que não possuem porcentagem
           */
          const questionPercentage = calculaPorcentagem(page.questions);
          page.questions.forEach(question => {
            if (!question.title) {
              _sweetAlert.default.fire('Ops!', 'Informe o Título da Pergunta', 'warning');
              result = false;
            }
            if (!question.alternatives.length) {
              _sweetAlert.default.fire('Ops!', `Informe ao menos uma Alternativa para a Pergunta ${question.title}`, 'warning');
              result = false;
            } else {
              if (!question.percentage) {
                //Insere porcentagem nas questões que estão em branco
                Ember.set(question, 'percentage', questionPercentage);
              }

              /*
               * Caclula porcentagem das alternativas que não possuem porcentagem
               */
              const alternativePercentage = calculaPorcentagem(question.alternatives);
              question.alternatives.forEach(alternative => {
                if (!alternative.percentage && alternative.percentage !== 0 && !alternative.ignoreToCalculate) {
                  //Insere porcentagem nas questões que estão em branco
                  Ember.set(alternative, 'percentage', alternativePercentage);
                }
                if (!alternative.description) {
                  _sweetAlert.default.fire('Ops!', `Informe uma Descrição para a Alternativa da Pergunta ${question.title}`, 'warning');
                  result = false;
                }
              });
            }
          });
        }
      });
      return result;
    }
  }
  function calculaPorcentagem(list) {
    const percent = list.filter(item => item.percentage);
    const noPercent = list.filter(item => !item.percentage);
    let valuePercent = 0;
    percent.forEach(item => {
      valuePercent += item.percentage;
    });
    return (100 - valuePercent) / noPercent.length;
  }
});