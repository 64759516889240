define("avaliacao-talentrh/pods/pde/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model() {
      return this.store.createRecord('pde');
    },
    deactivate() {
      this.controller.model.rollbackAttributes();
      Ember.set(this.controllerFor('pde'), 'filters', {});
    }
  });
  _exports.default = _default;
});