define("avaliacao-talentrh/pods/new-evaluation/edit/route", ["exports", "avaliacao-talentrh/utils/quiz"], function (_exports, _quiz) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model(params) {
      return this.store.findRecord('questionnaire', params.id);
    },
    actions: {
      save(model) {
        if ((0, _quiz.default)(model)) {
          model.save().then(() => {
            this.swal.success('Registro salvo com sucesso!');
          }).catch(() => {
            this.swal.success('Não foi possível salvar o registro!');
          });
        }
      }
    }
  });
  _exports.default = _default;
});