define("avaliacao-talentrh/pods/components/answer-evaluation/question/justification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZNMIifbn",
    "block": "{\"symbols\":[\"@question\"],\"statements\":[[10,\"label\"],[12],[2,\"Justificativa*\"],[13],[2,\"\\n\"],[8,\"textarea\",[[16,0,[31,[\"form-control \",[34,0]]]],[24,\"placeholder\",\"Informe uma justificativa...\"]],[[\"@value\"],[[32,1,[\"justification\"]]]],null],[2,\"\"]],\"hasEval\":false,\"upvars\":[\"unjustifiedClassError\"]}",
    "meta": {
      "moduleName": "avaliacao-talentrh/pods/components/answer-evaluation/question/justification/template.hbs"
    }
  });
  _exports.default = _default;
});