define("avaliacao-talentrh/pods/reports/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    userConfigs: Ember.inject.service(),
    router: Ember.inject.service(),
    accessProfile: Ember.computed.alias('userConfigs.accessProfile'),
    leds: Ember.computed.alias('userConfigs.usersICanManage.users'),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
      const allowed = this.accessProfile.admin || this.accessProfile.generalManager || this.leds.length;
      if (!allowed) {
        return this.router.transitionTo('errors.forbidden');
      }
    }
  });
});