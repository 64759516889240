define("avaliacao-talentrh/pods/manage-goals/show-achieve/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    store: Ember.inject.service(),
    showChart: false,
    showAddGoalAchievedRow: false,
    qualitativeOptionSelected: null,
    goalAchievesSort: Ember.A(['achieveDate:desc']),
    goalAchieved: Ember.computed.alias('model.goalAchieved'),
    userGoal: Ember.computed.alias('model.userGoal'),
    goal: Ember.computed.alias('model.userGoal.goal'),
    goalAchieves: Ember.computed.alias('model.userGoal.goalsAchieved'),
    goalAchievesOrdered: Ember.computed.sort('goalAchieves', 'goalAchievesSort'),
    qualitativeOptions: Ember.A([{
      value: 'S',
      label: 'Atingiu'
    }, {
      value: 'N',
      label: 'Não atingiu'
    }]),
    isGoalEnded: Ember.computed('goal.endAt', 'userGoal', function () {
      let active = Ember.get(this.userGoal, 'active');
      if (!active) {
        return true;
      }
      return false;
    }),
    saveGoalAchieved: (0, _emberConcurrency.task)(function* () {
      try {
        const goalAchievedSameDate = this.goalAchieves.find(g => moment(g.achieveDate).isSame(this.goalAchieved.achieveDate, 'day'));
        if (goalAchievedSameDate) {
          goalAchievedSameDate.achieveDate = this.goalAchieved.achieveDate;
          goalAchievedSameDate.value = this.goalAchieved.value;
          goalAchievedSameDate.comment = this.goalAchieved.comment;
          if (goalAchievedSameDate.userGoal.get('goal').get('unit') === 'qualitative') {
            goalAchievedSameDate.value = this.qualitativeOptionSelected.value;
          }
          if (!goalAchievedSameDate.validations.isValid) {
            return this.swal.validation(goalAchievedSameDate.validations);
          }
          yield goalAchievedSameDate.save();
        } else {
          const model = this.store.createRecord('goal-achieved', this.goalAchieved);
          model.userGoal = this.userGoal;
          if (model.userGoal.get('goal').get('unit') === 'qualitative') {
            model.value = this.qualitativeOptionSelected.value;
          }
          if (!model.validations.isValid) {
            return this.swal.validation(model.validations);
          }
          const created = yield model.save();
          if (this.afterSave) {
            this.afterSave(created);
          }
        }
        this.swal.success('Salvo com sucesso!');
        this.goalAchieved = this.store.createRecord('goal-achieved');
        Ember.set(this, 'showAddGoalAchievedRow', false);
      } catch (e) {
        console.log(e);
        this.swal.catch(e);
      }
    }),
    dateSelected: (0, _emberConcurrency.task)(function* ({
      date
    }) {
      try {
        const goal = this.userGoal.goal;
        yield goal;
        if (moment(date).isAfter(goal.get('endAt'))) {
          return this.swal.warning('Data da atualização tem que ser anterior a data de encerramento da meta!');
        }
        Ember.set(this, 'goalAchieved.achieveDate', date);
      } catch (e) {
        return this.swal.catch(e);
      }
    }),
    actions: {
      onCloseModal() {
        Ember.set(this, 'showChart', false);
        this.router.transitionTo('manage-goals');
      },
      setShowChart() {
        Ember.set(this, 'showChart', !this.showChart);
      },
      showAddGoalAchievedInputs() {
        Ember.set(this, 'showAddGoalAchievedRow', true);
      },
      closeAddGoalAchieved() {
        Ember.set(this, 'goalAchieved.achieveDate', null);
        Ember.set(this, 'goalAchieved.value', null);
        Ember.set(this, 'goalAchieved.comment', null);
        Ember.set(this, 'showAddGoalAchievedRow', false);
      },
      qualitativeChange(option) {
        Ember.set(this, 'qualitativeOptionSelected', option);
        Ember.set(this, 'goalAchieved.value', option.value);
      }
    }
  });
});