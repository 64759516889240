define("avaliacao-talentrh/pods/pde/team-user-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // HTML Attributes
    classNames: ['list-group-item', 'py-4']
  });
});