define("avaliacao-talentrh/models/type-answer", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    // Attributes
    description: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    // Associations
    answers: (0, _relationships.hasMany)('answer')
  });
});