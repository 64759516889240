define("avaliacao-talentrh/pods/evaluations/evaluation-view/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'ul',
    classNames: ['list-group', 'list-group-flush'],
    showTeamModal: false,
    hasTeam: Ember.computed('', 'evaluation', function () {
      const teams = this.store.peekAll('employee-evaluation-team').toArray();
      const hasTeam = teams.filter(t => t.employeeEvaluation.get('id') === this.evaluation.get('id'));
      return hasTeam;
    }),
    isDueDateBeforeCreatedAt: Ember.computed('evaluation', function () {
      if (moment(this.evaluation.dueDate).isBefore(this.evaluation.createdAt)) {
        return this.evaluation.createdAt;
      }
    }),
    expirateDateAfterCreatedAt: Ember.computed('evaluation', function () {
      if (this.evaluation.expirationDate === null) {
        return;
      }
      if (moment(this.evaluation.dueDate).isBefore(this.evaluation.expirationDate)) {
        return this.evaluation.expirationDate;
      }
    })
  });
});